/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from 'react';
import * as R from 'ramda';
import { calcDeltas } from '../../hooks/useChartData';
import 'chart.js/auto';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import { getMonthLabel, handleExternalTooltip, useBarThickness } from './shared';
import { useCreateAtom } from '@gothub-team/got-atom';
import { yearlyChartOptions } from './BarChartOptions';
import { BarTooltipZev } from './TooltipZev';

export const createBarChart = ({ options: _options, onClick, getLabel }) => {
    const Component = ({ chartData, startValues }) => {
        const ref = useRef();

        const chartArr = R.pathOr([], ['values'], chartData);
        const importStart = R.pathOr(startValues.import || 0, ['start', 'import'], chartData);
        const importArr = calcDeltas(importStart, chartArr, 'import');
        const internalStart = R.pathOr(startValues.internal || 0, ['start', 'internal'], chartData);
        const internalArr = calcDeltas(internalStart, chartArr, 'internal');
        const exportStart = R.pathOr(startValues.export || 0, ['start', 'export'], chartData);
        const exportArr = calcDeltas(exportStart, chartArr, 'export');

        const totalArr = importArr.map((item, i) => item + internalArr[i]);

        const labels = chartArr.map(item => getLabel(item));

        const data = {
            labels: labels,
            datasets: [
                {
                    label: 'Verbrauch Intern',
                    data: internalArr,
                    backgroundColor: ['rgb(250 204 21)'],
                    borderColor: 'rgb(250 204 21)',
                    // borderRadius: 5,
                    borderWidth: 1,
                },
                {
                    label: 'Verbrauch Gesamt',
                    data: totalArr,
                    backgroundColor: ['rgb(71 85 105)'],
                    borderColor: 'rgb(71 85 105)',
                    // borderRadius: 5,
                    borderWidth: 1,
                },
                // {
                //     label: 'Einspeisung',
                //     data: exportArr,
                //     backgroundColor: ['rgb(71 85 105)'],
                //     borderColor: 'rgb(71 85 105)',
                //     // borderRadius: 5,
                //     borderWidth: 1,
                // },
            ],
        };

        const tooltipAtom = useCreateAtom(null);

        const optionsWithBarThickness = useBarThickness(_options, ref, labels.length);
        const options = R.assocPath(
            ['plugins', 'tooltip'],
            {
                enabled: false,
                external: handleExternalTooltip(tooltipAtom),
                interaction: 'x',
            },
            optionsWithBarThickness,
        );

        return (
            <>
                <Bar
                    ref={ref}
                    data={data}
                    options={options}
                    onClick={e => {
                        const elements = getElementAtEvent(ref.current, e) || [];
                        if (elements.length === 0) return;

                        const elementIndex = elements[0].index;
                        const element = chartData.values[elementIndex];
                        onClick && onClick(element);
                    }}
                    onMouseLeave={() => {
                        tooltipAtom.set(null);
                    }}
                />
                <BarTooltipZev
                    tooltipAtom={tooltipAtom}
                    internalArr={internalArr}
                    importArr={importArr}
                    exportArr={exportArr}
                    chartArr={chartArr}
                />
            </>
        );
    };

    return Component;
};

export const YearlyBarChartZevPlain = createBarChart({
    options: yearlyChartOptions,
    getLabel: getMonthLabel,
});
