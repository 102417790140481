import { createGraph } from './got.config';
import { METER_DATA_EDITOR, METER_DATA_MAIN } from './herbenergy.api';
import * as R from 'ramda';

const intervalMin = 1;
const hourlyDataPoints = 60 / intervalMin;
const dailyDataPoints = (24 * 60) / intervalMin;

export const ttstr = val => {
    const str = val.toString();
    return str.length === 1 ? `0${str}` : str;
};

// höchsten datenpunkt holen
export const getLatestDataPointDay = (zevId, meterId, timestamp) => {
    const { node } = createGraph(METER_DATA_MAIN, METER_DATA_EDITOR);
    const day = timestamp.slice(0, 10);
    const nodeId = `data/${zevId}/${meterId}/day/${day}`;
    const data = node(nodeId);

    if (!data) return undefined;
    if (data.end) return data.end;

    const values = R.propOr([], 'values', data);
    for (let i = values.length - 1; i >= 0; i--) {
        if (values[i]) {
            return values[i];
        }
    }
    return undefined;
};

export const getLatestDataPointMonth = (zevId, meterId, timestamp) => {
    const { node } = createGraph(METER_DATA_MAIN, METER_DATA_EDITOR);
    const month = timestamp.slice(0, 7);
    const nodeId = `data/${zevId}/${meterId}/month/${month}`;
    const data = node(nodeId);

    if (data && data.end) {
        return data.end;
    }
    const values = R.propOr([], 'values', data);
    const daysInMonth = new Date(month.slice(0, 4), month.slice(5, 7), 0).getDate();

    for (let i = daysInMonth - 1; i >= 0; i--) {
        if (values[i]) {
            return values[i];
        } else {
            const dayPoint = getLatestDataPointDay(zevId, meterId, `${month}-${ttstr(i + 1)}`);
            if (dayPoint) {
                return dayPoint;
            }
        }
    }
    return undefined;
};

// Datenvervollständigung

export const fillDayData = (zevId, meterId, timestamp, data) => {
    const day = timestamp.slice(0, 10);
    const values = R.propOr([], 'values', data);

    let newValues = [];

    for (let i = 0; i < dailyDataPoints; i++) {
        if (values[i] && values[i].import) {
            newValues[i] = values[i];
        } else {
            const hour = Math.floor(i / hourlyDataPoints);
            const minute = (i % hourlyDataPoints) * intervalMin;
            const timestamp = `${day}T${ttstr(hour)}:${ttstr(minute)}Z`;
            newValues[i] = { timestamp, import: undefined };
        }
    }

    return { ...data, values: newValues };
};

export const fillMonthData = (zevId, meterId, timestamp, data) => {
    const month = timestamp.slice(0, 7);
    const values = R.propOr([], 'values', data);
    const daysInMonth = new Date(month.slice(0, 4), month.slice(5, 7), 0).getDate();

    let newValues = [];

    for (let i = 0; i < daysInMonth; i++) {
        if (values[i] && values[i].import) {
            newValues[i] = values[i];
        } else {
            const dayStamp = `${month}-${ttstr(i + 1)}`;
            const dayPoint = getLatestDataPointDay(zevId, meterId, dayStamp);
            if (dayPoint) {
                newValues[i] = { ...dayPoint, timestamp: dayStamp };
            } else {
                newValues[i] = { timestamp: dayStamp, import: undefined };
            }
        }
    }

    return { ...data, values: newValues };
};

export const fillYearData = (zevId, meterId, timestamp, data) => {
    const year = timestamp?.slice(0, 4);
    const values = R.propOr([], 'values', data);
    const monthsInYear = 12;

    let newValues = [];

    for (let i = 0; i < monthsInYear; i++) {
        if (values[i] && values[i].import) {
            newValues[i] = values[i];
        } else {
            const monthStamp = `${year}-${ttstr(i + 1)}`;
            const monthPoint = getLatestDataPointMonth(zevId, meterId, monthStamp);
            if (monthPoint) {
                newValues[i] = { ...monthPoint, timestamp: monthStamp };
            } else {
                newValues[i] = { timestamp: monthStamp, import: undefined };
            }
        }
    }

    return { ...data, values: newValues };
};
