import React from 'react';
import { motion } from 'framer-motion';
import Icon from './Icon';

export const Modal = ({ children, onClose, title, onSave, scroll }) => (
    <motion.div
        className="absolute left-0 top-0 h-full w-full bg-black/50 backdrop-blur"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
        // onClick={onClose}
    >
        <div
            onClick={e => e.stopPropagation()}
            className="fixed left-1/2 top-1/2 flex max-h-[90vh] min-w-[500px] -translate-x-1/2 -translate-y-1/2 flex-col rounded-2xl bg-white p-3"
        >
            <div className="mb-5 px-2">
                <div className="mb-10 flex justify-between">
                    <div className="text-2xl text-emerald-700">{title}</div>
                    <div
                        className="flex h-6 w-6 cursor-pointer justify-center fill-slate-600 text-neutral-400 transition hover:scale-110 hover:fill-yellow-400"
                        onClick={onClose}
                        title="Fenster schließen"
                    >
                        <Icon name="x" />
                    </div>
                </div>
            </div>
            <div className={`${scroll && 'overflow-y-scroll'} px-2`}>{children}</div>
            {onSave && (
                <div
                    type="submit"
                    className="font-semi mx-auto mt-10 w-1/3 cursor-pointer px-4 py-2 text-center text-xl text-slate-600 hover:text-yellow-400 focus:outline-none"
                    onClick={() => {
                        onSave();
                        onClose && onClose();
                    }}
                >
                    Speichern
                </div>
            )}
        </div>
    </motion.div>
);
