import { useAtom } from '@gothub-team/got-atom';
import {
    SelectedDayAtom,
    SelectedMonthAtom,
    SelectedTenantIdAtom,
    SelectedYearAtom,
    TenantExportPopup,
    selectedZEVID,
} from '../../state/states';
import { Modal } from '../../components/Modal';
import { exportCSV } from '../../misc/exportCSV';

export const ExportModal = () => {
    const isOpen = useAtom(TenantExportPopup);

    const zevId = useAtom(selectedZEVID);
    const tenantId = useAtom(SelectedTenantIdAtom);

    const day = useAtom(SelectedDayAtom);
    const month = useAtom(SelectedMonthAtom);
    const year = useAtom(SelectedYearAtom);

    const exportValues = interval => {
        const nodeId = `data/${zevId}/${tenantId}/day/${year}-${month}-${day}`;
        const name = `${zevId}_${tenantId}_${day}-${month}-${year}`;

        exportCSV(nodeId, name, interval);
    };

    if (!isOpen) return null;

    const buttonClass =
        'w-32 h-32 border-none bg-gray-100 hover:bg-gray-200 rounded-2xl text-lg font-bold';

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => TenantExportPopup.set(false)}
            title="CSV Export"
            scroll
        >
            <div className="mx-auto flex flex-row justify-center gap-16 px-8 pb-12">
                <button
                    className={buttonClass}
                    onClick={() => {
                        exportValues(1);
                    }}
                >
                    1 min
                </button>
                <button
                    className={buttonClass}
                    onClick={() => {
                        exportValues(5);
                    }}
                >
                    5 min
                </button>
                <button
                    className={buttonClass}
                    onClick={() => {
                        exportValues(15);
                    }}
                >
                    15 min
                </button>
                <button
                    className={buttonClass}
                    onClick={() => {
                        exportValues(60);
                    }}
                >
                    60 min
                </button>
            </div>
        </Modal>
    );
};
