import React from 'react';
import { searchInputAtom } from '../state/states';
import Icon from './Icon';

export default function Searchbar(props) {
    const [showInput, setShowInput] = React.useState(false);
    const ref = React.useRef();
    return (
        <div className="flex">
            <div style={{ opacity: showInput ? 1 : 0 }} className="mr-3 transition">
                <input
                    ref={ref}
                    placeholder={props.placeholderText}
                    value={props.input1}
                    onChange={props.input2}
                    type="text"
                    name="searchInput"
                    id="searchInput"
                    className="border-1 w-full rounded-md border border-solid border-yellow-400/70 text-center text-black outline-none placeholder:text-center placeholder:text-slate-500"
                />
            </div>
            <div
                className="h-5 w-5 cursor-pointer fill-slate-600 transition hover:fill-yellow-400"
                onClick={() => {
                    setShowInput(!showInput);
                    !showInput && ref.current.focus();
                    showInput && searchInputAtom.set('');
                }}
            >
                <Icon name="searchIcon" className="h-5 w-5" />
            </div>
        </div>
    );
}
