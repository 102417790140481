import React from 'react';
import { SelectedTenantIdAtom, tenantEditPopup } from '../../state/states';
import { useAtom } from '@gothub-team/got-atom';
import { useGraph } from '../../misc/got.config';
import { EDITOR, MAIN } from '../../misc/consts';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal } from '../../components/Modal';
import { Input } from '../../components/Input';

const createTenantView = tenantID =>
    tenantID
        ? {
              [tenantID]: {
                  include: {
                      node: true,
                  },
              },
          }
        : {};

const notifySuccess = () => toast.success('Speichern erfolgreich');
const notifyError = () => toast.error('Speichern fehlgeschlagen');

const EDIT_TENANT = 'EDIT_TENANT';
export const EditTenantModal = () => {
    const selectedTenantId = useAtom(SelectedTenantIdAtom);
    const view = createTenantView(selectedTenantId);

    const { pull, update, push, useNode, clear } = useGraph(MAIN, EDITOR, EDIT_TENANT);
    const tenant = useNode(selectedTenantId);

    React.useEffect(() => {
        pull(view);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTenantId]);

    const {
        nameT = '',
        adressT = '',
        telT = '',
        mailT = '',
        object = '', //object ist nähere wohnungsbezeichnung wie "1.OG links"
        cityT = '',
        pLZT = '',
    } = tenant || {};

    return (
        <Modal
            onClose={() => {
                clear();
                tenantEditPopup.set(false);
            }}
            title={nameT}
            onSave={async () => {
                try {
                    await push();
                    notifySuccess();
                } catch (err) {
                    console.log(err);
                    notifyError();
                } finally {
                    tenantEditPopup.set(false);
                }
            }}
            scroll
        >
            <div className="mx-auto flex flex-col justify-center gap-5 pb-2">
                <Input
                    label={'Name'}
                    type={'text'}
                    value={nameT}
                    onChange={value => update({ id: selectedTenantId, nameT: value })}
                />
                <Input
                    label={'Adresse'}
                    type={'text'}
                    value={adressT}
                    onChange={value => update({ id: selectedTenantId, adressT: value })}
                />
                <Input
                    label={'PLZ'}
                    type={'text'}
                    value={pLZT}
                    onChange={value => update({ id: selectedTenantId, pLZT: value })}
                />
                <Input
                    label={'Stadt'}
                    type={'text'}
                    value={cityT}
                    onChange={value => update({ id: selectedTenantId, cityT: value })}
                />
                <Input
                    label={'Wohnungsbeschreibung'}
                    type={'text'}
                    value={object}
                    onChange={value => update({ id: selectedTenantId, object: value })}
                />
                <Input
                    label={'Telefonnummer'}
                    type={'text'}
                    value={telT}
                    onChange={value => update({ id: selectedTenantId, telT: value })}
                />
                <Input
                    label={'Mail'}
                    type={'text'}
                    value={mailT}
                    onChange={value => update({ id: selectedTenantId, mailT: value })}
                />
            </div>
        </Modal>
    );
};
