import React from 'react';
import { useAuthscreenActions } from './useScreenAuthActions';
import { CodeInput, EmailInput, PasswordConfirmInput, PasswordInput } from './inputs';
// import { Toast } from '../leanComponents/Toast';
import * as R from 'ramda';
import Icon from '../../components/Icon';
import { ClickAnimator } from '../../Mobile/components/ClickAnimator';

export const AuthScreen = ({ onLoggedIn }) => {
    const {
        task,
        setTask,
        navLogin,
        navForgotPassword,
        navRegister,
        login,
        register,
        forgotPasswordVerify,
        activate,
        resendActivationCode,
    } = useAuthscreenActions(onLoggedIn);

    if (onLoggedIn === 'init') return <div>loading</div>;

    return (
        <div className="fade-in fixed flex h-full w-full items-center justify-center">
            <div className="focus-auth" />
            {/* <img
                src="https://images.pexels.com/photos/3608056/pexels-photo-3608056.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                alt=""
                className="blur-fade-in h-full w-full contrast-[1.15]"
            /> */}
            <div
                className={`absolute z-[100] flex h-auto w-96 flex-col rounded-2xl bg-white bg-opacity-50 px-10 py-10 tracking-wide`}
            >
                <Icon name="logo" className="mb-10" />
                {task.name === 'login' && (
                    <Login
                        task={task}
                        setTask={setTask}
                        login={login}
                        navRegister={navRegister}
                        navForgotPassword={navForgotPassword}
                    />
                )}
                {task.name === 'register' && (
                    <Register
                        task={task}
                        setTask={setTask}
                        register={register}
                        onNavLogin={navLogin}
                    />
                )}
                {task.name === 'forgotPassword' && (
                    <ForgotPassword
                        task={task}
                        setTask={setTask}
                        forgotPasswordVerify={forgotPasswordVerify}
                        navLogin={navLogin}
                    />
                )}
                {task.name === 'activate' && (
                    <Activate
                        task={task}
                        setTask={setTask}
                        registerVerify={activate}
                        resendActivationCode={resendActivationCode}
                        navLogin={navLogin}
                    />
                )}
            </div>
        </div>
    );
};

const Login = ({ task, setTask, login, navForgotPassword, navRegister }) => {
    const email = task.email || '';
    const password = task.password || '';

    // useHotkeys('enter', login, { enableOnTags: ['INPUT'] });

    return (
        <div>
            <div className="flex flex-col gap-3">
                <EmailInput value={email} onChange={value => setTask(R.assoc('email', value))} />
                <PasswordInput
                    value={password}
                    onChange={value => setTask(R.assoc('password', value))}
                />
            </div>
            <div className="mt-10 flex flex-col gap-4">
                <ClickAnimator>
                    <div
                        className="my-auto cursor-pointer select-none rounded-full border-none bg-emerald-700 px-4 py-1 text-center text-xl text-white backdrop-blur transition"
                        onClick={login}
                    >
                        Anmelden
                    </div>
                </ClickAnimator>
                <div className="flex flex-col items-center justify-center gap-3 tracking-normal">
                    <div
                        className="w-2/3  cursor-pointer rounded-2xl border-none  bg-opacity-60  px-4 py-1 text-center text-black transition placeholder:text-center hover:text-yellow-400"
                        onClick={navForgotPassword}
                    >
                        Passwort vergessen
                    </div>
                    <div
                        className="w-2/3  cursor-pointer rounded-2xl border-none  bg-opacity-60  px-4 py-1 text-center text-black transition placeholder:text-center hover:text-yellow-400"
                        onClick={navRegister}
                    >
                        Registrieren
                    </div>
                </div>
            </div>
        </div>
    );
};

const Register = ({ task, setTask, register, onNavLogin }) => {
    const email = task.email || '';
    const password = task.password || '';
    const passwordConfirm = task.passwordConfirm || '';

    // useHotkeys('enter', register, { enableOnTags: ['INPUT'] });

    return (
        <>
            <div className="flex flex-col gap-3">
                <EmailInput value={email} onChange={value => setTask(R.assoc('email', value))} />
                <PasswordInput
                    value={password}
                    onChange={value => setTask(R.assoc('password', value))}
                />
                <PasswordConfirmInput
                    value={passwordConfirm}
                    onChange={value => setTask(R.assoc('passwordConfirm', value))}
                />
            </div>
            <div className="mt-10 flex flex-col gap-4">
                <div
                    className="my-auto cursor-pointer rounded-2xl border-none bg-orange-300 bg-opacity-60 px-4  py-1  text-center text-xl text-white"
                    onClick={register}
                >
                    Registrieren
                </div>
                <div
                    className="my-auto cursor-pointer rounded-2xl border-none bg-orange-300 bg-opacity-60 px-4  py-1  text-center text-xl text-white"
                    onClick={onNavLogin}
                >
                    Zurück zum Login
                </div>
            </div>
        </>
    );
};

const ForgotPassword = ({ task, setTask, forgotPasswordVerify, navLogin }) => {
    const code = task.code || '';
    const password = task.password || '';
    const passwordConfirm = task.passwordConfirm || '';

    // useHotkeys('enter', forgotPasswordVerify, { enableOnTags: ['INPUT'] });

    return (
        <>
            <div className="flex flex-col gap-3">
                <CodeInput value={code} onChange={value => setTask(R.assoc('code', value))} />
                <PasswordInput
                    value={password}
                    onChange={value => setTask(R.assoc('password', value))}
                />
                <PasswordConfirmInput
                    value={passwordConfirm}
                    onChange={value => setTask(R.assoc('passwordConfirm', value))}
                />
            </div>
            <div className="mt-10 flex flex-col gap-4">
                <div
                    className="my-auto cursor-pointer rounded-2xl border-none bg-orange-300 bg-opacity-60 px-4  py-1  text-center text-xl text-white"
                    onClick={forgotPasswordVerify}
                >
                    Passwort setzen
                </div>
                <div
                    className="my-auto cursor-pointer rounded-2xl border-none bg-orange-300 bg-opacity-60 px-4  py-1  text-center text-xl text-white"
                    onClick={navLogin}
                >
                    zurück zum Login
                </div>
            </div>
        </>
    );
};

const Activate = ({ task, setTask, registerVerify, resendActivationCode, navLogin }) => {
    const code = task.code || '';

    // useHotkeys('enter', registerVerify, { enableOnTags: ['INPUT'] });

    return (
        <>
            <div className="flex flex-col gap-3">
                <CodeInput value={code} onChange={value => setTask(R.assoc('code', value))} />
            </div>
            <div className="mt-10 flex flex-col gap-4">
                <div
                    className="my-auto cursor-pointer rounded-2xl border-none bg-orange-300 bg-opacity-60 px-4  py-1  text-center text-xl text-white"
                    onClick={registerVerify}
                >
                    Aktivieren
                </div>
                <div className="flex justify-center gap-3 tracking-normal">
                    <div
                        className="my-auto w-1/2 cursor-pointer rounded-2xl border-none  bg-orange-300  bg-opacity-60 px-4 py-1 text-center text-white placeholder:text-center"
                        onClick={navLogin}
                    >
                        zurück zum Login
                    </div>
                    <div
                        className="my-auto w-1/2 cursor-pointer rounded-2xl border-none bg-orange-300 bg-opacity-60  px-4 py-1 text-center text-white  placeholder:text-center"
                        onClick={resendActivationCode}
                    >
                        Code erneut senden
                    </div>
                </div>
            </div>
        </>
    );
};

export default AuthScreen;
