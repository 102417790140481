import React from 'react';
import TenantRow from './TenantRow';
import { useMeters, useZev } from '../hooks/useMeters';
import { ZevRow } from './ZevRow';

export default function TenantList() {
    const { tenantIDs } = useMeters();
    const zev = useZev();
    return (
        <div>
            <div className="flex flex-col gap-2">
                {zev && <ZevRow zev={zev} />}
                {tenantIDs.map((tenantId, index) => (
                    <TenantRow key={tenantId} parentId={tenantId} />
                ))}
            </div>
        </div>
    );
}
