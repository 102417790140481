import React from 'react';
import Icon from '../../components/Icon';

export const AddButton = ({ className, onClick }) => {
    return (
        <div
            className={`fixed bottom-0 right-2 flex flex-col items-center justify-center p-3 ${className}`}
            onClick={onClick}
        >
            <Icon name="plus" className={`mb-1 h-4 w-4 fill-emerald-700`} />
            <div className={`text-xs text-emerald-700`}>Hinzufügen</div>
        </div>
    );
};

export const BackButton = ({ className, onClick }) => {
    return (
        <div
            className={`absolute bottom-0 left-2 flex flex-col items-center justify-center rounded-2xl bg-white p-3 shadow-white ${className}`}
            onClick={onClick}
        >
            <Icon name="back" className={`mb-1 h-4 w-4`} />
            <div className={`text-xs text-slate-700`}>Zurück</div>
        </div>
    );
};

export const CloseButton = ({ className, onClick }) => {
    return (
        <div
            className={`fixed bottom-0 left-2 flex flex-col items-center justify-center rounded-xl bg-white p-3 shadow-white ${className}`}
            onClick={onClick}
        >
            <Icon name="x" className={`mb-1 h-4 w-4`} />
            <div className={`text-xs text-slate-700`}>Schließen</div>
        </div>
    );
};

export const PDFButton = ({ className, onClick }) => {
    return (
        <div
            className={`fixed bottom-0 right-2 z-10 flex flex-col items-center justify-center rounded-xl bg-white p-3 shadow-white ${className}`}
            onClick={onClick}
        >
            <Icon name="pdf" className={`mb-1 h-4 w-4 fill-emerald-700`} />
            <div className={`text-xs text-emerald-700`}>PDF erstellen</div>
        </div>
    );
};

export const SaveButton = ({ className, onClick }) => {
    return (
        <div
            className={`fixed bottom-0 right-2 z-10 flex flex-col items-center justify-center rounded-xl bg-white p-3 shadow-white ${className}`}
            onClick={onClick}
        >
            <Icon name="check-solid" className={`mb-1 h-4 w-4 fill-emerald-700`} />
            <div className={`text-xs text-emerald-700`}>Speichern</div>
        </div>
    );
};
