import React from 'react';
import { useAtom } from '@gothub-team/got-atom';
import { ZevArrayAtom } from '../../state/states';
import Icon from '../../components/Icon';
import { loadZev } from '../../misc/loadUser';

export default function ZevSelectionMobile() {
    const zevArray = useAtom(ZevArrayAtom);

    return (
        <div className="flex-1 overflow-y-scroll">
            {zevArray.map(zev => (
                <div
                    key={zev.nodeId}
                    onClick={async () => {
                        loadZev(zev, true);
                    }}
                    className={`relative my-5 mb-10 box-border flex w-[calc(100%-0.5rem)] cursor-pointer items-center rounded-xl text-left text-lg text-slate-900 transition`}
                >
                    <Icon name="people" className="mr-3 h-6 w-6 fill-emerald-700/80" />
                    {zev.node.name}
                </div>
            ))}
        </div>
    );
}
