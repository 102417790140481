import React from 'react';
import { useState } from 'react';

export const Input = ({ label, type, value, onChange, onBlur, onFocus }) => {
    const [isFocused, setIsFocused] = useState(false);
    const handleOnBlur = e => {
        onBlur && onBlur(e);
        setIsFocused(false);
    };
    const handleOnFocus = e => {
        onFocus && onFocus(e);
        setIsFocused(true);
    };
    return (
        <div className="flex flex-row items-center gap-2">
            <div className="relative mb-8 inline-block w-full">
                <input
                    type={type}
                    className="box-border w-full border-none py-0 pl-[0.1rem] pr-3 text-base text-black outline-none transition-all duration-200 ease-out placeholder:transition-all placeholder:duration-200 placeholder:ease-out"
                    placeholder={label}
                    value={value}
                    onChange={e => onChange(e.target.value)}
                    onBlur={handleOnBlur}
                    onFocus={handleOnFocus}
                />
                <div
                    className={`absolute -bottom-1 left-0 h-[2px] w-full overflow-hidden rounded-[2px] bg-slate-600 after:absolute after:left-0 after:block after:h-full after:w-full after:bg-emerald-600 after:transition-all after:duration-200 after:ease-out after:content-[""]
                        ${isFocused ? 'after:scale-100' : 'after:scale-0'}
                    `}
                />
                {value && (
                    <div
                        className={`animate-fade-scale-in absolute -bottom-6 left-0 rounded py-[0.1rem] pl-1 pr-4 text-[0.6rem] font-medium text-white transition-all duration-200 ease-out
                            ${isFocused ? 'bg-emerald-600' : 'bg-slate-600'}
                            ${value ? 'scale-100' : 'scale-0'}
                        `}
                        style={{
                            clipPath:
                                'polygon(0% 0%, 100% 0, 100% 0%, calc(100% - 12px) 100%, 0% 100%)',
                            transformOrigin: 'left',
                        }}
                    >
                        {label}
                    </div>
                )}
            </div>
        </div>
    );
};
