import { tariffCalcPopupAtom } from '../../../state/states';
import { useTariffCalculator } from '../../../hooks/useTariffEdit';
import { FieldsLeft, FieldsRight } from './shared';
import { useAtom } from '@gothub-team/got-atom';
import { MobileModal } from '../../../Mobile/components/MobileModal';

export const TariffCalculatorModalMobile = () => {
    const { onSave, tariffInfo, updateTariffInfo, tariffRes } = useTariffCalculator();
    const isOpen = useAtom(tariffCalcPopupAtom);

    return (
        <MobileModal
            onClose={() => tariffCalcPopupAtom.set(false)}
            onSave={onSave}
            title="Tarif berechnen"
            isOpen={isOpen}
            scroll
        >
            {isOpen && (
                <div className="flex w-full flex-col gap-5">
                    <FieldsLeft
                        tariffInfo={tariffInfo}
                        updateTariffInfo={updateTariffInfo}
                        tariffRes={tariffRes}
                    ></FieldsLeft>
                    <FieldsRight
                        tariffInfo={tariffInfo}
                        updateTariffInfo={updateTariffInfo}
                        tariffRes={tariffRes}
                    ></FieldsRight>
                    <div className="pb-16"></div>
                </div>
            )}
        </MobileModal>
    );
};
