import { EDITOR, MAIN } from '../misc/consts';
import { useGraph } from '../misc/got.config';
import { useAtom } from '@gothub-team/got-atom';
import { SelectedTenantIdAtom } from '../state/states';
import * as R from 'ramda';

export const useMeterStartValue = (type = 'import') => {
    const { useNode } = useGraph(MAIN, EDITOR);
    const meterId = useAtom(SelectedTenantIdAtom);
    return useNode(meterId, getStartValue(type));
};

export const useMeterStartValues = () => {
    const { useNode } = useGraph(MAIN, EDITOR);
    const meterId = useAtom(SelectedTenantIdAtom);
    return useNode(meterId, getStartValues);
};

const getStartValue = (type = 'import') => R.pathOr(0, ['startValues', type]);
const getStartValues = R.propOr({}, 'startValues');
