import './App.css';
import ScreenNav from './components/ScreenNav';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AppMobile = () => {
    return (
        <div className="fade-in mobile-app">
            <ScreenNav mobile />
            <ToastContainer position="bottom-right" autoClose={2500} pauseOnHover={false} />
        </div>
    );
};

export default AppMobile;
