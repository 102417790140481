import { toast } from 'react-toastify';
import { EDITOR, MAIN } from '../misc/consts';
import { useGraph } from '../misc/got.config';
import { landlordEditPopup, selectedZEVID } from '../state/states';
import { useAtom } from '@gothub-team/got-atom';
import { useEffect } from 'react';

const EDIT_LANDLORD = 'EDIT_LANDLORD';

const createLandLordView = landLordID =>
    landLordID
        ? {
              [landLordID]: {
                  include: {
                      node: true,
                  },
              },
          }
        : {};

const notifySuccess = () => toast.success('Speichern erfolgreich');
const notifyError = () => toast.error('Speichern fehlgeschlagen');

export const useEditLandlord = () => {
    const loggedInLLID = useAtom(selectedZEVID);
    const view = createLandLordView(loggedInLLID);

    const { useNode, update, push, pull, clear } = useGraph(MAIN, EDITOR, EDIT_LANDLORD);
    const landLord = useNode(loggedInLLID);

    useEffect(() => {
        pull(view);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onClose = () => {
        clear();
        landlordEditPopup.set(false);
    };

    const onSave = async e => {
        e && e.preventDefault();
        try {
            await push();
            notifySuccess();
        } catch (err) {
            console.log(err);
            notifyError();
        } finally {
            landlordEditPopup.set(false);
        }
    };

    return { landLord, update, onClose, onSave, loggedInLLID };
};
