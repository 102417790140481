import * as R from 'ramda';
import { EDITOR, MAIN } from '../misc/consts';
import { gotApi, useGraph } from '../misc/got.config';
import { SelectedTenantIdAtom, selectedZEVID } from '../state/states';
import { useAtom } from '@gothub-team/got-atom';
import { useEffect, useState } from 'react';

const createTenantView = TenantId =>
    TenantId
        ? {
              [TenantId]: {
                  include: {
                      rights: true,
                  },
              },
          }
        : {};

const selectReadEmails = rights => {
    const userRights = R.pathOr({}, ['user'], rights);
    const pickedRights = R.pickBy((val, key) => {
        return val.read && key !== gotApi.getCurrentUser().email;
    }, userRights);
    const selectedEMails = R.keys(pickedRights);
    return selectedEMails;
};

export const useEditTenantRights = () => {
    const [eMail, setEMail] = useState('');
    const tenantID = useAtom(SelectedTenantIdAtom);

    const { setRights, push, pull, useRights, useNode } = useGraph(MAIN, EDITOR);
    const tenantView = createTenantView(tenantID);
    const selectedTenantId = useAtom(SelectedTenantIdAtom);
    const tenant = useNode(selectedTenantId);

    useEffect(() => {
        pull(tenantView);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantID]);

    const { nameT = '' } = tenant || {};
    const rights = useRights(tenantID, selectReadEmails); //tenantID = ZählerID und selectReadEmails = TenantID

    const handleClick = () => {
        setRights(SelectedTenantIdAtom.get())(eMail.toLowerCase(), { read: true });
        setRights(selectedZEVID.get())(eMail.toLowerCase(), { read: true });
        push();
    };

    const handleRemove = removeEMail => {
        setRights(SelectedTenantIdAtom.get())(removeEMail, { read: false });
        push();
    };

    return { eMail, setEMail, handleClick, handleRemove, rights, nameT };
};
