import { useAtom } from '@gothub-team/got-atom';
import { MAIN } from '../misc/consts';
import { useGraph } from '../misc/got.config';
import { selectedZEVID } from '../state/states';

export const FEATURE_MODE = {
    FULL: 'full',
    REDUCED: 'reduced',
};

const selectFeatureMode = zev => {
    if (!zev) {
        return FEATURE_MODE.REDUCED;
    }

    return zev.featureMode || FEATURE_MODE.FULL;
};

export const useFeatureMode = () => {
    const { useNode } = useGraph(MAIN);
    const zevId = useAtom(selectedZEVID);

    return useNode(zevId, selectFeatureMode);
};
