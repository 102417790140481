import React from 'react';
import 'chart.js/auto';
import { SelectedMonthAtom, SelectedYearAtom, SelectedDayAtom } from '../state/states';
import { useAtom } from '@gothub-team/got-atom';
import Icon from '../components/Icon';
import { ttstr } from '../misc/meterData.util';

const updateDate = timeChange => {
    const timeStamp = `${SelectedYearAtom.get()}-${ttstr(SelectedMonthAtom.get() || '06')}-${ttstr(
        SelectedDayAtom.get() || '15',
    )}T00:00:00.000Z`;

    const newDate = new Date(new Date(timeStamp).getTime() + timeChange).toISOString();
    const newYear = Number.parseInt(newDate.slice(0, 4));
    const newMonth = Number.parseInt(newDate.slice(5, 7));
    const newDay = Number.parseInt(newDate.slice(8, 10));

    SelectedYearAtom.set(newYear);
    SelectedMonthAtom.get() && SelectedMonthAtom.set(ttstr(newMonth));
    SelectedDayAtom.get() && SelectedDayAtom.set(ttstr(newDay));
};

export default function YearSelection({ mobile }) {
    const selectedYear = useAtom(SelectedYearAtom);
    const selectedMonth = useAtom(SelectedMonthAtom);
    const selectedDay = useAtom(SelectedDayAtom);

    const iconColor = mobile
        ? 'fill-slate-600/90 h-[0.875rem] w-[0.875rem]'
        : 'fill-slate-600 hover:fill-yellow-400 h-[0.875rem] w-[0.875rem]';

    const update = factor => {
        if (!selectedMonth && !selectedDay) {
            // update year
            updateDate(factor * 1000 * 60 * 60 * 24 * 365);
        } else if (!selectedDay) {
            // update month
            updateDate(factor * 1000 * 60 * 60 * 24 * 31);
        } else {
            // update day
            updateDate(factor * 1000 * 60 * 60 * 24);
        }
    };

    return (
        <div className="flex items-center">
            <div
                className="cursor-pointer text-black hover:text-yellow-400"
                onClick={e => {
                    e.stopPropagation();
                    update(-1);
                }}
            >
                <div className={`flex items-center justify-between ${iconColor}`}>
                    <Icon name="chevleft" />
                </div>
            </div>

            <div className="mx-3 flex flex-row">
                {selectedDay && (
                    <div className={`select-none text-xl text-slate-600`}>
                        {`${ttstr(selectedDay)}.`}
                    </div>
                )}
                {selectedMonth && (
                    <div
                        onClick={e => {
                            e.stopPropagation();
                            SelectedDayAtom.set(false);
                        }}
                        className={`cursor-pointer select-none text-xl text-slate-600 hover:text-yellow-400`}
                    >
                        {`${ttstr(selectedMonth)}.`}
                    </div>
                )}
                <div
                    onClick={e => {
                        e.stopPropagation();
                        SelectedDayAtom.set(false);
                        SelectedMonthAtom.set(false);
                    }}
                    className={`cursor-pointer select-none text-xl text-slate-600 hover:text-yellow-400`}
                >
                    {selectedYear}
                </div>
            </div>

            <div
                className="cursor-pointer text-black hover:scale-110 hover:text-yellow-400"
                onClick={e => {
                    e.stopPropagation();
                    update(1);
                }}
            >
                <div className={`flex items-center justify-between ${iconColor}`}>
                    <Icon name="chevright" />
                </div>
            </div>
        </div>
    );
}
