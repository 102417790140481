import React from 'react';
import { useSpring, animated } from 'react-spring';
import Icon from '../../components/Icon';
import { useAtom } from '@gothub-team/got-atom';
import { mobileMenuAtom } from '../../state/states';
import { Portal } from '@headlessui/react';
import { Backdrop } from './Backdrop';

export const MobileMenu = ({ children }) => {
    const open = useAtom(mobileMenuAtom);
    const icon1Spring = useSpring({
        opacity: open ? 0 : 1,
        transform: open ? 'scale(0)' : 'scale(1)',
        transformOrigin: 'center',
    });
    const menuSpring = useSpring({
        opacity: open ? 1 : 0,
        transform: open ? 'scale(1)' : 'scale(0)',
        transformOrigin: 'top right',
    });
    return (
        <>
            <div
                className="relative flex h-5 w-5 items-center justify-center"
                onClick={() => mobileMenuAtom.set(!open)}
            >
                <animated.div
                    style={icon1Spring}
                    className="absolute left-0 top-0 flex items-center justify-center"
                >
                    <Icon name="burger" className="h-5 w-5 fill-slate-600" />
                </animated.div>
            </div>
            <Portal>
                <animated.div
                    style={menuSpring}
                    className="fixed right-2 top-2 z-10 rounded-2xl bg-emerald-700 p-5 py-3 shadow-[0_0.5rem_1rem_rgba(0,0,0,0.25)] backdrop-blur-sm"
                >
                    <div
                        className="flex w-full items-center justify-between pb-3"
                        onClick={() => mobileMenuAtom.set(false)}
                    >
                        <div className="text-xs text-white/40">H e r b E n e r g y</div>
                        <Icon name="x" className="h-5 w-5 translate-x-[30%] fill-white/40" />
                    </div>
                    {children}
                </animated.div>
            </Portal>
            <Backdrop toggle={open} onClick={() => mobileMenuAtom.set(false)} />
        </>
    );
};
