import axios from 'axios';
import { gotApi, gotStore } from './got.config';

const authHeader = async (asAdmin = false) => {
    const { idToken } = gotApi.getCurrentSession();

    return {
        headers: {
            Authorization: idToken,
            ...(asAdmin ? { 'x-as-admin': true } : {}),
        },
    };
};

export const METER_DATA_MAIN = 'meter-data-main';
export const METER_DATA_EDITOR = 'meter-data-editor';

export const createHerbenergyApi = ({ host }) => {
    const uploadPdf = async data =>
        axios.post(`${host}/upload-invoice`, data, await authHeader()).then(res => res.data);

    const pull = timeframe => async (zevId, meterId, timestamp) => {
        const graph = await axios
            .post(
                `${host}/get-meter-data`,
                { zevId, meterId, timeframe, timestamp: `${timestamp}` },
                await authHeader(),
            )
            .then(res => res.data);
        gotStore.mergeOverwriteGraph(graph, METER_DATA_MAIN);
    };

    const getInvoiceData = async (zevId, meterId, from, to) =>
        axios
            .post(
                `${host}/get-meter-data`,
                { zevId, meterId, timeframe: 'INVOICE', from: `${from}`, to: `${to}` },
                await authHeader(),
            )
            .then(res => res.data);

    const getWeather = async body => {
        return axios.post(`${host}/get-weather`, body, await authHeader()).then(res => res.data);
    };

    return {
        uploadPdf,
        pullYear: pull('YEAR'),
        pullMonth: pull('MONTH'),
        pullDay: pull('DAY'),
        getInvoiceData,
        getWeather,
    };
};

export const herbenergyApi = createHerbenergyApi({ host: 'https://backend.herbenergy.ch' });
// export const herbenergyApi = createHerbenergyApi({ host: 'http://localhost:4000/dev' });
