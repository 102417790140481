import React from 'react';
import { useAtom } from '@gothub-team/got-atom';
import { LoggedIn, screenNav } from '../state/states';
import LandLordMain from '../landlord/LandLordMain';
import Login from './Login';
import TenantMain from '../tenant/TenantMain';
import AdminMain from '../admin/AdminMain';
import LandLordMainMobile from '../Mobile/Landlord/LandLordMainMobile';
import TenantMainMobile from '../Mobile/Tenant/TenantMainMobile';
import { LandLordNoZev } from '../landlord/LandLordNoZev';
import { TenantNoMeter } from '../tenant/TenantNoMeter';

export default function ScreenNav({ mobile }) {
    const nav = useAtom(screenNav);
    const loggedIn = useAtom(LoggedIn);

    if (loggedIn !== 'loggedIn') {
        return <Login mobile={mobile} />;
    } else if (mobile) {
        if (nav === 'landlord-main') {
            return <LandLordMainMobile />;
        } else if (nav === 'landlord-no-zev') {
            return <LandLordNoZev />;
        } else if (nav === 'tenant-main') {
            return <TenantMainMobile />;
        } else if (nav === 'tenant-no-meter') {
            return <TenantNoMeter />;
        } else {
            return null;
        }
    } else {
        if (nav === 'landlord-main') {
            return <LandLordMain />;
        } else if (nav === 'landlord-no-zev') {
            return <LandLordNoZev />;
        } else if (nav === 'tenant-main') {
            return <TenantMain />;
        } else if (nav === 'tenant-no-meter') {
            return <TenantNoMeter />;
        } else if (nav === 'admin') {
            return <AdminMain />;
        } else {
            return null;
        }
    }
}
