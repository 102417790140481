import { tariffCalcPopupAtom, tariffEditPopupAtom } from '../../../state/states';
import { useTariffEdit } from '../../../hooks/useTariffEdit';
import { RappenPropInputField } from './shared';
import { MobileModal } from '../../../Mobile/components/MobileModal';
import { useAtom } from '@gothub-team/got-atom';

export const EditTariffModalMobile = () => {
    const { onSave, tariff, updateTariff, tariffRes } = useTariffEdit();
    const isOpen = useAtom(tariffEditPopupAtom);

    const externalPrice = tariff?.external || 0;
    const externalSuggestion = tariffRes?.externalTariff || 0;

    const internalPrice = tariff?.internal || 0;
    const internalSuggestion = tariffRes?.internalTariff || 0;

    const warning =
        internalPrice > internalSuggestion
            ? 'Der interne Strompreis ist höher als der empfohlene Preis, dies könnte gegen Schweizer Richtlinien verstoßen.'
            : '';

    return (
        <MobileModal
            onClose={() => tariffEditPopupAtom.set(false)}
            onSave={onSave}
            title="Tarif bearbeiten"
            isOpen={isOpen}
        >
            {isOpen && (
                <div className="flex w-full flex-col gap-5">
                    <div
                        className="font-semi mx-auto cursor-pointer px-4 py-2 text-center text-xl text-slate-600 hover:text-yellow-400 focus:outline-none"
                        onClick={() => {
                            tariffCalcPopupAtom.set(true);
                        }}
                    >
                        Zum Tarifrechner
                    </div>
                    <RappenPropInputField
                        label={'Standard Strompreis Rp/kWh'}
                        type={'number'}
                        defaultVal={externalPrice}
                        suggestion={externalSuggestion}
                        propName={'external'}
                        obj={tariff}
                        setObj={updateTariff}
                    />
                    <RappenPropInputField
                        label={'Interner strompreis Rp/kWh'}
                        type={'number'}
                        defaultVal={internalPrice}
                        suggestion={internalSuggestion}
                        propName={'internal'}
                        obj={tariff}
                        setObj={updateTariff}
                    />
                    {warning && (
                        <div className="max-w-[450px] rounded-md border border-solid border-orange-500 px-4 py-2 text-orange-500">
                            {warning}
                        </div>
                    )}
                </div>
            )}
        </MobileModal>
    );
};
