import React from 'react';
import * as R from 'ramda';
import { MobileModal } from '../../components/MobileModal';
import { tenantRightsPopup } from '../../../state/states';
import { useAtom } from '@gothub-team/got-atom';
import { PropInputField } from '../../components/PropInputField';
import { useEditTenantRights } from '../../../hooks/useEditTenantRights';
import Icon from '../../../components/Icon';
import { AddButton } from '../../components/Buttons';

export const TenantRightsModalMobile = () => {
    const tenantRights = useAtom(tenantRightsPopup);
    return (
        <MobileModal
            onClose={() => tenantRightsPopup.set(false)}
            title="Rechte vergeben"
            isOpen={tenantRights}
        >
            {tenantRights && <Content />}
        </MobileModal>
    );
};

const Content = () => {
    const { eMail, setEMail, handleClick, handleRemove, rights } = useEditTenantRights();
    const email = R.propOr('', 'email', eMail);
    const mightBeValidMail = R.includes('.', email) && R.includes('@', email) ? true : false;
    return (
        <>
            <div className="flex h-full w-full flex-col p-3">
                <div className="flex w-full items-center gap-5">
                    <PropInputField
                        label="Email-Adresse"
                        propName="email"
                        obj={eMail}
                        setObj={setEMail}
                    />
                </div>
                <div className="mt-5 flex w-full flex-col flex-wrap gap-3">
                    {rights.map((rightsEMail, index) => (
                        <div
                            className="flex items-center gap-2 rounded-xl px-2 py-1 text-slate-600"
                            key={index}
                        >
                            <div className="text-lg">{rightsEMail}</div>
                            <div
                                className="ml-2 flex h-4 w-4 cursor-pointer items-center justify-center rounded-lg hover:scale-110"
                                onClick={() => handleRemove(rightsEMail)}
                                title="Löschen"
                            >
                                <Icon name="trash-can" className="fill-slate-600" />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <AddButton
                onClick={mightBeValidMail ? handleClick : () => {}}
                className={mightBeValidMail ? 'opacity-100' : 'opacity-40'}
            />
        </>
    );
};
