import React, { useState } from 'react';
import { motion } from 'framer-motion';

const FadeInDiv = ({ delay, children, onComplete }) => (
    <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay, onComplete }}
    >
        {children}
    </motion.div>
);

export default function Loading() {
    const [shouldBounce, setShouldBounce] = useState(false);

    const handleComplete = () => {
        setTimeout(() => {
            setShouldBounce(true);
        }, 300);
    };

    return (
        <div className="flex h-screen items-center justify-center text-emerald-700">
            {shouldBounce ? (
                <motion.h1
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontWeight: 200,
                    }}
                    animate={{
                        y: [-10, 0, -10],
                        transition: { duration: 0.5, repeat: Infinity },
                    }}
                >
                    <div>H</div>
                    <div style={{ width: '0.5rem' }} />
                    <div>e</div>
                    <div style={{ width: '0.5rem' }} />
                    <div>r</div>
                    <div style={{ width: '0.5rem' }} />
                    <div>b</div>
                    <div style={{ width: '0.5rem' }} />
                    <div>E</div>
                    <div style={{ width: '0.5rem' }} />
                    <div>n</div>
                    <div style={{ width: '0.5rem' }} />
                    <div>e</div>
                    <div style={{ width: '0.5rem' }} />
                    <div>r</div>
                    <div style={{ width: '0.5rem' }} />
                    <div>g</div>
                    <div style={{ width: '0.5rem' }} />
                    <div>y</div>
                </motion.h1>
            ) : (
                <motion.h1
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontWeight: 200,
                    }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ onComplete: handleComplete }}
                >
                    <FadeInDiv delay={0.05}>H</FadeInDiv>
                    <div style={{ width: '0.5rem' }} />
                    <FadeInDiv delay={0.1}>e</FadeInDiv>
                    <div style={{ width: '0.5rem' }} />
                    <FadeInDiv delay={0.15}>r</FadeInDiv>
                    <div style={{ width: '0.5rem' }} />
                    <FadeInDiv delay={0.2}>b</FadeInDiv>
                    <div style={{ width: '0.5rem' }} />
                    <FadeInDiv delay={0.25}>E</FadeInDiv>
                    <div style={{ width: '0.5rem' }} />
                    <FadeInDiv delay={0.3}>n</FadeInDiv>
                    <div style={{ width: '0.5rem' }} />
                    <FadeInDiv delay={0.35}>e</FadeInDiv>
                    <div style={{ width: '0.5rem' }} />
                    <FadeInDiv delay={0.4}>r</FadeInDiv>
                    <div style={{ width: '0.5rem' }} />
                    <FadeInDiv delay={0.45}>g</FadeInDiv>
                    <div style={{ width: '0.5rem' }} />
                    <FadeInDiv delay={0.5}>y</FadeInDiv>
                </motion.h1>
            )}
        </div>
    );
}
