import React from 'react';
import Icon from '../../components/Icon';

export const Chaotic3dLogoSpinner = ({ hscreen }) => (
    <div
        className={`fade-in flex w-full items-center justify-center ${
            hscreen ? 'h-screen' : 'h-full'
        }`}
    >
        <div className="chaotic-3d-spin mb-8 flex h-[7.5rem] w-[7.5rem] flex-col items-center">
            <Icon name="logo" className="h-[7.5rem] w-[7.5rem]" />
            <div className="-translate-y-6 text-xs">H e r b E n e r g y</div>
        </div>
    </div>
);
