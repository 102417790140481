import React, { useState } from 'react';
import TenantEnergyGraph from './TenantEnergyGraph/TenantEnergyGraph';
import TenantstatisticsDonut from './TenantStatisticsDonut';
import ReactWeather from 'react-open-weather';
import { useOpenWeatherWithBackup } from '../hooks/useOpenWeatherFreemium';
import { SelectedTenantIdAtom, selectedZEVID, meterSelectionPopup } from '../state/states';
import { useAtom } from '@gothub-team/got-atom';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import NavMenuT from './NavMenuT';
import { useGraph } from '../misc/got.config';
import { EDITOR, MAIN } from '../misc/consts';
import { Tile } from '../components/Tile';
import { LogoutButton } from '../components/Logout';
import { InvoiceButtons } from '../components/InvoiceButtons';
import MeterSelection from './Modals/MeterSelection';
import { InvoicesDisabled, TenantInvoiceList } from '../landlord/InvoiceList';
import { Chaotic3dLogoSpinner } from '../Mobile/components/Chaotic3dLogoSpinner';
import { TenantInfoModal } from './Modals/TennantInfo/TenantInfoModal';
import { TariffDetailsModal } from './Modals/TariffDetails/TariffDetailsModal';
import { ExportModal } from './Modals/ExportModal';
import { StatisticsTile } from './TenantStatisticsSimple';
import { FEATURE_MODE, useFeatureMode } from '../hooks/useFeatureMode';
import { ZevSelectionModal } from '../modals/ZevSelectionModal';
dayjs.locale('de');

export default function TenantMain() {
    const selectedTenantId = useAtom(SelectedTenantIdAtom);
    const selectedZevId = useAtom(selectedZEVID);

    const { useNode } = useGraph(MAIN, EDITOR);
    const tenant = useNode(selectedTenantId);
    const landLord = useNode(selectedZevId);

    const { cityT, nameT } = tenant || {};
    const { city } = landLord || {};

    const cityWeather = selectedTenantId === selectedZevId ? city : cityT;

    const { data, isLoading, errorMessage } = useOpenWeatherWithBackup(cityWeather);

    const tenSelectionPopup = useAtom(meterSelectionPopup);

    const featureMode = useFeatureMode();

    return (
        <>
            <div
                className="fade-in grid-cols-[repeat(18, minmax(0, 1fr))] grid h-screen w-screen grid-rows-2 gap-5 bg-neutral-300/50 p-5"
                style={{ gridTemplateColumns: 'repeat(18, minmax(0, 1fr))' }}
            >
                <Tile
                    className="col-start-1 col-end-13 row-start-1 row-end-2 px-3"
                    title={`Verbrauchsübersicht - ${nameT}`}
                    content={selectedTenantId && <TenantEnergyGraph />}
                />
                <StatisticsTile />
                <Tile
                    className="col-start-[17] col-end-[19] row-start-1 row-end-2 px-3"
                    title="Menü"
                    topbar={<LogoutButton />}
                    content={selectedTenantId && <NavMenuT />}
                />
                <WeatherTile
                    isLoading={isLoading}
                    errorMessage={errorMessage}
                    data={data}
                    cityWeather={cityWeather}
                />
                <Tile
                    className="col-start-7 col-end-11 row-start-2 row-end-3 px-3"
                    title="Autarkie"
                    content={<TenantstatisticsDonut />}
                />
                <Tile
                    className="col-start-11 col-end-[19] row-start-2 row-end-3 px-3"
                    title="Rechnungsübersicht"
                    topbar={<InvoiceButtons />}
                    content={
                        featureMode === FEATURE_MODE.FULL ? (
                            <TenantInvoiceList />
                        ) : (
                            <InvoicesDisabled />
                        )
                    }
                    noScrollbar
                />
            </div>
            <TenantInfoModal />
            <TariffDetailsModal />
            <ExportModal />
            <ZevSelectionModal />
            {tenSelectionPopup && <MeterSelection />}
        </>
    );
}

const WeatherTile = ({ isLoading, errorMessage, data, cityWeather }) => {
    const [showScrollbar, setShowScrollbar] = useState(false);

    return (
        <Tile
            className="col-start-1 col-end-7 row-start-2 row-end-3"
            content={
                isLoading ? (
                    <div className=" flex h-full items-center justify-center">
                        <Chaotic3dLogoSpinner />
                    </div>
                ) : (
                    <ReactWeather
                        errorMessage={errorMessage}
                        data={data}
                        lang="de"
                        locationLabel={cityWeather}
                        unitsLabels={{ temperature: 'C', windSpeed: 'Km/h' }}
                        showForecast
                    />
                )
            }
            onMouseEnter={() => setShowScrollbar(true)}
            onMouseLeave={() => setShowScrollbar(false)}
            noScrollbar={!showScrollbar}
        />
    );
};
