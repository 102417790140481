import React from 'react';
import { Modal } from '../../components/Modal';
import { Input } from '../../components/Input';
import { useEditLandlord } from '../../hooks/useEditLandlord';
export const EditLandlordModal = () => {
    const { landLord, update, onSave, onClose, loggedInLLID } = useEditLandlord();

    const {
        name,
        adress,
        tel,
        bank,
        iBAN,
        bIC,
        amtsgericht,
        geschäftsführer,
        taxNumber,
        pLZ,
        city,
    } = landLord || {};

    return (
        <Modal onClose={onClose} onSave={onSave} scroll title={name}>
            <div className="mx-auto flex flex-col justify-center gap-5">
                <Input
                    label={'Name'}
                    type={'text'}
                    value={name}
                    onChange={value => update({ id: loggedInLLID, name: value })}
                />
                <Input
                    label={'Adresse'}
                    type={'text'}
                    value={adress}
                    onChange={value => update({ id: loggedInLLID, adress: value })}
                />
                <Input
                    label={'PLZ'}
                    type={'text'}
                    value={pLZ}
                    onChange={value => update({ id: loggedInLLID, pLZ: value })}
                />
                <Input
                    label={'Stadt'}
                    type={'text'}
                    value={city}
                    onChange={value => update({ id: loggedInLLID, city: value })}
                />
                <Input
                    label={'Telefonnummer'}
                    type={'text'}
                    value={tel}
                    onChange={value => update({ id: loggedInLLID, tel: value })}
                />
                <Input
                    label={'Name der Bank'}
                    type={'text'}
                    value={bank}
                    onChange={value => update({ id: loggedInLLID, bank: value })}
                />
                <Input
                    label={'IBAN'}
                    type={'text'}
                    value={iBAN}
                    onChange={value => update({ id: loggedInLLID, iBAN: value })}
                />
                <Input
                    label={'BIC'}
                    type={'text'}
                    value={bIC}
                    onChange={value => update({ id: loggedInLLID, bIC: value })}
                />
                <Input
                    label={'Amtsgericht'}
                    type={'text'}
                    value={amtsgericht}
                    onChange={value => update({ id: loggedInLLID, amtsgericht: value })}
                />
                <Input
                    label={'Geschäftsführer'}
                    type={'text'}
                    value={geschäftsführer}
                    onChange={value => update({ id: loggedInLLID, geschäftsführer: value })}
                />
                <Input
                    label={'Steuernummer'}
                    type={'text'}
                    value={taxNumber}
                    onChange={value => update({ id: loggedInLLID, taxNumber: value })}
                />
            </div>
        </Modal>
    );
};
