import * as R from 'ramda';

const propSum = R.compose(R.sum, R.map(R.prop('value')));

export const calcTariff = tariffInfo => {
    try {
        // ----------CONSTANT----------

        // ----------INPUT----------
        // general information and expected values
        // const installedPower = tariffInfo.installedPower; // 30;
        const fixedInvestmentArr = tariffInfo.fixedInvestment; // 50000;
        const fixedInvestment = propSum(fixedInvestmentArr);
        const subsidyArr = tariffInfo.subsidy; // 10000;
        const subsidy = propSum(subsidyArr);
        const age = tariffInfo.age; // 5;

        // manual expected values
        const expectedPower = tariffInfo.expectedPower; // 30000 || installedPower * 1000;
        const degradation = tariffInfo.degradation / 100; // 0.005;
        const usageEfficiency = tariffInfo.usageEfficiency / 100; // 0.6;
        const expectedTotalUsage = tariffInfo.expectedTotalUsage; // 40000; // todo

        // investment interest thingies
        const maxInterestYears = tariffInfo.maxInterestYears; // 25;
        const currentReferenceInterestRate = tariffInfo.currentReferenceInterestRate / 100; // 0.0157;
        const riskFreeInterestRate = tariffInfo.riskFreeInterestRate / 100; // 0.005;

        // external product
        const buyTariff = tariffInfo.buyTariff; // 0.1777;
        const workingTariff = tariffInfo.workingTariff; // 0.0991;
        const additionalCostsPerKWhArr = tariffInfo.additionalCostsPerKWh || []; // array of positions
        const additionalCostsPerKWh = propSum(additionalCostsPerKWhArr);
        const baseCost = tariffInfo.baseCost; // 85.3;

        // sell to external
        const sellTariff = tariffInfo.sellTariff; // 0.14;
        const regionalSellTariff = tariffInfo.regionalSellTariff ?? 0; // 0.0;

        // internal costs
        const runningCostsArr = tariffInfo.runningCosts || []; // aray of running cost positions
        const runningCosts = propSum(runningCostsArr);
        const runningCostskWhArr = tariffInfo.runningCostskWh || []; // array of running cost per kWh positions
        const runningCostskWh = propSum(runningCostskWhArr);
        // const fixedInternalBaseCost = tariffInfo.fixedInternalBaseCost; // 800;

        // const tarifUrl

        // ----------CALCULATION----------
        // expected values
        const expectedPowerAtAge = expectedPower * (1 - degradation) ** age;
        const expectedInteralUsage = expectedPowerAtAge * usageEfficiency;
        const expectedSold = expectedPowerAtAge - expectedInteralUsage;
        const expectedBuy = expectedTotalUsage - expectedInteralUsage;
        // external product costs
        const externalTariff = R.sum([
            buyTariff,
            workingTariff,
            additionalCostsPerKWh,
            baseCost / expectedBuy,
        ]);

        // interestRates
        // Zinssatz
        const actualInterestRate = R.sum([currentReferenceInterestRate, riskFreeInterestRate]);
        // Kapitalkostenrendite
        const maximumInterestRate =
            ((1 + actualInterestRate) ** maxInterestYears * actualInterestRate) /
            ((1 + actualInterestRate) ** maxInterestYears - 1);

        // internal costs
        const totalInvestment = fixedInvestment - subsidy;

        // returns
        const sellReturn = expectedSold * sellTariff;
        const regionalSellReturn = expectedSold * regionalSellTariff;
        const totalSellReturn = R.sum([sellReturn, regionalSellReturn]);

        const yearlyInvestmentCosts = totalInvestment * maximumInterestRate;
        const yearlyCostsGross = runningCosts + yearlyInvestmentCosts - totalSellReturn;

        const internalTariffCosts = yearlyCostsGross / expectedInteralUsage + runningCostskWh;
        const tariffDifference = externalTariff - internalTariffCosts;
        const internalTariff = Math.min(
            externalTariff * 0.8,
            R.sum([internalTariffCosts + tariffDifference / 2]),
        );

        return {
            expectedPowerAtAge,
            expectedInteralUsage,
            expectedSold,
            expectedBuy,
            actualInterestRate,
            maximumInterestRate,
            sellReturn,
            regionalSellReturn,
            totalSellReturn,
            yearlyInvestmentCosts,
            yearlyCostsGross,
            runningCostskWh,
            tariffDifference,
            externalTariff,
            internalTariffCosts,
            internalTariff,
        };
    } catch (err) {
        return {};
    }
};
