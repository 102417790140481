import { FieldsLeft, FieldsRight } from './shared';
import { tenantTariffDetailsPopup } from '../../../state/states';
import { useAtom } from '@gothub-team/got-atom';
import { useTariffCalculator } from '../../../hooks/useTariffEdit';
import { Modal } from '../../../components/Modal';

export const TariffDetailsModal = () => {
    const { tariffInfo, tariffRes } = useTariffCalculator();
    const isOpen = useAtom(tenantTariffDetailsPopup);

    if (!isOpen) return null;

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => tenantTariffDetailsPopup.set(false)}
            title="TarifDetails"
            scroll
        >
            <div className="mx-auto flex flex-row justify-center gap-16 px-8">
                <div className="flex flex-col gap-5">
                    <FieldsLeft tariffInfo={tariffInfo} tariffRes={tariffRes}></FieldsLeft>
                </div>
                <div className="flex flex-col gap-5">
                    <FieldsRight tariffInfo={tariffInfo} tariffRes={tariffRes}></FieldsRight>
                </div>
            </div>
        </Modal>
    );
};
