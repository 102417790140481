import React from 'react';
import { SelectedTenantIdAtom, selectedZEVID } from '../../state/states';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Modal } from '../../components/Modal';
import { Values } from './CreateInvoice/shared';
import { useInvoiceData } from '../../hooks/useInvoiceData';
import { atom, useAtom } from '@gothub-team/got-atom';
import { floatpoint } from '../../misc/util';
import { useGraph } from '../../misc/got.config';
import { EDITOR, MAIN } from '../../misc/consts';

export const InvoiceDataModalAtom = atom(false);

export const InvoiceDataModal = () => {
    const isOpen = useAtom(InvoiceDataModalAtom);
    if (!isOpen) return null;

    return <Content />;
};

const Content = () => {
    const zevId = useAtom(selectedZEVID);
    const meterId = useAtom(SelectedTenantIdAtom);
    const {
        invoiceError,
        invoiceDataLoading,
        selectedEndDate,
        selectedStartDate,
        setSelectedEndDate,
        setSelectedStartDate,
        totalStart,
        totalEnd,
        internalStart,
        internalEnd,
    } = useInvoiceData(zevId, meterId);
    const { useNode } = useGraph(MAIN, EDITOR);
    const tenant = useNode(meterId);
    const { nameT } = tenant || {};

    return (
        <Modal onClose={() => InvoiceDataModalAtom.set(false)} title={'Zählerdaten für ' + nameT}>
            <form className="relative w-fit" onSubmit={e => e.preventDefault()}>
                <div className="my-4 flex w-full items-center justify-between gap-10">
                    <div className="flex flex-col">
                        <div className="mb-1 text-xs font-medium text-slate-600">
                            Startdatum wählen:
                        </div>
                        <DatePicker
                            selected={selectedStartDate}
                            onChange={setSelectedStartDate}
                            dateFormat="dd.MM.yyyy"
                            maxDate={new Date(new Date().getTime() - 1000 * 60 * 60 * 24)}
                            isClearable
                            placeholderText="Datum"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            className="rounded-md outline-none focus:border-yellow-400"
                        />
                    </div>
                    <div className="flex flex-col">
                        <div className="mb-1 text-xs font-medium text-slate-600">
                            Enddatum wählen:
                        </div>

                        <DatePicker
                            selected={selectedEndDate}
                            onChange={setSelectedEndDate}
                            dateFormat="dd.MM.yyyy"
                            maxDate={new Date(new Date().getTime() - 1000 * 60 * 60 * 24)}
                            isClearable
                            placeholderText="Datum"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            className="rounded-md outline-none focus:border-yellow-400"
                        />
                    </div>
                </div>
                {invoiceDataLoading === 'pending' && (
                    <div className="w-full text-center">Lade Daten...</div>
                )}
                {invoiceDataLoading === 'loaded' && (
                    <div>
                        <Values
                            name="Import Zähler"
                            start={`${floatpoint(totalStart, 3)} kWh`}
                            end={`${floatpoint(totalEnd, 3)} kWh`}
                        ></Values>
                        <Values
                            name="Eigenverbrauch Zähler"
                            start={`${floatpoint(internalStart, 3)} kWh`}
                            end={`${floatpoint(internalEnd, 3)} kWh`}
                        ></Values>
                    </div>
                )}
                {invoiceDataLoading === 'error' && (
                    <div className="w-full text-center text-red-600">{invoiceError}</div>
                )}
            </form>
        </Modal>
    );
};
