import React, { useState } from 'react';
import { animated, useSpring } from 'react-spring';

export const ClickAnimator = ({ children, style }) => {
    const [state, toggle] = useState(false);
    const { x } = useSpring({
        from: { x: 0 },
        x: state ? 1 : 0,
    });
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyItems: 'center',
                width: '100%',
            }}
            onClick={() => toggle(!state)}
        >
            <animated.div
                style={{
                    scale: x.to({
                        range: [0, 0.5, 1],
                        output: [1, 1.15, 1],
                    }),
                    ...style,
                    width: '100%',
                    position: 'relative',
                }}
            >
                {children}
            </animated.div>
        </div>
    );
};
