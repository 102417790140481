/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { getIcon } from 'react-open-weather/src/js/providers/openweather/iconsMap';
import dayjs from 'dayjs';
import { herbenergyApi } from '../misc/herbenergy.api';

const initialState = {
    data: null,
    errorMessage: null,
};

const formatDate = (dte, lang) => {
    if (lang && lang !== 'en') {
        dayjs.locale(lang.replace('_', '-'));
    }
    if (dte && dayjs().isValid(dte)) {
        return dayjs.unix(dte).format('ddd D MMMM HH:mm');
    }
    return '';
};

const mapCurrent = (day, lang) => {
    return {
        date: formatDate(day.dt, lang),
        description: day.weather[0] ? day.weather[0].description : null,
        icon: day.weather[0] && getIcon(day.weather[0].icon),
        temperature: {
            current: day.main.temp.toFixed(0),
            min: day.main.temp_min.toFixed(0),
            max: day.main.temp_max.toFixed(0),
        },
        wind: day.wind.speed.toFixed(0),
        humidity: day.main.humidity,
    };
};

const mapForecast = (forecast, lang) => {
    const mappedForecast = [];
    for (let i = 0; i < 5; i += 1) {
        const item = forecast.list[i];
        const weather = item.weather[0];
        mappedForecast.push({
            date: formatDate(item.dt, lang),
            description: weather ? weather.description : null,
            icon: weather && getIcon(weather.icon),
            temperature: {
                min: item.main.temp_min.toFixed(0),
                max: item.main.temp_max.toFixed(0),
            },
            wind: item.wind.speed.toFixed(0),
            humidity: item.main.humidity,
        });
    }
    return mappedForecast;
};

const mapData = (forecastData, todayData, lang) => {
    const mapped = {};
    if (forecastData && todayData) {
        const daysData = forecastData;
        mapped.current = mapCurrent(todayData, lang);
        mapped.forecast = mapForecast(daysData);
    }
    return mapped;
};

export const useOpenWeatherFreemium = options => {
    const [{ data, errorMessage }, setData] = useState(initialState);
    const [isLoading, setIsLoading] = useState(false);
    const { unit, lang, q, lon, lat } = options;

    const fetchData = async () => {
        const [city] = q.split(',');
        if (!city) return;

        setIsLoading(true);
        try {
            const params = {
                lang,
                units: unit,
                ...(q ? { q } : { lat, lon }),
            };

            const { current, forecast } = await herbenergyApi.getWeather(params);
            const newData = mapData(forecast, current, lang);
            setData({ data: newData, errorMessage: null });
        } catch (error) {
            setData({ data: null, errorMessage: error.message || error });
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [lon, lat, q]);

    return { data, errorMessage, isLoading };
};

const country1 = 'de';
const country2 = 'ch';
export const useOpenWeatherWithBackup = (city = '') => {
    const {
        data: data1,
        isLoading: isLoading1,
        errorMessage: errorMessage1,
    } = useOpenWeatherFreemium({
        q: `${city},${country1}`,
        lang: 'de',
        unit: 'metric', // values are (metric, standard, imperial)
    });
    const {
        data: data2,
        isLoading: isLoading2,
        errorMessage: errorMessage2,
    } = useOpenWeatherFreemium({
        q: `${city},${country2}`,
        lang: 'de',
        unit: 'metric', // values are (metric, standard, imperial)
    });

    const data = data1 || data2;
    const isLoading = isLoading1 || isLoading2;
    const errorMessage = errorMessage1 && errorMessage2 ? errorMessage1 : null;
    return { data, errorMessage, isLoading };
};
