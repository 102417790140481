/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import { useGraph } from '../misc/got.config';
import { herbenergyApi, METER_DATA_EDITOR, METER_DATA_MAIN } from '../misc/herbenergy.api';
import { fillDayData, fillMonthData, fillYearData } from '../misc/meterData.util';
import { useAtom } from '@gothub-team/got-atom';
import { loadAtom } from '../state/loadatom';
import { SelectedTenantIdAtom, selectedZEVID } from '../state/states';
import * as R from 'ramda';

export const LoadState = loadAtom();
export const refreshYearData = async (zevId, tenantId, year) => {
    const id = `data/${zevId}/${tenantId}/year/${year}`;
    await LoadState.loadFn(id, () => herbenergyApi.pullYear(zevId, tenantId, year));
};
export const refreshMonthData = async (zevId, tenantId, month) => {
    const id = `data/${zevId}/${tenantId}/month/${month}`;

    await LoadState.loadFn(id, () => herbenergyApi.pullMonth(zevId, tenantId, month));
};
export const refreshDayData = async (zevId, tenantId, day) => {
    const id = `data/${zevId}/${tenantId}/day/${day}`;
    await LoadState.loadFn(id, () => herbenergyApi.pullDay(zevId, tenantId, day));
};

export const getMode = (year, month, day) => {
    if (year && month && day) {
        return 'day';
    } else if (year && month) {
        return 'month';
    } else if (year) {
        return 'year';
    } else return '';
};

export const getSelectedTime = (year, month, day) => {
    if (year && month && day) {
        return `${year}-${month}-${day}`;
    } else if (year && month) {
        return `${year}-${month}`;
    } else if (year) {
        return year;
    } else return '';
};

const completeData = (zevId, tenantId, mode, selectedTime) => data => {
    if (!data) return data;
    if (!selectedTime) return data;
    if (mode === 'day') {
        return fillDayData(zevId, tenantId, selectedTime.toString(), data);
    }
    if (mode === 'month') {
        return fillMonthData(zevId, tenantId, selectedTime.toString(), data);
    }
    if (mode === 'year') {
        return fillYearData(zevId, tenantId, selectedTime.toString(), data);
    }
    return data;
};

const tryParseFloat = str => {
    try {
        if (R.is(String, str)) {
            return parseFloat(str);
        }
    } catch (err) {}

    return str;
};

export const useChartData = (year, month, day) => {
    const mode = getMode(year, month, day);
    const zevId = useAtom(selectedZEVID);
    const tenantId = useAtom(SelectedTenantIdAtom);
    const { useNode } = useGraph(METER_DATA_MAIN, METER_DATA_EDITOR);
    React.useEffect(() => {
        if (zevId && tenantId) {
            year && refreshYearData(zevId, tenantId, year);
            year &&
                month &&
                refreshMonthData(zevId, tenantId, `${year}-${month}`).then(() => {
                    if (year && month && day) {
                        // day data after month, since it overwrites incomplete month data
                        refreshDayData(zevId, tenantId, `${year}-${month}-${day}`);
                    }
                });
        }
    }, [zevId, tenantId, year, month, day]);
    React.useEffect(() => {
        if (zevId && tenantId) {
            const currYear = new Date().toISOString().substring(0, 4);
            const currMonth = new Date().toISOString().substring(0, 7);
            const currDay = new Date().toISOString().substring(0, 10);
            refreshYearData(zevId, tenantId, currYear);
            refreshMonthData(zevId, tenantId, currMonth).then(() => {
                // day data after month, since it overwrites incomplete month data
                refreshDayData(zevId, tenantId, currDay);
            });
        }
    }, [zevId, tenantId]);

    const selectedTime = getSelectedTime(year, month, day);

    // const selectedTime = "2020-01";
    // const selectedTime = "2020";
    const nodeId = `data/${zevId}/${tenantId}/${mode}/${selectedTime}`;

    const completor = useCallback(completeData(zevId, tenantId, mode, selectedTime), [
        zevId,
        tenantId,
        mode,
        selectedTime,
    ]);
    const chartData = useNode(nodeId, completor);

    if (!chartData) return chartData;

    return R.over(
        R.lensProp('values'),
        R.map(entry => {
            return {
                ...entry,
                import: tryParseFloat(entry.import),
                internal: tryParseFloat(entry.internal),
                export: tryParseFloat(entry.export),
            };
        }),
    )(chartData);
};

export const calcDelta = (prev, curr) => {
    if (!curr) return 0;
    if (!prev) return curr;

    return curr - prev;
};

export const calcDeltas = (start, array, propName) => {
    let prevVal = start;
    const result = [];

    for (let i = 0; i < array.length; i++) {
        const data = array[i];
        const curr = data?.[propName];
        if (curr !== undefined) {
            const delta = curr - prevVal;
            result.push(delta);
            prevVal = curr;
        } else {
            result.push(0);
        }
    }

    return result;
};

export const calcWattage = (deltaArray, factor) => {
    const result = new Array(deltaArray.length);

    for (let i = 0; i < deltaArray.length; i++) {
        const wattage = (deltaArray[i] || 0) * factor;
        result[i] = wattage;
    }

    return result;
};

export const getTimeLabel = (mode, item) => {
    if (!item || !item.timestamp) return '';

    if (mode === 'day') {
        return new Date(item.timestamp).toLocaleTimeString('de-DE').slice(0, 5);
    } else if (mode === 'month') {
        return new Date(item.timestamp).toLocaleDateString('de-DE', { day: '2-digit' });
    } else if (mode === 'year') {
        return new Date(item.timestamp).toLocaleDateString('de-DE', { month: 'short' });
    }

    return '';
};

export const getLabels = (mode, data) => {
    if (!data) return [];

    return data.map(item => getTimeLabel(mode, item));
};
