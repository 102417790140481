import { gotApi } from '../../misc/got.config';
// import { useToast } from '../../toast/useToast';
import { toast } from 'react-toastify';

const setToast = (type, message, duration) => {
    toast(message);
};

export const useAuthActions = ({ onUserNotVerified, onPasswordResetRequired }) => {
    // const setToast = useToast('auth-toast');

    const sanitizeEmail = email => email.replace(/ /g, '').toLowerCase();
    const sanitizeCode = code => code.replace(/ /g, '');

    const verifyEmail = email => {
        if (!email) {
            setToast('error', 'E-Mail fehlt...', 7500);
            return false;
        }
        return true;
    };

    const verifyPassword = password => {
        if (!password) {
            setToast('error', 'Passwort fehlt...', 7500);
            return false;
        }
        return true;
    };

    const verifyMatchingPasswords = (password, passwordConfirm) => {
        if (password !== passwordConfirm) {
            setToast('error', 'Passwörter stimmen nicht überein...', 7500);
            return false;
        }
        return true;
    };

    const verifyCode = code => {
        if (!code) {
            setToast('error', 'Aktivierungs Code fehlt...', 7500);
            return false;
        }
        return true;
    };

    const handleError = (err, type) => {
        if (err.name === 'LoginVerifyError') {
            setToast('error', 'Benutzername oder Passwort ist falsch...', 7500);
        } else if (err.name === 'UserNotFoundError') {
            setToast('error', 'Benutzer existiert nicht...', 7500);
        } else if (err.name === 'UserExistsError') {
            setToast('error', 'Benutzer existiert bereits...', 7500);
        } else if (err.name === 'InvalidEmailError') {
            setToast('error', 'E-Mail ist ungültig...', 7500);
        } else if (err.name === 'InvalidPasswordError') {
            setToast('error', 'Passwort ist ungültig...', 7500);
        } else if (err.name === 'VerificationCodeMismatchError') {
            setToast('error', 'Aktivierungscode ist falsch...', 7500);
        } else if (err.name === 'VerificationCodeExpiredError') {
            setToast('error', 'Aktivierungscode ist abgelaufen...', 7500);
        } else if (err.name === 'UserNotVerifiedError') {
            onUserNotVerified && onUserNotVerified();
        } else if (err.name === 'PasswordResetRequiredError') {
            onPasswordResetRequired && onPasswordResetRequired();
        } else {
            console.error('unknown auth error', err);
            setToast('error', `Fehler beim ${type}...`, 7500);
        }
    };

    const login = async (emailRaw, password) => {
        const email = sanitizeEmail(emailRaw || '');

        if (!verifyEmail(email)) return false;
        if (!verifyPassword(password)) return false;

        setToast('spinner', 'Anmelden...', 7500);
        try {
            await gotApi.login({ email, password });
            return true;
        } catch (err) {
            handleError(err, 'Anmelden');
            return false;
        }
    };

    const register = async (emailRaw, password, passwordConfirm) => {
        const email = sanitizeEmail(emailRaw || '');

        if (!verifyEmail(email)) return false;
        if (!verifyPassword(password)) return false;
        if (!verifyMatchingPasswords(password, passwordConfirm)) return false;

        setToast('spinner', 'Registriere...', 7500);
        try {
            await gotApi.registerInit({ email: email.toLowerCase(), password });
            return true;
        } catch (err) {
            handleError(err, 'Registrieren');
            return false;
        }
    };

    const registerVerify = async (emailRaw, codeRaw) => {
        const email = sanitizeEmail(emailRaw || '');
        const code = sanitizeCode(codeRaw || '');

        if (!verifyEmail(email)) return false;
        if (!verifyCode(code)) return false;

        setToast('spinner', 'Aktiviere...', 7500);
        try {
            await gotApi.registerVerify({ email, verificationCode: code });
            return true;
        } catch (err) {
            handleError(err, 'Aktivieren');
            return false;
        }
    };

    const registerVerifyResend = async emailRaw => {
        const email = sanitizeEmail(emailRaw || '');

        if (!verifyEmail(email)) return false;

        setToast('spinner', 'Aktivierungscode wird erneut versendet...', 7500);
        try {
            await gotApi.registerVerifyResend({ email });
            setToast('spinner', 'Aktivierungscode wurde versendet...', 7500);
            return true;
        } catch (err) {
            handleError(err, 'Versenden');
            return false;
        }
    };

    const resetPassword = async emailRaw => {
        const email = sanitizeEmail(emailRaw || '');

        if (!verifyEmail(email)) return false;

        setToast('spinner', 'Setze Passwort zurück...', 7500);
        try {
            await gotApi.resetPasswordInit({ email });
            return true;
        } catch (err) {
            handleError(err, 'Passwort zurücksetzen');
            return false;
        }
    };

    const resetPasswordVerify = async (emailRaw, codeRaw, password, passwordConfirm) => {
        const email = sanitizeEmail(emailRaw || '');
        const code = sanitizeCode(codeRaw || '');

        if (!verifyEmail(email)) return false;
        if (!verifyCode(code)) return false;
        if (!verifyPassword(password)) return false;
        if (!verifyMatchingPasswords(password, passwordConfirm)) return false;

        setToast('spinner', 'Setze Passwort zurück...', 7500);
        try {
            await gotApi.resetPasswordVerify({ email, verificationCode: code, password });
            return true;
        } catch (err) {
            handleError(err, 'Passwort zurücksetzen');
            return false;
        }
    };

    return {
        login,
        register,
        registerVerify,
        registerVerifyResend,
        resetPassword,
        resetPasswordVerify,
    };
};
