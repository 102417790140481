import React from 'react';
import { useAtom } from '@gothub-team/got-atom';
import { dashboardMobileAtom } from '../../state/states';
import { ConsProdComparison } from './ConsProdComparison';
import { WeatherMobile } from './WeatherMobile';
import { Invoices, InvoicesDisabled } from './Invoices';
import { StatisticsSimple } from './StatisticsSimple';
import { FEATURE_MODE, useFeatureMode } from '../../hooks/useFeatureMode';

export const Screens = ({ tenantView }) => {
    const selectedScreen = useAtom(dashboardMobileAtom);
    const featureMode = useFeatureMode();
    switch (selectedScreen) {
        case 'consumption':
            return (
                <Screen className="items-center">
                    <ConsProdComparison />
                </Screen>
            );
        case 'metrics':
            return (
                <Screen className="items-center">
                    <StatisticsSimple />
                </Screen>
            );
        case 'invoices':
            return (
                <Screen className="items-start">
                    {featureMode === FEATURE_MODE.FULL ? (
                        <Invoices tenantView={tenantView} />
                    ) : (
                        <InvoicesDisabled />
                    )}
                </Screen>
            );
        case 'weather':
            return (
                <Screen className="items-center">
                    <WeatherMobile />
                </Screen>
            );
        default:
            return <></>;
    }
};

const Screen = ({ children, className }) => {
    return (
        <div
            className={`fixed left-0 top-12 flex h-[calc(100%-7rem)] w-full justify-center overflow-y-auto py-2 ${className}`}
        >
            <div
                className="fixed left-0 top-12 z-10 h-2 w-full -translate-y-[1px]"
                style={{ backgroundImage: 'linear-gradient(white 5%, transparent)' }}
            />
            {children}
            <div
                className="fixed bottom-16 left-0 z-10 h-2 w-full translate-y-[1px]"
                style={{ backgroundImage: 'linear-gradient(transparent 5%, white)' }}
            />
        </div>
    );
};
