import { v4 } from 'uuid';

export const isMobile = () =>
    window.matchMedia('screen and (max-width: 1170px) and (orientation: portrait)').matches;

export const openInNewTab = url => {
    const elementId = `a-${v4()}`;
    const link = document.createElement('a');
    link.id = elementId;
    link.hidden = true;
    link.href = url;
    link.target = '_blank';

    document.body.appendChild(link);
    link.click();
};

export const floor = (exponent, num) => Math.floor(num * 10 ** exponent) / 100;

export const floatpoint = (value, decimals) => {
    let val = value;
    if (typeof value === 'string') {
        val = parseFloat(value);
    }
    return val.toFixed(decimals).replace('.', ',');
};
