import React from 'react';
import 'chart.js/auto';
import {
    SelectedDayAtom,
    SelectedMonthAtom,
    SelectedTenantIdAtom,
    SelectedYearAtom,
    selectedZEVID,
} from '../state/states';
import { useChartData } from '../hooks/useChartData';
import { useAtom } from '@gothub-team/got-atom';
import YearSelection from './YearSelection';
import { useMeterStartValues } from '../hooks/useMeterStartValue';
import { YearlyBarChartPlain } from '../tenant/TenantEnergyGraph/BarChart';
import { YearlyBarChartZevPlain } from '../tenant/TenantEnergyGraph/BarChartZev';

export default function TenantBar() {
    const zevId = useAtom(selectedZEVID);
    const tenantId = useAtom(SelectedTenantIdAtom);

    const selectedMonth = useAtom(SelectedMonthAtom);
    const selectedYear = useAtom(SelectedYearAtom);
    const selectedDay = useAtom(SelectedDayAtom);

    const chartData = useChartData(selectedYear, selectedMonth, selectedDay);
    const chartIsLoaded = chartData?.values?.length > 0;

    const startValues = useMeterStartValues();

    return (
        <>
            <div className="relative h-[75%]">
                <div className="flex h-full flex-col items-center justify-center">
                    {chartIsLoaded && (
                        <div className="flex max-h-full w-[90%] flex-col items-center">
                            {zevId !== tenantId && (
                                <YearlyBarChartPlain
                                    chartData={chartData}
                                    startValues={startValues}
                                />
                            )}
                            {zevId === tenantId && (
                                <YearlyBarChartZevPlain
                                    chartData={chartData}
                                    startValues={startValues}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
            <div className="absolute bottom-4 right-1/2 flex translate-x-1/2 items-center justify-center">
                <YearSelection />
            </div>
        </>
    );
}
