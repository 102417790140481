import { tariffCalcPopupAtom } from '../../../state/states';
import { Modal } from '../../../components/Modal';
import { useTariffCalculator } from '../../../hooks/useTariffEdit';
import { FieldsLeft, FieldsRight } from './shared';

export const TariffCalculatorModal = () => {
    const { onSave, tariffInfo, updateTariffInfo, tariffRes } = useTariffCalculator();

    return (
        <Modal
            onClose={() => tariffCalcPopupAtom.set(false)}
            onSave={onSave}
            title="Tarif berechnen"
            scroll
        >
            <div className="mx-auto flex flex-row justify-center gap-16 px-8">
                <div className="flex flex-col gap-5">
                    <FieldsLeft
                        tariffInfo={tariffInfo}
                        updateTariffInfo={updateTariffInfo}
                        tariffRes={tariffRes}
                    ></FieldsLeft>
                </div>
                <div className="flex flex-col gap-5">
                    <FieldsRight
                        tariffInfo={tariffInfo}
                        updateTariffInfo={updateTariffInfo}
                        tariffRes={tariffRes}
                    ></FieldsRight>
                </div>
            </div>
        </Modal>
    );
};
