import { useAtom } from '@gothub-team/got-atom';
import * as R from 'ramda';
import { SelectedDayAtom, SelectedMonthAtom, SelectedYearAtom } from '../state/states';
import { calcDeltas, useChartData } from './useChartData';
import { useMeterStartValue } from './useMeterStartValue';

export const useDoughnutData = () => {
    const startValue = useMeterStartValue();

    const selectedMonth = useAtom(SelectedMonthAtom);
    const selectedYear = useAtom(SelectedYearAtom);
    const selectedDay = useAtom(SelectedDayAtom);
    const chartData = useChartData(selectedYear, selectedMonth, selectedDay);
    const chartIsLoaded = chartData?.values?.length > 0 ? true : false;
    const chartArray = R.pathOr([], ['values'], chartData);
    const dataStartImport = R.pathOr(startValue, ['start', 'import'], chartData);
    const dataStartInternal = R.pathOr(0, ['start', 'internal'], chartData);

    const dailyInternalArray = calcDeltas(dataStartInternal, chartArray, 'internal');
    const dailySumArray = calcDeltas(dataStartImport, chartArray, 'import');
    const amountInternal = chartIsLoaded ? dailyInternalArray.reduce((prev, cur) => prev + cur) : 0;
    const amountSum = chartIsLoaded ? dailySumArray.reduce((prev, cur) => prev + cur) : 0;
    const amountNet = amountSum - amountInternal;
    const internalPercent = (amountInternal / amountSum) * 100;
    const donutData = {
        labels: ['Solar Eigenverbrauch', 'Netzbezug'],
        datasets: [
            {
                label: 'Autarkie',
                data: [amountInternal, amountNet],
                backgroundColor: ['#fbbf24', '#047857'],
                hoverOffset: 4,
            },
        ],
    };

    return {
        amountNet,
        amountSum,
        amountInternal,
        internalPercent,
        donutData,
    };
};
