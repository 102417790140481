import React from 'react';
import { SelectedTenantIdAtom } from '../../state/states';
import { MobileScreen } from '../components/MobileScreen';
import { Modals } from './Modals';
import { useAtom } from '@gothub-team/got-atom';
import { useGraph } from '../../misc/got.config';
import { EDITOR, MAIN } from '../../misc/consts';
import { SelectModals } from './SelectModals';
import { Menu } from './Menu';
import { Footer } from '../sharedScreenComponents/Footer';
import { Screens } from '../sharedScreenComponents/Screens';

export default function TenantMainMobile() {
    const tenantId = useAtom(SelectedTenantIdAtom);
    const { useNode } = useGraph(MAIN, EDITOR);
    const meter = useNode(tenantId);
    const { nameT } = meter || {};
    return (
        <>
            <MobileScreen title={nameT || ''} footer={<Footer />} menu={<Menu />}>
                <Modals />
                <Screens tenantView />
            </MobileScreen>
            <SelectModals />
        </>
    );
}
