import React from 'react';
import { useAtom } from '@gothub-team/got-atom';
import { SelectedTenantIdAtom, meterSelectionPopup, MeterArrayAtom } from '../../state/states';
// import { LogoutButton } from '../../components/Logout';

export default function MeterSelection() {
    const tenArray = useAtom(MeterArrayAtom);
    return (
        <div className="absolute left-0 top-0 h-full w-full  bg-black/60 backdrop-blur-sm">
            <div className="fixed left-1/2 top-1/2 flex max-h-[75vh] max-w-[75vw] -translate-x-1/2 -translate-y-1/2 flex-col rounded-2xl bg-white p-5">
                <div className="mb-10 text-2xl font-medium text-emerald-700">Zähler wählen</div>
                {/* <LogoutButton className="fixed right-5 top-5 h-6 w-6" /> */}
                <div className="flex-1 overflow-y-scroll">
                    {tenArray.map((tenant, index) => (
                        <div
                            key={tenant.nodeId}
                            onClick={() => {
                                SelectedTenantIdAtom.set(tenant.nodeId);
                                meterSelectionPopup.set(false);
                            }}
                            className="relative mx-auto mb-2 w-full cursor-pointer rounded-lg px-2 text-center text-2xl text-slate-600 transition duration-200 hover:bg-yellow-200"
                        >
                            {tenant.node.nameT}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
