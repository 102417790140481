import React from 'react';
import { v4 } from 'uuid';
import { useAtom } from '@gothub-team/got-atom';
import { searchInputAtom2, SelectedInvoiceIdAtom, landlordInvoicePopup } from '../state/states';
import Icon from './Icon';
import Searchbar from './SearchBar';

export const InvoiceButtons = ({ invNew, mobile }) => {
    const searchInput2 = useAtom(searchInputAtom2);
    const iconColor = mobile ? 'fill-slate-600/90' : 'fill-slate-600 hover:fill-yellow-400';
    return (
        <div className="flex gap-8">
            {!mobile && (
                <div className="flex items-center">
                    <Searchbar
                        placeholderText="In Rechnungen suchen"
                        input1={searchInput2}
                        input2={e => searchInputAtom2.set(e.target.value)}
                    />
                </div>
            )}
            {invNew && (
                <div
                    className="cursor-pointer text-black hover:scale-110 hover:text-yellow-400"
                    onClick={() => {
                        landlordInvoicePopup.set(true);
                        SelectedInvoiceIdAtom.set(v4());
                    }}
                    title="Rechnung an Mieter schreiben"
                >
                    <Icon name="plus" className={`h-6 w-6 cursor-pointer text-xl ${iconColor} `} />
                </div>
            )}
        </div>
    );
};
