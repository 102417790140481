import { atom, useAtom } from '@gothub-team/got-atom';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

const debugModeAtom = atom(false);

export const useDebugMode = () => useAtom(debugModeAtom);

export const useToggleDebugMode = () => {
    useEffect(() => {
        const onKeyUp = e => {
            if (e.key === 'Q' && e.ctrlKey) {
                debugModeAtom.set(!debugModeAtom.get());
                toast.success(`Debug mode: ${debugModeAtom.get() ? 'on' : 'off'}`);
            }
        };

        window.addEventListener('keyup', onKeyUp);

        return () => {
            window.removeEventListener('keyup', onKeyUp);
        };
    });
};
