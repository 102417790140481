import React from 'react';
import { tenantPesonalInfoPopup } from '../../../state/states';
import 'react-toastify/dist/ReactToastify.css';
import { Modal } from '../../../components/Modal';
import { useInfoTenant } from '../../../hooks/useInfoTenant';
import { useAtom } from '@gothub-team/got-atom';
import { TariffData, TenantContact, TenantInfo } from './shared';
import { FEATURE_MODE, useFeatureMode } from '../../../hooks/useFeatureMode';

export const TenantInfoModal = () => {
    const isOpen = useAtom(tenantPesonalInfoPopup);

    if (!isOpen) return null;

    return (
        <Modal onClose={() => tenantPesonalInfoPopup.set(false)} title="Persönliche Daten">
            <Content />
        </Modal>
    );
};

const Content = () => {
    const { tenant, tariffRes } = useInfoTenant();
    const { nameT } = tenant || {};

    const isPremium = useFeatureMode() === FEATURE_MODE.FULL;

    return (
        <Modal onClose={() => tenantPesonalInfoPopup.set(false)} title={nameT} scroll>
            <div className="mx-auto flex flex-row justify-start gap-5">
                <div className="flex flex-1 flex-col gap-5">
                    <TenantInfo tenant={tenant} />
                    <TenantContact tenant={tenant} />
                </div>
                {isPremium && (
                    <div className="flex-1">
                        <TariffData tariffRes={tariffRes} />
                    </div>
                )}
            </div>
        </Modal>
    );
};
