import React from 'react';
import { useAdminMode } from '../hooks/useAdminMode';
import { Tile } from '../components/Tile';
import { ZevList } from './ZevList';
import { useZEVs } from '../hooks/useZEVs';

export default function AdminMain() {
    useAdminMode();
    useZEVs();
    return (
        <div className="fade-in grid-cols-[repeat(18, minmax(0, 1fr))] grid h-screen w-screen grid-rows-2 gap-5 bg-neutral-300/50 p-5">
            <Tile
                className="col-start-1 col-end-6 row-start-1 row-end-3 px-3"
                title="ZEV Übersicht"
                content={<ZevList />}
            />
            <Tile
                className="col-start-6 col-end-[19] row-start-1 row-end-2 px-3"
                title="Berechtigte User"
            />
            <Tile className="col-start-6 col-end-[19] row-start-2 row-end-3 px-3" title="Zähler" />
        </div>
    );
}
