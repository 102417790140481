import React from 'react';
import { gotApi, gotStore } from './misc/got.config';
import Icon from './components/Icon';

const Button = ({ onClick, text, icon }) => (
    <button
        className="font-semi mx-auto flex cursor-pointer flex-row items-center gap-4 border-none bg-transparent px-4 py-2 text-center text-xl text-slate-600 hover:text-yellow-400 focus:outline-none"
        onClick={onClick}
    >
        {icon} {text}
    </button>
);

const ErrorInfo = ({ error }) => {
    const [show, setShow] = React.useState(false);

    return (
        <div className="flex flex-col items-center gap-4">
            <pre className="max-w-[90vw] overflow-x-scroll">
                {error?.message || 'Unbekannter Fehler'}
            </pre>
            <Button
                onClick={() => setShow(!show)}
                text={show ? 'Details verbergen' : 'Details anzeigen'}
                icon={<Icon className="h-4 w-4" name="info-circle" />}
            />
            {show && (
                <div className="max-w-[90vw] overflow-x-scroll">
                    <pre>{error.stack}</pre>
                </div>
            )}
        </div>
    );
};

const LogoutButton = () => {
    const [result, setResult] = React.useState(false);

    if (result) {
        return <div>{result}</div>;
    }

    return (
        <Button
            onClick={() => {
                try {
                    gotApi.logout();
                    gotStore.clearAll();
                    setResult('Du bist ausgelogged');
                } catch (err) {
                    setResult(`Ausloggen fehlgeschlagen: ${err.message}`);
                }
            }}
            text="Abmelden"
            icon={<Icon className="h-4 w-4" name="logout" />}
        />
    );
};

// const ContactUsButton = () => {
//     const openContactModal = useOpenModal(CONTACT_MODAL);
//     return (
//         <Button
//             onClick={() => openContactModal()}
//             text="Kontakt aufnehmen"
//             icon={<IoMailOutline size="0.9rem" />}
//         />
//     );
// };

const ClearData = () => {
    const [result, setResult] = React.useState(false);

    if (result) {
        return <div>{result}</div>;
    }

    return (
        <Button
            onClick={() => {
                try {
                    gotStore.clearAll();
                    setResult('Cache geleert');
                } catch (err) {
                    setResult(`Cache leeren fehlgeschlagen: ${err.message}`);
                }
            }}
            text="Cache leeren"
            icon={<Icon className="h-4 w-4" name="trash-can" />}
        />
    );
};

export const Error = ({ error }) => (
    <div className="relative flex h-screen w-screen flex-col items-center justify-center gap-4 overflow-y-scroll">
        <Icon name="logo" className="h-[200px] w-[200px]" />
        <h1 className="text-center text-5xl">Etwas ist schief gelaufen.</h1>
        <ErrorInfo error={error} />
        <h2 className="text-center">
            Wenn dieser Fehler mehrfach auftritt, probieren Sie bitte folgende Schritte aus:
        </h2>
        <div className="flex flex-col gap-4">
            <ClearData />
            <Button
                onClick={() => window.location.reload()}
                text="Seite neu laden"
                icon={<Icon className="h-4 w-4" name="refresh" />}
            />
            <LogoutButton />
        </div>
        <h2 className="text-center">
            Wenn nichts davon funktioniert, kontaktieren Sie bitte den Support unter{' '}
            <span
                style={{
                    textDecoration: 'underline',
                }}
            >
                <a className="decoration-transparent" href="mailto:info@herbenergy.ch">
                    info@herbenergy.ch
                </a>
            </span>
        </h2>
        {/* <ContactUsButton /> */}
    </div>
);
