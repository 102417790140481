import { toast } from 'react-toastify';
import * as R from 'ramda';
import { EDITOR, MAIN, TARIF_CACLULATOR, TARIF_EDITOR } from '../misc/consts';
import { useGraph } from '../misc/got.config';
import { calcTariff } from '../misc/calcTariff';
import { selectedZEVID, tariffCalcPopupAtom } from '../state/states';
import { tariffEditPopupAtom } from '../state/states';
import { useAtom } from '@gothub-team/got-atom';

const notifySuccess = () => toast.success('Speichern erfolgreich');
const notifyError = () => toast.error('Speichern fehlgeschlagen');
const notifyMissingInputs = () => toast.error('Eingabe unvollständig');
const selectTariffInfo = R.propOr({}, 'tariffInfo');
const selectTariffRes = R.propOr({}, 'tariffRes');
const selectTariff = R.propOr({}, 'tariff');

export const useTariffCalculator = () => {
    const loggedInLLID = useAtom(selectedZEVID);
    const { useNode, update, push } = useGraph(MAIN, EDITOR, TARIF_CACLULATOR);

    const tariffInfo = useNode(loggedInLLID, selectTariffInfo);

    const updateTariffInfo = newTariffInfo => {
        update({ id: loggedInLLID, tariffInfo: newTariffInfo });
    };

    const tariffRes = calcTariff(tariffInfo);

    const onSave = async () => {
        if (!tariffRes || R.isEmpty(tariffRes)) {
            notifyMissingInputs();
            return;
        }

        update({ id: loggedInLLID, tariffRes });

        try {
            await push();
            notifySuccess();
        } catch (err) {
            console.log(err);
            notifyError();
        } finally {
            tariffCalcPopupAtom.set(false);
        }
    };

    return { onSave, tariffInfo, updateTariffInfo, tariffRes };
};

export const useTariffEdit = () => {
    const loggedInLLID = useAtom(selectedZEVID);
    const { useNode, update, push } = useGraph(MAIN, EDITOR, TARIF_EDITOR);

    const tariffRes = useNode(loggedInLLID, selectTariffRes);
    const tariff = useNode(loggedInLLID, selectTariff);

    const updateTariff = newTariff => {
        update({ id: loggedInLLID, tariff: newTariff });
    };

    const onSave = async () => {
        if (!tariff || !tariff.internal || !tariff.external) {
            notifyMissingInputs();
            return;
        }

        try {
            await push();
            notifySuccess();
        } catch (err) {
            console.log(err);
            notifyError();
        } finally {
            tariffEditPopupAtom.set(false);
        }
    };

    return { onSave, tariffRes, tariff, updateTariff };
};
