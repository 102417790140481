import { useEffect, useState } from 'react';
import { herbenergyApi } from '../misc/herbenergy.api';
import { useMeterStartValues } from './useMeterStartValue';

const toApiDate = date => {
    const str = `${new Date(date).toDateString()} GMT+0000`;
    return new Date(str).toISOString();
};

const parse = value => {
    if (typeof value === 'string') {
        return parseFloat(value);
    }
    return value;
};

export const useInvoiceData = (zevId, meterId) => {
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);

    const [invoiceData, setInvoiceData] = useState({});
    const [invoiceDataLoading, setInvoiceDataLoading] = useState('init');
    const [invoiceError, setInvoiceError] = useState(null);

    useEffect(() => {
        const loadInvoiceData = async () => {
            setInvoiceError(null);
            if (!selectedStartDate || !selectedEndDate) return;

            try {
                setInvoiceDataLoading('pending');
                const newInvoiceData = await herbenergyApi.getInvoiceData(
                    zevId,
                    meterId,
                    toApiDate(selectedStartDate),
                    toApiDate(selectedEndDate),
                );
                setInvoiceData(newInvoiceData);
                setInvoiceDataLoading('loaded');
            } catch (err) {
                setInvoiceData({});
                setInvoiceDataLoading('error');
                if (err?.response?.status === 404) {
                    const min = new Date(err?.response?.data?.min).toLocaleDateString('de-DE');
                    const max = new Date(err?.response?.data?.max).toLocaleDateString('de-DE');
                    setInvoiceError(`Bitte wählen Sie ein Datum im Zeitraum von ${min} bis ${max}`);
                } else if (
                    err?.response?.status === 400 &&
                    err?.response?.message === 'Zev has not been in operation for long enough'
                ) {
                    setInvoiceError(
                        'Der ZEV wurde noch nicht lange genug betrieben, um eine Rechnung zu erstellen.',
                    );
                } else {
                    setInvoiceError('Etwas ist schief gelaufen. Bitte versuche es erneut.');
                }
            }
        };

        loadInvoiceData();
    }, [zevId, meterId, selectedStartDate, selectedEndDate]);

    const startValues = useMeterStartValues();
    const totalStart = Math.max(invoiceData?.start?.import || 0, startValues.import || 0);
    const totalEnd = Math.max(invoiceData?.end?.import || 0, startValues.import || 0);
    const totalValue = totalEnd - totalStart;

    const internalStart = invoiceData?.start?.internal || 0;
    const internalEnd = invoiceData?.end?.internal || 0;
    const internalValue = internalEnd - internalStart;
    const externalValue = totalValue - internalValue;

    return {
        selectedStartDate,
        selectedEndDate,
        setSelectedStartDate,
        setSelectedEndDate,
        invoiceData,
        invoiceError,
        invoiceDataLoading,
        totalStart: parse(totalStart),
        totalEnd: parse(totalEnd),
        totalValue: parse(totalValue),
        internalStart: parse(internalStart),
        internalEnd: parse(internalEnd),
        internalValue: parse(internalValue),
        externalValue: parse(externalValue),
    };
};
