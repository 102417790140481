import React from 'react';
import { MobileModal } from '../../../Mobile/components/MobileModal';
import { landlordInvoicePopup } from '../../../state/states';
import { useAtom } from '@gothub-team/got-atom';
import DatePicker from 'react-datepicker';
import { useCreateInvoice } from '../../../hooks/useCreateInvoice';
import { Chaotic3dLogoSpinner } from '../../../Mobile/components/Chaotic3dLogoSpinner';
import { PDFButton } from '../../../Mobile/components/Buttons';
import { Position } from './shared';
import { Input } from '../../../components/Input';

export const CreateInvoiceModalMobile = () => {
    const landlordInvoice = useAtom(landlordInvoicePopup);
    return (
        <MobileModal
            onClose={() => landlordInvoicePopup.set(false)}
            title="Rechnung erstellen"
            isOpen={landlordInvoice}
        >
            {landlordInvoice && <Content />}
        </MobileModal>
    );
};

const Content = () => {
    const {
        onSave,
        handleStartDateChange,
        handleEndDateChange,
        selectedStartDate,
        selectedEndDate,
        setInvoiceNumberIF,
        invoiceDataLoading,
        externalPosition,
        internalPosition,
        invoiceError,
        invoiceNumberIF,
        isLoading,
    } = useCreateInvoice();
    return (
        <div className="relative flex w-full flex-col gap-5">
            {isLoading && (
                <div className="absolute left-0 top-0 h-full w-full">
                    <Chaotic3dLogoSpinner />
                </div>
            )}
            <div
                className="flex h-full w-full flex-col gap-5 p-3"
                style={{ opacity: isLoading && 0.4 }}
            >
                <div className="flex w-full items-center">
                    <div className="w-full">
                        <Input
                            label={'Rechnungsnummer'}
                            type={'text'}
                            value={invoiceNumberIF}
                            onChange={value => setInvoiceNumberIF(value)}
                        />
                    </div>
                </div>
                <div className="flex w-full items-center justify-between gap-5">
                    <div className="mobile-dates flex flex-col">
                        <div
                            className={`mb-1 block text-xs font-medium transition duration-200 ${
                                selectedStartDate ? 'text-neutral-400' : 'text-transparent'
                            }`}
                        >
                            Startdatum
                        </div>
                        <DatePicker
                            selected={selectedStartDate}
                            onChange={handleStartDateChange}
                            dateFormat="dd.MM.yyyy"
                            maxDate={new Date(new Date().getTime() - 1000 * 60 * 60 * 24)}
                            placeholderText="Startdatum"
                            dropdownMode="select"
                            className="h-10 w-full rounded-md border-none shadow-[0_0_0_1px_#e5e7eb] outline-none placeholder:text-center focus:shadow-[0_0_0_2px_rgb(234,179,8)]"
                        />
                    </div>
                    <div className="mobile-dates flex flex-col">
                        <div
                            className={`mb-1 block text-xs font-medium transition duration-200 ${
                                selectedEndDate ? 'text-neutral-400' : 'text-transparent'
                            }`}
                        >
                            Enddatum
                        </div>
                        <DatePicker
                            selected={selectedEndDate}
                            onChange={handleEndDateChange}
                            dateFormat="dd.MM.yyyy"
                            maxDate={new Date(new Date().getTime() - 1000 * 60 * 60 * 24)}
                            placeholderText="Enddatum"
                            dropdownMode="select"
                            className="h-10 w-full rounded-md border-none shadow-[0_0_0_1px_#e5e7eb] outline-none placeholder:text-center focus:shadow-[0_0_0_2px_rgb(234,179,8)]"
                        />
                    </div>
                </div>
                {invoiceDataLoading === 'pending' && (
                    <div className="w-full text-center">Lade Daten...</div>
                )}
                {invoiceDataLoading === 'loaded' && (
                    <div className="flex flex-col">
                        <Position position={externalPosition}></Position>
                        <Position position={internalPosition}></Position>
                    </div>
                )}
                {isLoading && (
                    <div className="absolute inset-0 z-10 flex items-center justify-center bg-white">
                        <div className="">Erstelle Rechnung...</div>
                    </div>
                )}
                {invoiceError && (
                    <div className="rounded-md border border-solid border-red-600 px-4 py-2 text-red-600">
                        {invoiceError}
                    </div>
                )}
            </div>
            {!invoiceError && <PDFButton onClick={onSave} />}
        </div>
    );
};
