import { useEffect } from 'react';
import { APPLICATION_ROOT_NODE, EDITOR, MAIN, ROOT_ZEV_EDGE } from '../misc/consts';
import { createGraph } from '../misc/got.config';
import { ZevArrayAtom } from '../state/states';
import * as R from 'ramda';

const zevsView = {
    [APPLICATION_ROOT_NODE]: {
        as: 'root',
        include: {
            node: true,
        },
        edges: {
            [ROOT_ZEV_EDGE]: {
                as: 'zevs',
                include: {
                    edges: true,
                    node: true,
                    rights: true,
                },
            },
        },
    },
};

export const useZEVs = async () => {
    const { pull, useView } = createGraph(MAIN, EDITOR);
    useEffect(() => {
        pull(zevsView);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const zevs = useView(zevsView);
    const zevArray = R.compose(
        R.sortBy(R.path(['node', 'name'])),
        R.values,
        R.pathOr({}, ['root', 'zevs']),
    )(zevs);
    ZevArrayAtom.set(zevArray);
};
