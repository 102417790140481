import Icon from '../../../components/Icon';
import * as R from 'ramda';
import { floor } from '../../../misc/util';
import { useState } from 'react';

export const RappenPropInputField = ({
    label = '',
    type = 'text',
    defaultVal = '',
    propName,
    obj,
    setObj,
    suggestion,
    onBlur,
    onFocus,
}) => {
    const value = floor(2, R.propOr(defaultVal, propName, obj) * 100);
    const [isFocused, setIsFocused] = useState(false);
    const handleOnBlur = e => {
        onBlur && onBlur(e);
        setIsFocused(false);
    };
    const handleOnFocus = e => {
        onFocus && onFocus(e);
        setIsFocused(true);
    };
    return (
        <div className="mb-5">
            <div className="flex flex-row items-center gap-2">
                <div className="relative mb-8 inline-block w-full">
                    <input
                        type={type}
                        className="box-border w-full border-none py-0 pl-[0.1rem] pr-3 text-base text-black outline-none transition-all duration-200 ease-out placeholder:transition-all placeholder:duration-200 placeholder:ease-out"
                        placeholder={label}
                        value={value}
                        onChange={e => {
                            const value = e.target.value * 0.01;
                            setObj(R.assoc(propName, value, obj));
                        }}
                        onBlur={handleOnBlur}
                        onFocus={handleOnFocus}
                    />
                    <div
                        className={`absolute -bottom-1 left-0 h-[2px] w-full overflow-hidden rounded-[2px] bg-slate-600 after:absolute after:left-0 after:block after:h-full after:w-full after:bg-emerald-600 after:transition-all after:duration-200 after:ease-out after:content-[""]
                            ${isFocused ? 'after:scale-100' : 'after:scale-0'}
                        `}
                    />
                    <div
                        className={`animate-fade-scale-in absolute -bottom-6 left-0 rounded py-[0.1rem] pl-1 pr-4 text-[0.6rem] font-medium text-white transition-all duration-200 ease-out
                                ${isFocused ? 'bg-emerald-600' : 'bg-slate-600'}
                            `}
                        style={{
                            clipPath:
                                'polygon(0% 0%, 100% 0, 100% 0%, calc(100% - 12px) 100%, 0% 100%)',
                            transformOrigin: 'left',
                        }}
                    >
                        {label}
                    </div>
                </div>
                {suggestion && (
                    <>
                        <button
                            className="flex cursor-pointer items-center justify-center border-none bg-transparent"
                            onClick={() => {
                                setObj(R.assoc(propName, suggestion, obj));
                            }}
                        >
                            <Icon name="copy-arrow" className="h-6 w-6"></Icon>
                        </button>
                        <div className="whitespace-nowrap">{`${floor(2, suggestion * 100).toFixed(
                            2,
                        )} Rp/kWh`}</div>
                    </>
                )}
            </div>
        </div>
    );
};

export const PropInputField = ({
    label = '',
    type = 'text',
    defaultVal = '',
    propName,
    obj,
    setObj,
    suggestion,
}) => {
    const value = R.propOr(defaultVal, propName, obj);
    return (
        <div className="mb-5">
            <label className="mb-1 block text-xs font-medium text-neutral-400">{label}</label>
            <div className="flex flex-row items-center gap-2">
                <input
                    placeholder={label}
                    value={value}
                    onChange={e => setObj(R.assoc(propName, e.target.value, obj))}
                    type={type}
                    name={label}
                    id={label}
                    className="h-10 w-full rounded-lg border-none px-2 py-1 shadow-[0_0_0_1px_#e5e7eb] outline-none placeholder:text-center focus:shadow-[0_0_0_2px_rgb(234,179,8)]"
                />
                {suggestion && (
                    <>
                        <button
                            className="flex cursor-pointer items-center justify-center border-none bg-transparent"
                            onClick={() => {
                                setObj(R.assoc(propName, suggestion, obj));
                            }}
                        >
                            <Icon name="copy-arrow" className="h-6 w-6"></Icon>
                        </button>
                        <div className="whitespace-nowrap">{`${suggestion.toFixed(
                            4,
                        )} CHF/kWh`}</div>
                    </>
                )}
            </div>
        </div>
    );
};
