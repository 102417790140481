import { gotReducer } from '@gothub-team/got-react';
import { combineReducers, createStore } from 'redux'; //applyMiddleware
// import logger from 'redux-logger';
import { toastsReducer } from '../toast/toast-reducer';

const rootReducer = combineReducers({
    got: gotReducer,
    toasts: toastsReducer,
});

export const reduxStore = createStore(
    rootReducer,
    // applyMiddleware(logger)
);
