import React, { useState } from 'react';
import TenantList from './TenantList';
import Searchbar from '../components/SearchBar';
import {
    searchInputAtom,
    landlordEditPopup,
    SelectedTenantIdAtom,
    tariffEditPopupAtom,
    selectedZEVID,
    tenantRightsPopup,
    tenantEditPopup,
    landlordInvoicePopup,
    tariffCalcPopupAtom,
} from '../state/states';
import { useAtom } from '@gothub-team/got-atom';
import TenantBar from './TenantBar';
import ReactWeather from 'react-open-weather';
import { useOpenWeatherWithBackup } from '../hooks/useOpenWeatherFreemium';

import dayjs from 'dayjs';
import 'dayjs/locale/de';
import NavMenu from './NavMenu';
import { useGraph } from '../misc/got.config';
import { EDITOR, MAIN } from '../misc/consts';
import { InvoicesDisabled, LandlordInvoiceList } from './InvoiceList';
import { Tile } from '../components/Tile';
import { LogoutButton } from '../components/Logout';
import { InvoiceButtons } from '../components/InvoiceButtons';
import { EditLandlordModal } from './Modals/EditLandlordModal';
import { TenantRightsModal } from './Modals/TenantRightsModal';
import { EditTenantModal } from './Modals/EditTenantModal';
import { CreateInvoiceModal } from './Modals/CreateInvoice/CreateInvoiceModal';
import { ZevSelectionModal } from '../modals/ZevSelectionModal';
import { TariffCalculatorModal } from './Modals/TariffCalculator/Modal';
import { Chaotic3dLogoSpinner } from '../Mobile/components/Chaotic3dLogoSpinner';
import { EditTariffModal } from './Modals/EditTariff/EditTariffModal';
import { StatisticsTile } from '../tenant/TenantStatisticsSimple';
import { FEATURE_MODE, useFeatureMode } from '../hooks/useFeatureMode';
import { InvoiceDataModal } from './Modals/InvoiceDataModal';
dayjs.locale('de');

export default function LandLordMain() {
    const searchInput1 = useAtom(searchInputAtom);
    const lanlordEditPopup = useAtom(landlordEditPopup);
    const tariffEditPopup = useAtom(tariffEditPopupAtom);
    const tariffCalcPopup = useAtom(tariffCalcPopupAtom);
    const selectedTenantId = useAtom(SelectedTenantIdAtom);
    const selectedZevId = useAtom(selectedZEVID);
    const tenRightsPopup = useAtom(tenantRightsPopup);
    const tenEditPopup = useAtom(tenantEditPopup);
    const tenInvoicePopup = useAtom(landlordInvoicePopup);

    const { useNode } = useGraph(MAIN, EDITOR);
    const tenant = useNode(selectedTenantId);
    const landLord = useNode(selectedZevId);

    const { cityT } = tenant || {};
    const { city } = landLord || {};

    const cityWeather = selectedTenantId === selectedZevId ? city : cityT;
    const { data, isLoading, errorMessage } = useOpenWeatherWithBackup(cityWeather);

    const isPremium = useFeatureMode() === FEATURE_MODE.FULL;

    return (
        <>
            <div
                className="fade-in grid-cols-[repeat(18, minmax(0, 1fr))] grid h-screen w-screen grid-rows-2 gap-5 bg-neutral-300/50 p-5"
                style={{ gridTemplateColumns: 'repeat(18, minmax(0, 1fr))' }}
            >
                <Tile
                    className="col-start-1 col-end-7 row-start-1 row-end-2 px-3"
                    title="Mieterübersicht"
                    topbar={
                        <Searchbar
                            placeholderText="Mieter suchen"
                            input1={searchInput1}
                            input2={e => searchInputAtom.set(e.target.value)}
                        />
                    }
                    content={<TenantList />}
                />
                <Tile
                    className="col-start-7 col-end-13 row-start-1 row-end-2 px-3"
                    title={
                        selectedTenantId !== selectedZevId
                            ? 'Zähler-Verbrauchsübersicht'
                            : 'ZEV-Verbrauchsübersicht'
                    }
                    content={selectedTenantId && <TenantBar />}
                />
                <StatisticsTile />
                <Tile
                    className="col-start-[17] col-end-[19] row-start-1 row-end-2 px-3"
                    title="Menü"
                    topbar={<LogoutButton />}
                    content={selectedTenantId && <NavMenu />}
                    noScrollbar
                />
                <WeatherTile
                    isLoading={isLoading}
                    errorMessage={errorMessage}
                    data={data}
                    cityWeather={cityWeather}
                />
                <Tile
                    className="col-start-7 col-end-[19] row-start-2 row-end-3 px-3"
                    title="Rechnungsübersicht"
                    topbar={
                        selectedTenantId !== selectedZevId && isPremium && <InvoiceButtons invNew />
                    }
                    content={
                        isPremium ? (
                            <LandlordInvoiceList />
                        ) : (
                            <InvoicesDisabled canSeeButton={selectedTenantId !== selectedZevId} />
                        )
                    }
                />
            </div>
            {lanlordEditPopup && <EditLandlordModal />}
            {tariffEditPopup && <EditTariffModal />}
            {tariffCalcPopup && <TariffCalculatorModal />}
            {tenRightsPopup && <TenantRightsModal />}
            {tenEditPopup && <EditTenantModal />}
            {tenInvoicePopup && <CreateInvoiceModal />}
            <InvoiceDataModal />
            <ZevSelectionModal />
        </>
    );
}

const WeatherTile = ({ isLoading, errorMessage, data, cityWeather }) => {
    const [showScrollbar, setShowScrollbar] = useState(false);
    return (
        <Tile
            className="col-start-1 col-end-7 row-start-2 row-end-3"
            content={
                isLoading ? (
                    <div className=" flex h-full items-center justify-center">
                        <Chaotic3dLogoSpinner />
                    </div>
                ) : (
                    <ReactWeather
                        isLoading={isLoading}
                        errorMessage={errorMessage}
                        data={data}
                        lang="de"
                        locationLabel={cityWeather}
                        unitsLabels={{ temperature: 'C', windSpeed: 'Km/h' }}
                        showForecast
                    />
                )
            }
            onMouseEnter={() => setShowScrollbar(true)}
            onMouseLeave={() => setShowScrollbar(false)}
            noScrollbar={!showScrollbar}
        />
    );
};
