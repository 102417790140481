import React from 'react';
import { useAtom } from '@gothub-team/got-atom';
import { SelectedTenantIdAtom, dashboardMobileAtom } from '../../state/states';
import Icon from '../../components/Icon';
import { ClickAnimator } from '../components/ClickAnimator';
import { FEATURE_MODE, useFeatureMode } from '../../hooks/useFeatureMode';

const screens = [
    { name: 'Zurück', icon: 'back', nav: 'tenant' },
    { name: 'Verbrauch', icon: 'chart', nav: 'consumption' },
    { name: 'Kennwerte', icon: 'plug', nav: 'metrics' },
    { name: 'Rechnungen', icon: 'file', nav: 'invoices' },
    { name: 'Wetter', icon: 'weather', nav: 'weather' },
];

const premiumScreens = ['invoices'];

export const Footer = () => {
    const selectedScreen = useAtom(dashboardMobileAtom);

    const isPremium = useFeatureMode() === FEATURE_MODE.FULL;
    const filteredScreens = screens.filter(screen => {
        if (isPremium) {
            return true;
        }
        return !premiumScreens.includes(screen.nav);
    });

    return (
        <div className="fade-in flex w-full items-center justify-between bg-white p-3 px-0">
            {filteredScreens.map(screen => (
                <ClickAnimator key={screen.name}>
                    <FooterElement
                        key={screen.name}
                        name={screen.name}
                        icon={screen.icon}
                        selected={selectedScreen === screen.nav}
                        onClick={() => {
                            if (screen.nav === 'tenant') {
                                SelectedTenantIdAtom.set(undefined);
                            } else {
                                dashboardMobileAtom.set(screen.nav);
                            }
                        }}
                    />
                </ClickAnimator>
            ))}
        </div>
    );
};

const FooterElement = ({ name, icon, selected, onClick }) => {
    return (
        <div className="flex flex-col items-center justify-center" onClick={onClick}>
            <Icon
                name={icon}
                className={`mb-1 h-4 w-4 transition duration-200 ${
                    selected ? 'fill-emerald-700' : 'fill-slate-700'
                }`}
            />
            <div className={`text-xs ${selected ? 'text-emerald-700' : 'text-slate-700'}`}>
                {name}
            </div>
        </div>
    );
};
