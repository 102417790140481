import * as R from 'ramda';
import { floor } from '../../../misc/util';

const Field = ({ label = '', unit = '', value }) => {
    return (
        <div className="">
            <div className="flex flex-row justify-between gap-4">
                <div className="whitespace-nowrap">{label}</div>
                <div className="whitespace-nowrap text-right">{`${value} ${unit}`}</div>
            </div>
        </div>
    );
};

const ListFied = ({ label = '', unit = '', values = [] }) => {
    return (
        <div className="flex flex-col gap-2">
            <div className="flex flex-row justify-between">
                <div className="font-bold">{label}</div>
            </div>
            {values.map(value => (
                <div className="flex flex-row justify-between gap-4">
                    <div className="whitespace-nowrap">{value.name ?? ''}</div>
                    <div className="whitespace-nowrap text-right">{`${value.value} ${unit}`}</div>
                </div>
            ))}
            {values.length === 0 && (
                <div className="flex flex-row justify-between gap-4">
                    <div className="whitespace-nowrap">-</div>
                </div>
            )}
        </div>
    );
};

export const FieldsLeft = ({ tariffInfo, tariffRes }) => (
    <>
        <h2 className="text-lg font-bold">Anlagendaten Allgemein</h2>
        <Field
            label={'DC Anlagenleistung'}
            value={R.propOr('NaN', 'installedPowerDc', tariffInfo)}
            unit={'kW'}
        />
        <Field
            label={'AC Anlagenleistung'}
            value={R.propOr('NaN', 'installedPowerAc', tariffInfo)}
            unit={'kW'}
        />
        <ListFied
            label={'Anlagekosten'}
            values={R.propOr([], 'fixedInvestment', tariffInfo)}
            unit={'CHF'}
        ></ListFied>
        <ListFied
            label={'Subventionen'}
            values={R.propOr([], 'subsidy', tariffInfo)}
            unit={'CHF'}
        ></ListFied>
        <Field
            label={'Alter der Anlage'}
            value={R.propOr('NaN', 'age', tariffInfo)}
            unit={'Jahre'}
        />

        <h2 className="text-lg font-bold">Verbrauch</h2>
        <Field
            label={'Gesamtstromverbrauch'}
            defaultVal={tariffInfo.expectedTotalUsage}
            value={R.propOr('NaN', 'expectedTotalUsage', tariffInfo)}
            unit={'kWh/Jahr'}
        />
        <Field
            label={'Zu erwartende Stromproduktion'}
            value={R.propOr('NaN', 'expectedPower', tariffInfo)}
            unit={'kWh/Jahr'}
        />
        <Field
            label={'Degradation'}
            value={R.propOr('NaN', 'degradation', tariffInfo)}
            unit={'%/Jahr'}
        />
        <Field
            label={'Eigenverbrauchsquote'}
            value={R.propOr('NaN', 'usageEfficiency', tariffInfo)}
            unit={'%'}
        />
        <Field
            label={'Erwarteter Eigenverbrauch'}
            value={tariffRes?.expectedInteralUsage?.toFixed(2) || 'NaN'}
            unit={'kWh'}
        />
        <Field
            label={'Netzeinspeisung'}
            value={tariffRes?.expectedSold?.toFixed(2) || 'NaN'}
            unit={'kWh'}
        />
        <Field
            label={'Netzbezug'}
            value={tariffRes?.expectedBuy?.toFixed(2) || 'NaN'}
            unit={'kWh'}
        />
        <h2 className="text-lg font-bold">Betriebskosten</h2>
        <ListFied
            label={'Betriebskosten'}
            value={R.propOr([], 'runningCosts', tariffInfo)}
            unit={'CHF'}
        ></ListFied>
        <ListFied
            label={'Betriebskosten pro kWh'}
            propName={'runningCostskWh'}
            values={R.propOr([], 'runningCostskWh', tariffInfo)}
            unit={'CHF/kWh'}
        ></ListFied>
    </>
);

export const FieldsRight = ({ tariffInfo, tariffRes }) => {
    const externalTariff = tariffRes?.externalTariff
        ? floor(2, tariffRes.externalTariff * 100).toFixed(2)
        : 'NaN';
    const internalTariffCosts = tariffRes?.internalTariffCosts
        ? floor(2, tariffRes.internalTariffCosts * 100).toFixed(2)
        : 'NaN';
    const internalTariff = tariffRes?.internalTariff
        ? floor(2, tariffRes.internalTariff * 100).toFixed(2)
        : 'NaN';

    return (
        <>
            <h2 className="text-lg font-bold">Zinsen</h2>
            <Field
                label={'Armortisationszeitraum'}
                value={R.propOr('NaN', 'maxInterestYears', tariffInfo)}
                unit={'Jahre'}
            />
            <Field
                label={'Referenzzinssatz'}
                value={R.propOr('NaN', 'currentReferenceInterestRate', tariffInfo)}
                unit={'%'}
            />
            <Field
                label={'Risikozuschlag'}
                value={R.propOr('NaN', 'riskFreeInterestRate', tariffInfo)}
                unit={'%'}
            />
            <Field
                label={'Zinssatz'}
                value={((tariffRes?.actualInterestRate || 0) * 100).toFixed(2)}
                unit={'%'}
            />
            <Field
                label={'Kapitalkostenrendite'}
                value={((tariffRes?.maximumInterestRate || 0) * 100).toFixed(2)}
                unit={'%'}
            />
            <h2 className="text-lg font-bold">Strompreise</h2>
            <Field
                label={'Grundpreis'}
                value={R.propOr('NaN', 'baseCost', tariffInfo)}
                unit={'CHF'}
            />
            <Field
                label={'Energiepreis'}
                value={R.propOr('NaN', 'buyTariff', tariffInfo)}
                unit={'CHF/kWh'}
            />
            <Field
                label={'Arbeitspreis'}
                value={R.propOr('NaN', 'workingTariff', tariffInfo)}
                unit={'CHF/kWh'}
            />
            <ListFied
                label={'zusätzliche kosten pro kWh'}
                values={R.propOr([], 'additionalCostsPerKWh', tariffInfo)}
                unit={'CHF/kWh'}
            ></ListFied>
            <Field
                label={'Einspeisevergütung'}
                value={R.propOr('NaN', 'sellTariff', tariffInfo)}
                unit={'CHF/kWh'}
            />

            <h2 className="text-lg font-bold">Tarife</h2>
            <Field label={'Standard Strompreis'} value={externalTariff} unit={'Rp/kWh'} />
            <Field
                label={'Eigenstromgestehungskosten'}
                value={internalTariffCosts}
                unit={'Rp/kWh'}
            />
            <Field label={'Eigenstrompreis'} value={internalTariff} unit={'Rp/kWh'} />
        </>
    );
};
