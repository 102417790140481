import React, { useEffect, useRef } from 'react';
import * as R from 'ramda';
import { calcDeltas, calcWattage } from '../../hooks/useChartData';
import 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import { LineTooltip } from './Tooltip';
import { handleExternalTooltip } from './shared';
import { TenantExportPopup } from '../../state/states';
import { useDebugMode } from '../../state/debugMode';
import Icon from '../../components/Icon';
import { useCreateAtom } from '@gothub-team/got-atom';

const every = (num, arr) => {
    const newArr = [];
    for (let i = 0; i < arr.length; i += num) {
        newArr.push(arr[i]);
    }
    return newArr;
};

const dailyChartOptions = {
    indexAxis: 'x',
    elements: {
        point: {
            radius: 0,
        },
        line: {
            borderColor: 'rgb(71 85 105)',
        },
    },
    scales: {
        x: {
            stacked: true,
            grid: {
                drawBorder: false,
                color: 'rgba(0,0,0,0.1)',
            },
            ticks: {
                color: 'black',
                maxTicksLimit: 24,
            },
        },
        y: {
            // grid: {
            //     drawBorder: false,
            //     display: false,
            // },
            title: {
                display: true,
                text: 'Watt',
            },
        },
    },
    plugins: {
        legend: {
            display: false,
        },
    },
    interaction: {
        mode: 'x',
        axis: 'x',
        intersect: false,
    },
};

const getTimeLabel = item => new Date(item.timestamp).toLocaleTimeString('de-DE').slice(0, 5);

export const createLineChart = ({ options: _options, interval = 1, getLabel }) => {
    const Component = ({ fullyLoaded, chartData, startValues }) => {
        const ref = useRef();

        const factor = (60 / interval) * 1000; // * 60min to kW, * 1000 to W

        const chartArr = every(interval, R.pathOr([], ['values'], chartData));
        const importStart = R.pathOr(startValues.import || 0, ['start', 'import'], chartData);
        const importData = calcDeltas(importStart, chartArr, 'import');
        const internalStart = R.pathOr(startValues.internal || 0, ['start', 'internal'], chartData);
        const internalData = calcDeltas(internalStart, chartArr, 'internal');
        const exportStart = R.pathOr(startValues.export || 0, ['start', 'export'], chartData);
        const exportData = calcDeltas(exportStart, chartArr, 'export');

        const labels = chartArr.map((item, index) => {
            if (index % 6 === 0) {
                return getLabel(item);
            }
            return '';
        });

        const internalArr = calcWattage(internalData, factor);
        const importArr = calcWattage(importData, factor);
        const exportArr = calcWattage(exportData, factor);

        const radii =
            interval > 5 ? { hitRadius: 5, hoverRadius: 3 } : { hitRadius: 1.5, hoverRadius: 1.5 };

        const data = {
            labels: labels,
            datasets: [
                {
                    label: 'Leistung Intern',
                    data: internalArr,
                    backgroundColor: ['rgb(250 204 21)'],
                    borderColor: 'rgb(250 204 21)',
                    fill: 'origin',
                    borderWidth: 2,
                    tension: 0.4,
                    ...radii,
                },
                {
                    label: 'Leistung Gesamt',
                    data: importArr,
                    backgroundColor: ['rgb(71 85 105)'],
                    borderColor: 'rgb(71 85 105)',
                    borderWidth: 2,
                    tension: 0.4,
                    hitRadius: 5,
                    hoverRadius: 3,
                    ...radii,
                },
                // {
                //     label: 'Einspeisung',
                //     data: exportArr,
                //     backgroundColor: ['rgb(71 85 105)'],
                //     borderColor: 'rgb(71 85 105)',
                //     // borderRadius: 5,
                //     borderWidth: 1,
                //     hitRadius: 5,
                //     hoverRadius: 3,
                //     ...radii,
                // },
            ],
        };

        const tooltipAtom = useCreateAtom(null);
        const options = R.assocPath(
            ['plugins', 'tooltip'],
            {
                enabled: false,
                external: handleExternalTooltip(tooltipAtom),
                interaction: 'x',
            },
            _options,
        );

        useEffect(() => {});

        const verticalLinePlugin = {
            id: 'verticalLine',
            afterDraw: chart => {
                if (chart.tooltip?._active?.length) {
                    let x = chart.tooltip._active[0].element.x;
                    let yAxis = chart.scales.y;
                    let ctx = chart.ctx;
                    ctx.save();
                    ctx.beginPath();
                    ctx.moveTo(x, yAxis.top);
                    ctx.lineTo(x, yAxis.bottom);
                    ctx.lineWidth = 3;
                    ctx.strokeStyle = 'rgba(0, 0, 0, 0.1)';
                    ctx.stroke();
                    ctx.restore();
                }
            },
        };

        return (
            <>
                <Line
                    ref={ref}
                    data={data}
                    options={options}
                    onMouseLeave={() => {
                        tooltipAtom.set(null);
                    }}
                    plugins={[verticalLinePlugin]}
                />
                <LineTooltip
                    tooltipAtom={tooltipAtom}
                    internalArr={internalArr}
                    importArr={importArr}
                    exportArr={exportArr}
                    chartArr={chartArr}
                />
                <ExportButton />
            </>
        );
    };

    return Component;
};

const ExportButton = () => {
    const debugMode = useDebugMode();

    if (!debugMode) return null;

    return (
        <button
            className="absolute right-4 top-4 rounded-full border-none bg-gray-100 px-2 py-1 hover:bg-gray-200"
            onClick={() => {
                TenantExportPopup.set(true);
            }}
        >
            <Icon name="download" />
        </button>
    );
};

export const DayLineChartPremium = createLineChart({
    options: dailyChartOptions,
    getLabel: getTimeLabel,
    interval: 15,
});

export const DayLineChartFree = createLineChart({
    options: dailyChartOptions,
    getLabel: getTimeLabel,
    interval: 60,
});
