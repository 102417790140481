import React from 'react';
import { MobileMenu } from '../components/MobileMenu';
import { mobileMenuAtom, tenantPesonalInfoPopup } from '../../state/states';
import { logout } from '../../misc/logout';

export const Menu = () => (
    <MobileMenu>
        <div
            onClick={() => {
                tenantPesonalInfoPopup.set(true);
                mobileMenuAtom.set(false);
            }}
            className="mb-6 w-full text-xl text-white"
        >
            Persönliche Daten
        </div>
        <div
            onClick={() => {
                mobileMenuAtom.set(false);
                logout();
            }}
            className="w-full text-xl text-white"
        >
            Abmelden
        </div>
    </MobileMenu>
);
