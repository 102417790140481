import React from 'react';
import { MobileMenu } from '../components/MobileMenu';
import {
    SelectedInvoiceIdAtom,
    SelectedTenantIdAtom,
    landlordEditPopup,
    landlordInvoicePopup,
    mobileMenuAtom,
    selectedZEVID,
    tariffEditPopupAtom,
    tenantRightsPopup,
} from '../../state/states';
import { logout } from '../../misc/logout';
import { v4 } from 'uuid';
import { useAtom } from '@gothub-team/got-atom';
import { FEATURE_MODE, useFeatureMode } from '../../hooks/useFeatureMode';

export const Menu = () => {
    const selectedTenantId = useAtom(SelectedTenantIdAtom);
    const selectedZevId = useAtom(selectedZEVID);

    const isPremium = useFeatureMode() === FEATURE_MODE.FULL;

    return (
        <MobileMenu>
            {selectedTenantId === selectedZevId && isPremium && (
                <div
                    onClick={() => {
                        tariffEditPopupAtom.set(true);
                        mobileMenuAtom.set(false);
                    }}
                    className="mb-6 w-full text-xl text-white"
                >
                    Tarif bearbeiten
                </div>
            )}
            {selectedTenantId === selectedZevId && (
                <div
                    onClick={() => {
                        landlordEditPopup.set(true);
                        mobileMenuAtom.set(false);
                    }}
                    className="mb-6 w-full text-xl text-white"
                >
                    Persönliche Daten
                </div>
            )}
            {selectedTenantId !== selectedZevId && (
                <div
                    onClick={() => {
                        tenantRightsPopup.set(true);
                        mobileMenuAtom.set(false);
                    }}
                    className="mb-6 w-full text-xl text-white"
                >
                    Rechte vergeben
                </div>
            )}
            {selectedTenantId !== selectedZevId && isPremium && (
                <div
                    onClick={() => {
                        landlordInvoicePopup.set(true);
                        SelectedInvoiceIdAtom.set(v4());
                        mobileMenuAtom.set(false);
                    }}
                    className="mb-6 w-full text-xl text-white"
                >
                    Rechnung erstellen
                </div>
            )}
            <div
                onClick={() => {
                    mobileMenuAtom.set(false);
                    logout();
                }}
                className="w-full text-xl text-white"
            >
                Abmelden
            </div>
        </MobileMenu>
    );
};
