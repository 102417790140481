/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { LoggedIn } from '../state/states';
import AuthScreen from '../pages/auth/auth';
import { gotApi } from '../misc/got.config';
import { useAtom } from '@gothub-team/got-atom';
import Loading from './Loading';
import { logout } from '../misc/logout';
import { loadUser } from '../misc/loadUser';

export default function Login({ mobile }) {
    useEffect(() => {
        const asyncfn = async () => {
            if (!gotApi.getCurrentSession()) {
                logout();
                return;
            }
            try {
                await gotApi.refreshSession();
                await loadUser({ mobile });
                LoggedIn.set('loggedIn');
            } catch (error) {
                logout();
            }
        };
        asyncfn();
    }, []);

    const li = useAtom(LoggedIn);
    if (li === 'init') return <Loading />;
    return (
        <div className="fade-in">
            <AuthScreen
                onLoggedIn={async () => {
                    if (gotApi.getCurrentUser() === null) {
                        logout();
                    } else {
                        await loadUser({ mobile });
                        LoggedIn.set('loggedIn');
                    }
                }}
                mobile={mobile}
            />
        </div>
    );
}
