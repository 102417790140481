import React from 'react';
import { SelectedTenantIdAtom, selectedZEVID } from '../../state/states';
import { MobileScreen } from '../components/MobileScreen';
import { Modals } from './Modals';
import { useAtom } from '@gothub-team/got-atom';
import { useGraph } from '../../misc/got.config';
import { EDITOR, MAIN } from '../../misc/consts';
import { Footer } from '../sharedScreenComponents/Footer';
import { Screens } from '../sharedScreenComponents/Screens';
import { Menu } from './Menu';
import { SelectModals } from './SelectModals';

export default function LandLordMainMobile() {
    const tenantId = useAtom(SelectedTenantIdAtom);
    const zevId = useAtom(selectedZEVID);
    const isZEVSelected = tenantId === zevId;
    const { useNode } = useGraph(MAIN, EDITOR);
    const meter = useNode(tenantId);

    const { nameT } = meter || {};

    return (
        <>
            <MobileScreen
                title={nameT ? nameT : isZEVSelected ? 'ZEV' : ''}
                footer={<Footer />}
                menu={<Menu />}
            >
                <Modals />
                <Screens />
            </MobileScreen>
            <SelectModals />
        </>
    );
}
