import { useAtom } from '@gothub-team/got-atom';
import { useEffect } from 'react';
import { SelectedDayAtom, SelectedMonthAtom } from '../../state/states';
import { useDebugMode } from '../../state/debugMode';
import { ToolTip } from '../../components/Tooltip';

const createTooltip = ({ unit }) => {
    const ChartTooltip = ({ tooltipAtom, internalArr, importArr, exportArr, chartArr }) => {
        useEffect(() => {
            tooltipAtom.set(null); //reset on mount
        }, [tooltipAtom]);

        const index = useAtom(tooltipAtom);

        const dataPoint = chartArr[index] || {};
        const timestamp = dataPoint.timestamp || '';

        const valInternal = (internalArr[index] || 0).toFixed(2);
        const valImport = ((importArr[index] || 0) + (internalArr[index] || 0)).toFixed(2);
        const valExport = (exportArr[index] || 0).toFixed(2);
        const valExternal = (importArr[index] || 0).toFixed(2);

        const valExportMeter = (dataPoint.export || 0).toFixed(3);
        const valImportMeter = (dataPoint.import || 0).toFixed(3);
        const valInternalMeter = (dataPoint.internal || 0).toFixed(3);

        const hasMonth = useAtom(SelectedMonthAtom);
        const hasDay = useAtom(SelectedDayAtom);

        const year = new Date(timestamp).getFullYear();
        const month = new Date(timestamp).toLocaleDateString('de-DE', { month: 'short' });
        const day = hasMonth && `${new Date(timestamp).getDate()}.`;
        const time = hasDay && new Date(timestamp).toLocaleTimeString('de-DE').slice(0, 5);

        const label = [time, day, month, year].filter(Boolean).join(' ');

        const debugMode = useDebugMode();

        return (
            <ToolTip tooltipAtom={tooltipAtom}>
                <div id="chartjs-tooltip" className={'flex flex-col'}>
                    <div className="text-lg">{label}</div>
                    <div className="flex flex-row gap-2">
                        <div className="flex flex-col">
                            <div className="my-1 h-4 w-4"></div>
                            <div className="my-1 h-4 w-4 border border-solid border-black bg-chart-internal"></div>
                            <div className="my-1 h-4 w-4 border border-solid border-black bg-chart-total"></div>
                        </div>
                        <div className="flex flex-col">
                            <div>Verbrauch Extern (Netzbezug): </div>
                            <div>Solar Eigenverbrauch: </div>
                            <div>Gesamtverbrauch: </div>
                            <div>Einspeisung: </div>
                            {debugMode && (
                                <>
                                    <div>Zähler Export: </div>
                                    <div>Zähler Import: </div>
                                    <div>Zähler Intern: </div>
                                </>
                            )}
                        </div>
                        <div className="flex flex-col">
                            <div>{`${valExternal}`}</div>
                            <div>{`${valInternal}`}</div>
                            <div>{`${valImport}`}</div>
                            <div>{`${valExport}`}</div>
                            {debugMode && (
                                <>
                                    <div>{`${valExportMeter}`}</div>
                                    <div>{`${valImportMeter}`}</div>
                                    <div>{`${valInternalMeter}`}</div>
                                </>
                            )}
                        </div>
                        <div className="flex flex-col">
                            <div>{unit}</div>
                            <div>{unit}</div>
                            <div>{unit}</div>
                            <div>{unit}</div>
                            {debugMode && (
                                <>
                                    <div>{`kWh`}</div>
                                    <div>{`kWh`}</div>
                                    <div>{`kWh`}</div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </ToolTip>
        );
    };
    return ChartTooltip;
};

export const BarTooltipZev = createTooltip({ unit: 'kWh' });
