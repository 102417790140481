import { useSpring, animated } from 'react-spring';

export const Backdrop = ({ toggle, onClick, className }) => {
    const opacitySpring = useSpring({
        opacity: toggle ? 1 : 0,
    });
    return (
        <animated.div
            className={`fixed left-0 top-0 h-screen w-full bg-[rgba(0,0,0,0.5)] ${className}`}
            onClick={() => onClick && onClick()}
            style={{
                ...opacitySpring,
                zIndex: 1,
                pointerEvents: toggle ? 'all' : 'none',
            }}
        />
    );
};
