import { atom } from '@gothub-team/got-atom';
import { indexAtom } from './indexAtom';

export const screenNav = atom('');
export const dashboardMobileAtom = atom('consumption');
export const screenNavLL = atom('zev-overview');
export const tenantRightsPopup = atom(false);
export const tenantEditPopup = atom(false);
export const landlordEditPopup = atom(false);
export const tariffEditPopupAtom = atom(false);
export const tariffCalcPopupAtom = atom(false);
export const landlordInvoicePopup = atom(false);
export const tenantInvoicePopup = atom(false);
export const tenantPesonalInfoPopup = atom(false);
export const tenantTariffDetailsPopup = atom(false);
export const consumptionOverview = atom(false);
export const consumptionOverviewLLDash = atom(false);
export const meterSelectionPopup = atom(false);
export const zevSelectionPopup = atom(false);
export const mobileMenuAtom = atom(false);
export const isPullingAtom = atom(false);
export const deleteMenuAtom = atom(false);
export const TenantExportPopup = atom(false);

export const llStatsIndexAtom = indexAtom(0, 4);

export const selectedZEVID = atom('');
export const SelectedTenantIdAtom = atom('');
export const SelectedInvoiceIdAtom = atom('');
export const MeterArrayAtom = atom([]);
export const ZevArrayAtom = atom([]);

export const LoggedIn = atom('init');

//sortieren und filtern
export const selectedFilter = atom('A-Z sortieren');
export const multiFilterSelected2 = atom([]);

export const searchInputAtom = atom(''); //Searchbar für Tenants
export const searchInputAtom2 = atom(''); //Searchbar für Invoices

const currentYearInt = new Date().getFullYear();
export const SelectedYearAtom = atom(currentYearInt);
export const SelectedMonthAtom = atom('');
export const SelectedDayAtom = atom('');
