import * as R from 'ramda';

export const PropInputField = ({
    label = '',
    type = 'text',
    defaultVal = '',
    propName,
    obj,
    setObj,
}) => {
    const value = R.propOr(defaultVal, propName, obj);
    return (
        <div className="mb-5 w-full">
            <label
                className={`mb-1 block text-xs font-medium transition duration-200 ${
                    value ? 'text-neutral-400' : 'text-transparent'
                }`}
            >
                {label}
            </label>
            <input
                // required
                placeholder={label}
                value={value}
                onChange={e => setObj(R.assoc(propName, e.target.value, obj))}
                type={type}
                name={label}
                id={label}
                className="h-10 w-full rounded-lg  border-none py-1  shadow-[0_0_0_1px_#e5e7eb] outline-none placeholder:text-center focus:shadow-[0_0_0_2px_rgb(234,179,8)]"
            />
        </div>
    );
};
