import React from 'react';
import { useAtom } from '@gothub-team/got-atom';
import { ZevArrayAtom, selectedZEVID } from '../state/states';

export const ZevList = () => {
    const zevArray = useAtom(ZevArrayAtom);
    const zevID = useAtom(selectedZEVID);
    return (
        <div className="flex-1 overflow-y-scroll">
            {zevArray.map(zev => {
                const zevIsSelected = zevID === zev.nodeId;
                return (
                    <div
                        key={zev.nodeId}
                        onClick={() => {
                            if (!zevIsSelected) {
                                selectedZEVID.set(zev.nodeId);
                            }
                        }}
                        className={`relative mx-auto mb-2 w-full cursor-pointer rounded-lg px-2 text-center text-2xl text-slate-600 transition duration-200 ${
                            zevIsSelected ? 'bg-yellow-200' : 'hover:bg-yellow-100'
                        }`}
                    >
                        {zev.node.name}
                    </div>
                );
            })}
        </div>
    );
};
