import React from 'react';
import { SelectedTenantIdAtom } from '../state/states';
import { useAtom } from '@gothub-team/got-atom';

export const ZevRow = ({ zev }) => {
    const { name } = zev || {};
    const isRowIsSelected = zev.id === useAtom(SelectedTenantIdAtom) ? 'bg-yellow-200' : '';

    return (
        <div className="flex justify-center">
            <div
                className={`item-center text-md flex w-full cursor-pointer rounded-lg border p-2 text-neutral-400 transition duration-100  ${isRowIsSelected} ${
                    isRowIsSelected ? '' : 'hover:bg-yellow-100'
                }`}
                onClick={() => {
                    SelectedTenantIdAtom.set(zev.id);
                }}
            >
                <div className="w-1/3 text-slate-900">{name}</div>
            </div>
        </div>
    );
};
