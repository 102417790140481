import React from 'react';
import Icon from './Icon';
import { logout } from '../misc/logout';

export const LogoutButton = ({ className }) => {
    return (
        <div
            className={
                'flex h-5 w-5 cursor-pointer items-center fill-slate-600 transition hover:fill-yellow-400 ' +
                className
            }
            onClick={logout}
            title="Abmelden"
        >
            <Icon name="logout" className={className} />
        </div>
    );
};
