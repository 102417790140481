import React from 'react';
import { useGraph } from '../../misc/got.config';
import { EDITOR, MAIN } from '../../misc/consts';
import { SelectedTenantIdAtom, selectedZEVID } from '../../state/states';
import { useAtom } from '@gothub-team/got-atom';
import ReactWeather from 'react-open-weather';
import { Chaotic3dLogoSpinner } from '../components/Chaotic3dLogoSpinner';
import { useOpenWeatherWithBackup } from '../../hooks/useOpenWeatherFreemium';

export const WeatherMobile = () => {
    const { useNode } = useGraph(MAIN, EDITOR);
    const selectedTenantId = useAtom(SelectedTenantIdAtom);
    const tenant = useNode(selectedTenantId);
    const selectedZevId = useAtom(selectedZEVID);
    const landLord = useNode(selectedZevId);
    const { cityT } = tenant || {};
    const { city } = landLord || {};
    const cityWeather = selectedTenantId === selectedZevId ? city : cityT;
    const { data, isLoading, errorMessage } = useOpenWeatherWithBackup(cityWeather);
    if (isLoading) return <Chaotic3dLogoSpinner />;
    return (
        <div className="fade-in h-full">
            <ReactWeather
                isLoading={isLoading}
                errorMessage={errorMessage}
                data={data}
                lang="de"
                locationLabel={cityWeather}
                unitsLabels={{ temperature: 'C', windSpeed: 'Km/h' }}
                showForecast
            />
        </div>
    );
};
