import React from 'react';

export const Tile = ({
    className,
    title,
    topbar,
    content,
    noScrollbar,
    onMouseEnter,
    onMouseLeave,
}) => (
    <div
        className={`shadow-effect relative flex h-full flex-col overflow-y-auto rounded-2xl bg-white ${className} ${
            noScrollbar ? 'noscrollbar' : ''
        }`}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
    >
        <TileTopbar title={title} topbar={topbar} />
        {content}
    </div>
);

const TileTopbar = ({ title, topbar }) => {
    if (!title) {
        return null;
    }
    return (
        <div className="sticky left-0 top-0 mb-1 flex h-[10%] w-full items-start justify-between bg-white pt-3">
            <div className="flex h-[20px] items-center text-2xl font-medium text-emerald-700">
                {title}
            </div>
            {topbar && topbar}
        </div>
    );
};
