import React from 'react';
import { MobileModal } from '../../components/MobileModal';
import { tenantPesonalInfoPopup } from '../../../state/states';
import { useAtom } from '@gothub-team/got-atom';
import { useInfoTenant } from '../../../hooks/useInfoTenant';
import { TariffData, TenantContact, TenantInfo } from '../../../tenant/Modals/TennantInfo/shared';
import { FEATURE_MODE, useFeatureMode } from '../../../hooks/useFeatureMode';

export const TenantInfoModalMobile = () => {
    const tenantEditPopup = useAtom(tenantPesonalInfoPopup);
    return (
        <MobileModal
            onClose={() => tenantPesonalInfoPopup.set(false)}
            title="Persönliche Daten"
            isOpen={tenantEditPopup}
            scroll
        >
            {tenantEditPopup && <Content />}
        </MobileModal>
    );
};

const Content = () => {
    const { tenant, tariffRes } = useInfoTenant();

    const isPremium = useFeatureMode() === FEATURE_MODE.FULL;

    return (
        <>
            <div className="mx-auto flex h-full w-full justify-center gap-5 p-3">
                <div className="flex flex-col gap-2">
                    <TenantInfo tenant={tenant} />
                    <TenantContact tenant={tenant} />
                    {isPremium && <TariffData tariffRes={tariffRes} />}
                </div>
            </div>
        </>
    );
};
