/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from 'react';
import * as R from 'ramda';
import { calcDeltas } from '../../hooks/useChartData';
import 'chart.js/auto';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import { SelectedDayAtom, SelectedMonthAtom } from '../../state/states';
import { BarTooltip } from './Tooltip';
import { getDayLabel, getMonthLabel, handleExternalTooltip, useBarThickness } from './shared';
import { useCreateAtom } from '@gothub-team/got-atom';
import { monthlyChartOptions, yearlyChartOptions } from './BarChartOptions';

export const createBarChart = ({ options: _options, onClick, getLabel }) => {
    const Component = ({ chartData, startValues }) => {
        const ref = useRef();

        const chartArr = R.pathOr([], ['values'], chartData);
        const importStart = R.pathOr(startValues.import || 0, ['start', 'import'], chartData);
        const importArr = calcDeltas(importStart, chartArr, 'import');
        const internalStart = R.pathOr(startValues.internal || 0, ['start', 'internal'], chartData);
        const internalArr = calcDeltas(internalStart, chartArr, 'internal');
        const exportStart = R.pathOr(startValues.export || 0, ['start', 'export'], chartData);
        const exportArr = calcDeltas(exportStart, chartArr, 'export');

        const labels = chartArr.map(item => getLabel(item));

        const data = {
            labels: labels,
            datasets: [
                {
                    label: 'Verbrauch Intern',
                    data: internalArr,
                    backgroundColor: ['rgb(250 204 21)'],
                    borderColor: 'rgb(250 204 21)',
                    // borderRadius: 5,
                    borderWidth: 1,
                },
                {
                    label: 'Verbrauch Gesamt',
                    data: importArr,
                    backgroundColor: ['rgb(71 85 105)'],
                    borderColor: 'rgb(71 85 105)',
                    // borderRadius: 5,
                    borderWidth: 1,
                },
            ],
        };

        const tooltipAtom = useCreateAtom(null);

        const optionsWithBarThickness = useBarThickness(_options, ref, labels.length);
        const options = R.assocPath(
            ['plugins', 'tooltip'],
            {
                enabled: false,
                external: handleExternalTooltip(tooltipAtom),
                interaction: 'x',
            },
            optionsWithBarThickness,
        );

        return (
            <>
                <Bar
                    ref={ref}
                    data={data}
                    options={options}
                    onClick={e => {
                        const elements = getElementAtEvent(ref.current, e) || [];
                        if (elements.length === 0) return;

                        const elementIndex = elements[0].index;
                        const element = chartData.values[elementIndex];
                        onClick && onClick(element);
                    }}
                    onMouseLeave={() => {
                        tooltipAtom.set(null);
                    }}
                />
                <BarTooltip
                    tooltipAtom={tooltipAtom}
                    internalArr={internalArr}
                    importArr={importArr}
                    exportArr={exportArr}
                    chartArr={chartArr}
                />
            </>
        );
    };

    return Component;
};

export const YearlyBarChart = createBarChart({
    options: yearlyChartOptions,
    getLabel: getMonthLabel,
    onClick: monthElement => {
        const month = monthElement.timestamp.substring(5, 7);
        SelectedMonthAtom.set(month);
    },
});

export const MonthlyBarChart = createBarChart({
    options: monthlyChartOptions,
    getLabel: getDayLabel,
    onClick: dayElement => {
        const day = dayElement.timestamp.substring(8, 10);
        SelectedDayAtom.set(day);
    },
});

export const YearlyBarChartPlain = createBarChart({
    options: yearlyChartOptions,
    getLabel: getMonthLabel,
});
