const scales = {
    x: {
        stacked: true,
        grid: {
            display: false,
            drawBorder: false,
        },
        ticks: {
            color: 'black',
        },
    },
    y: {
        grid: {
            drawBorder: false,
            display: false,
        },
        title: {
            display: true,
            text: 'kWh',
        },
    },
};

export const yearlyChartOptions = {
    indexAxis: 'x',
    barThickness: 20,
    scales: scales,
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            enabled: false, // Disable the on-canvas tooltip
        },
    },
    interaction: {
        mode: 'x',
        axis: 'x',
        intersect: false,
    },
};

export const monthlyChartOptions = {
    indexAxis: 'x',
    barThickness: 10,
    scales: scales,
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            enabled: false, // Disable the on-canvas tooltip
        },
    },
    interaction: {
        mode: 'x',
        axis: 'x',
        intersect: false,
    },
};
