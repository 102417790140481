import React from 'react';
import { SelectedTenantIdAtom, selectedZEVID } from '../../state/states';
import { useGraph } from '../../misc/got.config';
import { EDITOR, MAIN } from '../../misc/consts';
import { useAtom } from '@gothub-team/got-atom';

export const ZevRow = () => {
    const { useNode } = useGraph(MAIN, EDITOR);
    const zevId = useAtom(selectedZEVID);
    const zev = useNode(zevId);
    const { name, adress, city } = zev || {};
    return (
        <div className="mx-1 my-4 mb-8 flex items-end justify-center">
            <div
                className={`text-md flex w-full cursor-pointer flex-col items-center rounded-xl border text-neutral-400 transition duration-100`}
                onClick={() => {
                    SelectedTenantIdAtom.set(zevId);
                }}
            >
                <div className="w-full text-lg text-slate-900">{name || 'ZEV'}</div>
                <div className="w-full">{adress || 'Gesamtes ZEV anzeigen'}</div>
                {city && <div className="w-full">{city}</div>}
            </div>
        </div>
    );
};

export const MeterRow = ({ meterId }) => {
    const { useNode } = useGraph(MAIN, EDITOR);
    const meter = useNode(meterId);
    const zevId = useAtom(selectedZEVID);
    const { id, nameT, adressT, cityT } = meter || {};
    return (
        <div className="mx-1 my-4 mb-8 flex items-end justify-center">
            <div
                className={`text-md flex w-full cursor-pointer flex-col items-center rounded-xl border text-neutral-400 transition duration-100`}
                onClick={() => {
                    if (!meterId) {
                        SelectedTenantIdAtom.set(zevId);
                    } else {
                        SelectedTenantIdAtom.set(meter.id);
                    }
                }}
            >
                <div className="w-full text-lg text-slate-900">{nameT || id}</div>
                {adressT && <div className="w-full">{adressT}</div>}
                {cityT && <div className="w-full">{cityT}</div>}
            </div>
        </div>
    );
};
