import React, { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { Backdrop } from './Backdrop';
import { CloseButton, SaveButton } from './Buttons';

export const MobileModal = ({ children, className, onClose, onSave, title, isOpen, scroll }) => {
    useEffect(() => {
        setLocalOpen(isOpen);
    }, [isOpen]);
    const [localOpen, setLocalOpen] = useState(isOpen);
    const openClose = useSpring({
        transform: localOpen ? `translateY(0%)` : 'translateY(105%)',
        onRest: () => !localOpen && onClose(),
    });

    return (
        <>
            <animated.div
                style={openClose}
                className="fixed bottom-0 left-0 z-20 flex h-[95%] w-full flex-col items-center justify-center overflow-hidden rounded-t-xl bg-white shadow-[0_-10px_10px_rgba(0,0,0,0.3)]"
            >
                {title && (
                    <div className="flex h-12 w-full items-center justify-start bg-white p-3 px-4 text-center text-2xl text-emerald-700">
                        {title}
                    </div>
                )}
                <div
                    className={`flex w-full flex-1 p-2 ${className} ${
                        scroll ? 'overflow-y-scroll' : ''
                    }`}
                    onClick={e => e.stopPropagation()}
                >
                    {children}
                </div>
                <CloseButton onClick={() => setLocalOpen(false)} />
                {onSave && <SaveButton onClick={onSave} />}
            </animated.div>
            <Backdrop toggle={localOpen} onClick={() => setLocalOpen(false)} />
        </>
    );
};
