//
export const APPLICATION_ROOT_NODE = 'herbenergy';

//edges
export const ROOT_ZEV_EDGE = 'root/zev';
export const ZEV_METER_EDGE = 'zev/meter';
export const METER_INVOICE_EDGE = 'meter/invoice';

//graphnames
export const MAIN = 'main';
export const EDITOR = 'editor';
export const TARIF_CACLULATOR = 'tarif-calculator';
export const TARIF_EDITOR = 'tarif-editor';
