import React from 'react';
import { EditLandlordModalMobile } from './Modals/EditLandlordModalMobile';
import { TenantRightsModalMobile } from './Modals/TenantRightsModalMobile';
import { CreateInvoiceModalMobile } from '../../landlord/Modals/CreateInvoice/CreateInvoiceModalMobile';
import { EditTariffModalMobile } from '../../landlord/Modals/EditTariff/EditTariffModalMobile';
import { TariffCalculatorModalMobile } from '../../landlord/Modals/TariffCalculator/ModalMobile';

export const Modals = () => {
    return (
        <>
            <EditTariffModalMobile />
            <TariffCalculatorModalMobile />
            <EditLandlordModalMobile />
            <TenantRightsModalMobile />
            <CreateInvoiceModalMobile />
        </>
    );
};
