import React from 'react';
import { useMeters } from '../../hooks/useMeters';
import { MeterRow, ZevRow } from './MeterRow';
import { Chaotic3dLogoSpinner } from '../components/Chaotic3dLogoSpinner';

export default function TenantSelectionMobile({ tenant }) {
    const { tenantIDs, loading } = useMeters();

    return (
        <div className="flex-1 overflow-y-scroll">
            {loading ? (
                <div className="fixed left-0 top-[5%] flex h-[90%] w-full items-center justify-center">
                    <Chaotic3dLogoSpinner />
                </div>
            ) : (
                <div className="fade-in">
                    {!tenant && <ZevRow key={'ZEV'} />}
                    {tenantIDs.map(tenantId => (
                        <MeterRow key={tenantId} meterId={tenantId} />
                    ))}
                </div>
            )}
        </div>
    );
}
