import { useState } from 'react';
import { useEffect } from 'react';

export const handleExternalTooltip = tooltipAtom => context => {
    // Content
    const active = context.chart.tooltip._active[0];
    if (active) {
        const index = active.index;
        tooltipAtom.set(index);
    }
};

export const handleExternalTooltipDonut = tooltipAtom => context => {
    // Hide if no tooltip
    if (context.tooltip.opacity === 0) {
        tooltipAtom.set(null);
        return;
    }

    // Content
    const active = context.chart.tooltip._active[0];
    if (active) {
        const index = active.index;
        tooltipAtom.set(index);
    } else {
        tooltipAtom.set(null);
    }
};

export const getMonthLabel = item =>
    new Date(item.timestamp).toLocaleDateString('de-DE', { month: 'short' });

export const getDayLabel = item =>
    new Date(item.timestamp).toLocaleDateString('de-DE', { day: '2-digit' });

export const useBarThickness = (options, ref, numElements) => {
    const [barThickness, setBarThickness] = useState(options.barThickness);

    // update when item number changes
    useEffect(() => {
        const resizeBars = () => {
            if (!ref.current) return;
            const width = ref.current.chartArea.width;
            const newThickness = (width / numElements) * 0.75; // 25% gap

            setBarThickness(newThickness);
        };

        // something is always the wrong size, so lets just update every 100ms
        const interval = setInterval(resizeBars, 100);
        return () => clearInterval(interval);
    }, [numElements]);

    return {
        ...options,
        barThickness: barThickness,
    };
};
