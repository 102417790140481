import React from 'react';
import { useAtom } from '@gothub-team/got-atom';
import { ZevArrayAtom, selectedZEVID, zevSelectionPopup } from '../state/states';
import { LogoutButton } from '../components/Logout';
import { isMobile } from '../misc/util';
import { loadZev } from '../misc/loadUser';

export const ZevSelectionModal = () => {
    const zevSelectPopup = useAtom(zevSelectionPopup);

    if (!zevSelectPopup) return null;

    return <Content />;
};

const Content = () => {
    const zevArray = useAtom(ZevArrayAtom);
    const zevId = useAtom(selectedZEVID);

    return (
        <div className="absolute left-0 top-0 h-full w-full bg-black/60 backdrop-blur-sm">
            <div className="fixed left-1/2 top-1/2 flex h-2/5 w-1/4 -translate-x-1/2 -translate-y-1/2 flex-col rounded-2xl bg-white p-5">
                <div className="mb-10 text-2xl font-medium text-emerald-700">ZEV wählen</div>
                <LogoutButton className="fixed right-5 top-5 h-6 w-6" />
                <div className="flex-1 overflow-y-scroll">
                    {zevArray.map((zev, index) => (
                        <div
                            key={zev.nodeId}
                            onClick={async () => {
                                loadZev(zev, isMobile());
                                zevSelectionPopup.set(false);
                            }}
                            className={
                                'relative mx-auto mb-2 w-full cursor-pointer rounded-lg px-2 text-center text-2xl text-slate-600 transition duration-200 hover:bg-yellow-100' +
                                (zevId === zev.nodeId ? ' bg-gray-100' : '')
                            }
                        >
                            {zev.node.name}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
