import React from 'react';
import { TenantInfoModalMobile } from './Modals/TenantInfoModalMobile';
import { TariffDetailsModalMobile } from '../../tenant/Modals/TariffDetails/TariffDetailsModalMobile';

export const Modals = () => {
    return (
        <>
            <TenantInfoModalMobile />
            <TariffDetailsModalMobile />
        </>
    );
};
