import { atom } from '@gothub-team/got-atom';
import * as R from 'ramda';

export const indexAtom = (init, max) => {
    const _atom = atom(0);

    const setIndex = index => {
        _atom.set(R.clamp(0, max - 1, (index + max) % max));
    };
    return {
        ..._atom,
        setIndex,
    };
};
