import React from 'react';
import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
import { useDoughnutData } from '../hooks/useDoughnutData';
import { useCreateAtom } from '@gothub-team/got-atom';
import { handleExternalTooltipDonut } from './TenantEnergyGraph/shared';
import { ToolTip } from '../components/Tooltip';

export default function TenantStatisticsDonut() {
    const { internalPercent, donutData } = useDoughnutData();

    const tooltipAtom = useCreateAtom(null);

    const options = {
        plugins: {
            tooltip: {
                enabled: false,
                external: handleExternalTooltipDonut(tooltipAtom),
            },
        },
    };

    return (
        <div className="relative mt-3 flex h-4/5 items-center justify-center rounded-md bg-white">
            <div className="relative h-fit w-fit">
                <Doughnut
                    options={options}
                    data={donutData}
                    onMouseLeave={() => {
                        tooltipAtom.set(null);
                    }}
                />
                <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
                    <h1 className="translate-y-4 text-2xl font-bold">{`${internalPercent.toFixed(
                        1,
                    )}%`}</h1>
                </div>
                <DonutTooltip
                    tooltipAtom={tooltipAtom}
                    labels={donutData.labels}
                    data={donutData.datasets[0].data}
                />
            </div>
        </div>
    );
}

const DonutTooltip = ({ tooltipAtom, labels, data }) => {
    return (
        <ToolTip tooltipAtom={tooltipAtom}>
            <div id="chartjs-tooltip" className={'flex flex-col'}>
                <div className="text-lg">Autarkie</div>
                <div className="flex flex-row gap-2">
                    <div className="flex flex-col">
                        <div className="my-1 h-4 w-4 border border-solid border-black bg-chart-internal"></div>
                        <div className="my-1 h-4 w-4 border border-solid border-black bg-chart-external"></div>
                    </div>
                    <div className="flex flex-col">
                        <div>{`${labels[0]}: `}</div>
                        <div>{`${labels[1]}: `}</div>
                    </div>
                    <div className="flex flex-col">
                        <div>{`${data[0].toFixed(3)}`}</div>
                        <div>{`${data[1].toFixed(3)}`}</div>
                    </div>
                    <div className="flex flex-col">
                        <div>kWh</div>
                        <div>kWh</div>
                    </div>
                </div>
            </div>
        </ToolTip>
    );
};
