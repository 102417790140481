import React from 'react';

const createInput =
    (placeholder, type = 'text', name = '') =>
    ({ value, onChange }) =>
        (
            <input
                className={`h-10 rounded-full border border-solid border-gray-200 py-1 text-center text-black outline-none transition placeholder:text-center placeholder:text-neutral-800 focus:border-yellow-400`}
                placeholder={placeholder}
                type={type}
                name={name}
                value={value}
                onChange={e => onChange(e.target.value)}
            />
        );

export const EmailInput = createInput('E-Mail', 'email', 'email');
export const PasswordInput = createInput('Passwort', 'password', 'password');
export const PasswordConfirmInput = createInput(
    'Passwort wiederholen',
    'password',
    'password-confirm',
);
export const CodeInput = createInput('Code', 'text', 'text');
