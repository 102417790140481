import React from 'react';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { useAtom } from '@gothub-team/got-atom';
import {
    SelectedDayAtom,
    SelectedMonthAtom,
    SelectedTenantIdAtom,
    SelectedYearAtom,
    isPullingAtom,
} from '../../state/states';
import { calcDeltas, useChartData } from '../../hooks/useChartData';
import YearSelection from '../../landlord/YearSelection';
import Icon from '../../components/Icon';
import { Chaotic3dLogoSpinner } from '../components/Chaotic3dLogoSpinner';
import { useSpring, animated } from 'react-spring';
import { MobileDoughnut } from './Doughnut';
import { useMeterStartValue } from '../../hooks/useMeterStartValue';

const monthNames = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
];

export const ConsProdComparison = () => {
    const selectedMonth = useAtom(SelectedMonthAtom);
    const selectedYear = useAtom(SelectedYearAtom);
    const selectedDay = useAtom(SelectedDayAtom);
    const chartData = useChartData(selectedYear, selectedMonth, selectedDay);
    const tenantId = useAtom(SelectedTenantIdAtom);
    const pullowrhino = useAtom(isPullingAtom);
    const chartArray = R.pathOr([], ['values'], chartData);

    const startValue = useMeterStartValue();
    const totalStart = R.pathOr(startValue, ['start', 'import'], chartData);
    const totalDataArray = calcDeltas(totalStart, chartArray, 'import');

    const internalStart = R.pathOr(0, ['start', 'internal'], chartData);
    const internalDataArray = calcDeltas(internalStart, chartArray, 'internal');

    const peakMonth = totalDataArray.reduce(
        (max, val) => (val > max ? val : max),
        totalDataArray[0],
    );
    const getMonthWidth = value => {
        return `${(value / peakMonth) * 100}`;
    };

    const [showDoughnut, setShowDoughnut] = React.useState(false);

    if (pullowrhino) return <Chaotic3dLogoSpinner />;

    return (
        <div className="fade-in flex h-full w-full flex-col p-2">
            {tenantId && (
                <div className="relative mb-2">
                    <div
                        className={`flex h-16 items-center justify-center transition duration-300 ${
                            showDoughnut ? 'opacity-0' : 'opacity-100'
                        }`}
                    >
                        <YearSelection mobile />
                    </div>
                    <ChartToggle showDoughnut={showDoughnut} setShowDoughnut={setShowDoughnut} />
                </div>
            )}
            {showDoughnut ? (
                <MobileDoughnut />
            ) : (
                <Bars
                    totalDataArray={totalDataArray}
                    internalDataArray={internalDataArray}
                    getMonthWidth={getMonthWidth}
                />
            )}
        </div>
    );
};

const ChartToggle = ({ showDoughnut, setShowDoughnut }) => {
    const icon1Spring = useSpring({
        opacity: showDoughnut ? 1 : 0,
        transform: showDoughnut ? 'scale(1)' : 'scale(0)',
        transformOrigin: 'center',
    });
    const icon2Spring = useSpring({
        opacity: showDoughnut ? 0 : 1,
        transform: showDoughnut ? 'scale(0)' : 'scale(1)',
        transformOrigin: 'center',
    });
    return (
        <div
            onClick={() => setShowDoughnut(!showDoughnut)}
            className={`absolute right-1 top-1/2 flex h-5 w-5 -translate-y-1/2 items-center justify-center`}
        >
            <animated.div style={icon1Spring} className="absolute left-0 top-0 h-5 w-5">
                <Icon name="chart" className="h-5 w-5 rotate-90 fill-slate-600" />
            </animated.div>
            <animated.div style={icon2Spring} className="absolute left-0 top-0 h-5 w-5">
                <Icon name="pie" className="h-5 w-5 fill-slate-600" />
            </animated.div>
        </div>
    );
};

const Bars = ({ totalDataArray, internalDataArray, getMonthWidth }) =>
    RA.mapIndexed((totalValue, index) => {
        const internalValue = internalDataArray[index];
        return (
            <div className="flex h-full w-full flex-col" key={monthNames[index]}>
                <div className={`text-md mb-1 pl-3 font-medium text-slate-600`}>
                    {monthNames[index]}
                </div>
                <ConsumptionBar width={getMonthWidth(totalValue)} month={totalValue} />
                <ProductionBar width={getMonthWidth(internalValue)} month={internalValue} />
            </div>
        );
    })(totalDataArray);

const ConsumptionBar = ({ width, month }) => (
    <div className={`relative mb-1 flex h-8 w-full items-center rounded-full bg-slate-600/10`}>
        <div
            style={{
                width: width + '%',
            }}
            className={`flex h-full min-w-[2.5rem] items-center rounded-full  text-white ${
                !month ? 'w-0 bg-transparent pl-3' : 'scale-in bg-emerald-700 px-3'
            } ${width <= 50 ? 'justify-end' : ' justify-between'}`}
        >
            {width > 50 && (
                <div className="text-sm font-semibold  text-white">
                    {month.toFixed(2)} <span className="font-light">kWh</span>
                </div>
            )}
            <Icon name="lightbulb" className="h-4 w-4 fill-white" />
        </div>
        {width <= 50 && (
            <div className="absolute right-3 text-sm font-semibold text-emerald-700">
                {month.toFixed(2)} <span className="font-light">kWh</span>
            </div>
        )}
    </div>
);

const ProductionBar = ({ width, month }) => (
    <div className={`relative mb-5 flex h-8 w-full items-center rounded-full bg-slate-600/10`}>
        <div
            style={{
                width: width + '%',
            }}
            className={`flex h-full min-w-[2.5rem] items-center rounded-full  text-white ${
                !month ? 'w-0 bg-transparent pl-3' : 'scale-in bg-amber-400/90 px-3'
            } ${width <= 50 ? 'justify-end' : ' justify-between'}`}
        >
            {width > 50 && (
                <div className="text-sm font-semibold  text-white">
                    {month.toFixed(2)} <span className="font-light">kWh</span>
                </div>
            )}
            <Icon name="sun" className="h-4 w-4 fill-white" />
        </div>
        {width <= 50 && (
            <div className="absolute right-3 text-sm font-semibold text-amber-400">
                {month.toFixed(2)} <span className="font-light">kWh</span>
            </div>
        )}
    </div>
);
