import { ReactComponent as House } from '../images/house.svg';
import { ReactComponent as Statistics } from '../images/statistics.svg';
import { ReactComponent as PDF } from '../images/file-pdf.svg';
import { ReactComponent as Tenant } from '../images/tenant.svg';
import { ReactComponent as Logout } from '../images/logout.svg';
import { ReactComponent as UserPlus } from '../images/user-plus-solid.svg';
import { ReactComponent as EditUser } from '../images/edit-user.svg';
import { ReactComponent as Sort } from '../images/sort.svg';
import { ReactComponent as Invoice } from '../images/invoice.svg';
import { ReactComponent as UserCheck } from '../images/user-check.svg';
import { ReactComponent as Filter } from '../images/filter.svg';
import { ReactComponent as FilterDelete } from '../images/filter-delete.svg';
import { ReactComponent as SearchIcon } from '../images/searchIcon.svg';
import { ReactComponent as Rights } from '../images/circle-info-solid.svg';
import { ReactComponent as Close } from '../images/close.svg';
import { ReactComponent as Check } from '../images/check.svg';
import { ReactComponent as TrashCan } from '../images/trash-can.svg';
import { ReactComponent as Burger } from '../images/bars-solid.svg';
import { ReactComponent as ChevRight } from '../images/chevron-right-solid.svg';
import { ReactComponent as ChevLeft } from '../images/chevron-left-solid.svg';
import { ReactComponent as Euro } from '../images/euro-sign-solid.svg';
import { ReactComponent as Plug } from '../images/plug-solid.svg';
import { ReactComponent as Plus } from '../images/plus-solid.svg';
import { ReactComponent as Back } from '../images/arrow-left-solid.svg';
import { ReactComponent as Hint } from '../images/lightbulb-regular.svg';
import { ReactComponent as Logo } from '../images/herb-energy-logo.svg';
import { ReactComponent as ArrowUp } from '../images/arrow-up-solid.svg';
import { ReactComponent as X } from '../images/xmark-solid.svg';
import { ReactComponent as People } from '../images/people-roof-solid.svg';
import { ReactComponent as Weather } from '../images/cloud-sun-solid.svg';
import { ReactComponent as File } from '../images/file-solid.svg';
import { ReactComponent as Chart } from '../images/chart-simple-solid.svg';
import { ReactComponent as Edit } from '../images/pen-to-square-regular.svg';
import { ReactComponent as Sun } from '../images/sun-regular.svg';
import { ReactComponent as Lightbulb } from '../images/lightbulb-regular.svg';
import { ReactComponent as Paperclip } from '../images/paperclip-solid.svg';
import { ReactComponent as CheckSolid } from '../images/check-solid.svg';
import { ReactComponent as Pie } from '../images/chart-pie-solid.svg';
import { ReactComponent as CopyArrow } from '../images/copy-arrow.svg';
import { ReactComponent as InfoCircle } from '../images/info-circle.svg';
import { ReactComponent as Refresh } from '../images/refresh.svg';
import { ReactComponent as Download } from '../images/download.svg';

export default function Icon({ name, className = '' }) {
    if (name === 'house') return <House className={`icon ${className}`} />;
    else if (name === 'statistics') return <Statistics className={`icon ${className}`} />;
    else if (name === 'pdf') return <PDF className={`icon ${className}`} />;
    else if (name === 'tenant') return <Tenant className={`icon ${className}`} />;
    else if (name === 'logout') return <Logout className={`icon ${className}`} />;
    else if (name === 'user-plus') return <UserPlus className={`icon ${className}`} />;
    else if (name === 'edit-user') return <EditUser className={`icon ${className}`} />;
    else if (name === 'sort') return <Sort className={`icon ${className}`} />;
    else if (name === 'invoice') return <Invoice className={`icon ${className}`} />;
    else if (name === 'user-check') return <UserCheck className={`icon ${className}`} />;
    else if (name === 'filter') return <Filter className={`icon ${className}`} />;
    else if (name === 'filter-delete') return <FilterDelete className={`icon ${className}`} />;
    else if (name === 'searchIcon') return <SearchIcon className={`icon ${className}`} />;
    else if (name === 'rights') return <Rights className={`icon ${className}`} />;
    else if (name === 'close') return <Close className={`icon ${className}`} />;
    else if (name === 'check') return <Check className={`icon ${className}`} />;
    else if (name === 'trash-can') return <TrashCan className={`icon ${className}`} />;
    else if (name === 'burger') return <Burger className={`icon ${className}`} />;
    else if (name === 'chevright') return <ChevRight className={`icon ${className}`} />;
    else if (name === 'chevleft') return <ChevLeft className={`icon ${className}`} />;
    else if (name === 'euro') return <Euro className={`icon ${className}`} />;
    else if (name === 'plug') return <Plug className={`icon ${className}`} />;
    else if (name === 'plus') return <Plus className={`icon ${className}`} />;
    else if (name === 'back') return <Back className={`icon ${className}`} />;
    else if (name === 'hint') return <Hint className={`icon ${className}`} />;
    else if (name === 'logo') return <Logo className={`icon ${className}`} />;
    else if (name === 'arrow-up') return <ArrowUp className={`icon ${className}`} />;
    else if (name === 'x') return <X className={`icon ${className}`} />;
    else if (name === 'people') return <People className={`icon ${className}`} />;
    else if (name === 'weather') return <Weather className={`icon ${className}`} />;
    else if (name === 'file') return <File className={`icon ${className}`} />;
    else if (name === 'chart') return <Chart className={`icon ${className}`} />;
    else if (name === 'edit') return <Edit className={`icon ${className}`} />;
    else if (name === 'sun') return <Sun className={`icon ${className}`} />;
    else if (name === 'lightbulb') return <Lightbulb className={`icon ${className}`} />;
    else if (name === 'paperclip') return <Paperclip className={`icon -rotate-45 ${className}`} />;
    else if (name === 'check-solid') return <CheckSolid className={`icon ${className}`} />;
    else if (name === 'pie') return <Pie className={`icon ${className}`} />;
    else if (name === 'copy-arrow') return <CopyArrow className={`icon ${className}`} />;
    else if (name === 'info-circle') return <InfoCircle className={`icon ${className}`} />;
    else if (name === 'refresh') return <Refresh className={`icon ${className}`} />;
    else if (name === 'download') return <Download className={`icon ${className}`} />;

    return null;
}
