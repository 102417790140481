import React from 'react';
import { useAtom } from '@gothub-team/got-atom';
import { searchInputAtom2, SelectedTenantIdAtom } from '../state/states';
import InvoiceRow from './InvoiceRow';
import * as R from 'ramda';
import { useGraph } from '../misc/got.config';
import { EDITOR, MAIN, METER_INVOICE_EDGE } from '../misc/consts';
import Icon from '../components/Icon';
import { InvoiceDataModalAtom } from './Modals/InvoiceDataModal';

const createInvoiceView = tenantID =>
    tenantID
        ? {
              [tenantID]: {
                  as: 'tenant',
                  include: {
                      node: true,
                  },
                  edges: {
                      [METER_INVOICE_EDGE]: {
                          as: 'invoices',
                          include: {
                              files: true,
                              edges: true,
                              metadata: true,
                              node: true,
                          },
                      },
                  },
              },
          }
        : {};

export const InvoiceList = ({ canDelete }) => {
    const searchInput2 = useAtom(searchInputAtom2);
    const searchArray = searchInput2.split(' ');
    const searchInvoices = (arr, sw) => {
        return (arr = arr.filter(
            a =>
                !sw ||
                a.node.invoice?.invoiceDate?.includes(sw) ||
                a.node.invoice?.invoiceNumber?.includes(sw),
        ));
    };

    const sortInvoicesByDate = arr => {
        return arr.sort((a, b) =>
            a.node.invoice?.invoiceDate > b.node.invoice?.invoiceDate ? 1 : -1,
        );
    };

    function forLoop(arr, sa) {
        for (let index = 0; index < sa.length; index++) {
            arr = searchInvoices(arr, sa[index]);
        }
        return arr;
    }
    const selectorInvoiceIDs = viewRes => {
        const invoicesObj = R.pathOr({}, ['tenant', 'invoices'], viewRes);
        const invoicesArray = R.values(invoicesObj);
        const sortedInvoicesArray = sortInvoicesByDate(invoicesArray);
        const invoicesFiltered = forLoop(sortedInvoicesArray, searchArray);
        const invoiceIDsArray = invoicesFiltered.map(invoice => {
            return invoice.nodeId;
        });
        return invoiceIDsArray;
    };

    const selectedTenantId = useAtom(SelectedTenantIdAtom);
    const view = createInvoiceView(selectedTenantId);

    const { useView, pull } = useGraph(MAIN, EDITOR);

    const invoiceIDs = useView(view, selectorInvoiceIDs);

    React.useEffect(() => {
        pull(view);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTenantId]);

    if (R.length(invoiceIDs) === 0)
        return (
            <div className="text-md flex h-[90%] w-full -translate-y-[5%] items-center justify-center text-slate-600/40">
                <div className="flex items-center">
                    <Icon name="hint" className="mr-1 h-6 w-6 fill-yellow-400/60" />
                    Mieter auswählen, um Rechnungen anzuzeigen.
                </div>
            </div>
        );

    return (
        <div className="mx-auto flex h-[90%] w-full flex-wrap gap-10 overflow-y-auto p-5">
            {invoiceIDs.map(invoiceId => (
                <InvoiceRow key={invoiceId} parentId={invoiceId} canDelete={canDelete} />
            ))}
        </div>
    );
};

export const InvoicesDisabled = ({ canSeeButton }) => (
    <div className="text-md flex h-[90%] w-full -translate-y-[5%] flex-col items-center justify-center gap-2 text-slate-600/40">
        <div className="flex items-center">
            <Icon name="hint" className="mr-1 h-6 w-6 fill-yellow-400/60" />
            Abrechnungen sind in ihrer Vesion der Software deaktiviert.
        </div>
        {canSeeButton && (
            <div
                className="cursor-pointer select-none text-black hover:scale-110 hover:text-yellow-400"
                onClick={() => {
                    InvoiceDataModalAtom.set(true);
                }}
            >
                Zählerdaten anzeigen
            </div>
        )}
    </div>
);

export const LandlordInvoiceList = () => <InvoiceList canDelete={true} />;
export const TenantInvoiceList = () => <InvoiceList canDelete={false} />;
