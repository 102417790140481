import React from 'react';
import { AddButton, BackButton } from './Buttons';
import { LogoutButton } from '../../components/Logout';

export const MobileScreen = ({
    title,
    children,
    className,
    onBack,
    onAdd,
    contentClasses,
    footer,
    menu,
}) => {
    return (
        <div
            className={`absolute left-0 top-0 box-border flex h-full w-full flex-col overflow-hidden bg-white ${className}`}
        >
            <>
                <div
                    className={`z-1 absolute top-0 flex h-12 w-full items-center justify-between bg-white p-3 text-emerald-700 transition duration-500 ${
                        title ? 'opacity-100' : 'opacity-0'
                    }`}
                >
                    <div className={`flex items-center text-2xl text-inherit`}>{title}</div>
                    {menu || <LogoutButton className="h-5 w-5 fill-slate-600" />}
                </div>
            </>
            <div
                className={`fade-in noscrollbar box-border h-full w-full flex-col overflow-y-scroll p-3 pt-12 ${contentClasses}`}
            >
                {children}
                <div className="h-[4.25rem] w-full bg-transparent" />
            </div>
            {footer && (
                <div className="absolute bottom-0 z-0 flex h-16 w-full items-center">{footer}</div>
            )}
            {onBack && <BackButton onClick={onBack} />}
            {onAdd && <AddButton onClick={onAdd} />}
        </div>
    );
};
