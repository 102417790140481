import { FieldsLeft, FieldsRight } from './shared';
import { useAtom } from '@gothub-team/got-atom';
import { tenantTariffDetailsPopup } from '../../../state/states';
import { useTariffCalculator } from '../../../hooks/useTariffEdit';
import { MobileModal } from '../../../Mobile/components/MobileModal';

export const TariffDetailsModalMobile = () => {
    const { tariffInfo, updateTariffInfo, tariffRes } = useTariffCalculator();
    const isOpen = useAtom(tenantTariffDetailsPopup);

    return (
        <MobileModal
            onClose={() => tenantTariffDetailsPopup.set(false)}
            title="Tarifdetails"
            isOpen={isOpen}
            scroll
        >
            {isOpen && (
                <div className="mx-auto flex h-full w-full justify-center gap-5 p-3">
                    <div className="flex w-full flex-col gap-5">
                        <FieldsLeft
                            tariffInfo={tariffInfo}
                            updateTariffInfo={updateTariffInfo}
                            tariffRes={tariffRes}
                        ></FieldsLeft>
                        <FieldsRight
                            tariffInfo={tariffInfo}
                            updateTariffInfo={updateTariffInfo}
                            tariffRes={tariffRes}
                        ></FieldsRight>
                        <div className="pb-16"></div>
                    </div>
                </div>
            )}
        </MobileModal>
    );
};
