import React from 'react';
import { useStatistics } from '../../hooks/useStatistics';

const ElementLeft = ({ title, children }) => (
    <div className="relative flex w-full items-center justify-between">
        <div className="absolute left-0 top-1/2 mr-2 -translate-y-[100%] text-slate-900">
            {title}
        </div>
        <div className="scaleX h-[1px] flex-1 bg-emerald-700" style={{ transformOrigin: 'left' }} />
        <div className="slide-in-left flex h-24 w-24 flex-col items-center justify-center rounded-full bg-emerald-700  p-2 text-xs text-white">
            {children}
        </div>
    </div>
);

const ElementRight = ({ title, children }) => (
    <div className="relative flex w-full items-center justify-between">
        <div
            style={{ transformOrigin: 'right' }}
            className="slide-in-right flex h-24 w-24 flex-col items-center justify-center rounded-full bg-emerald-700 p-2 text-xs text-white"
        >
            {children}
        </div>
        <div className="scaleX h-[1px] flex-1 bg-emerald-700" />
        <div className="absolute right-0 top-1/2 ml-2 -translate-y-[100%] text-slate-900">
            {title}
        </div>
    </div>
);

export const StatisticsSimple = () => {
    const statistics = useStatistics();

    return (
        <div className="fade-in flex h-full w-full flex-col justify-center gap-2 p-4">
            <div className="fade-in flex h-full w-full flex-col justify-center gap-2 p-4">
                <ElementRight title="Verbrauch Extern (Netzbezug)">
                    <div>{statistics.external} kWh</div>
                </ElementRight>
                <ElementLeft title="Solar Eigenverbrauch">
                    <div>{statistics.internal} kWh</div>
                </ElementLeft>
                <ElementRight title=" Gesamtverbrauch">
                    <div>{statistics.import} kWh</div>
                </ElementRight>
                <ElementLeft title="Einspeisung">
                    <div>{statistics.export} kWh</div>
                </ElementLeft>
            </div>
        </div>
    );
};
