import { useEffect } from 'react';
import { gotApi } from '../misc/got.config';

export const useAdminMode = () => {
    useEffect(() => {
        gotApi.setAdminMode(true);

        return () => {
            gotApi.setAdminMode(false);
        };
    }, []);
};
