import { useAtom } from '@gothub-team/got-atom';
import { useChartData } from './useChartData';
import { SelectedDayAtom, SelectedMonthAtom, SelectedYearAtom } from '../state/states';
import { useMeterStartValues } from './useMeterStartValue';

const getLast = data => {
    if (!data) return {};

    if (data.end && data.end.import != null) return data.end;

    const values = data.values || [];

    for (let i = values.length - 1; i >= 0; i -= 1) {
        const value = values[i];
        if (value && value.import != null) return value;
    }

    return {};
};

export const useStatistics = () => {
    const selectedMonth = useAtom(SelectedMonthAtom);
    const selectedYear = useAtom(SelectedYearAtom);
    const selectedDay = useAtom(SelectedDayAtom);

    const month =
        selectedMonth &&
        new Date(`${selectedYear}-${selectedMonth}`).toLocaleDateString('de-DE', {
            month: 'short',
        });

    const title = ['Verbrauch -', selectedDay, month, selectedYear].filter(Boolean).join(' ');

    const chartData = useChartData(selectedYear, selectedMonth, selectedDay);
    const startValues = useMeterStartValues();

    const start = chartData?.start || startValues || {};
    const end = getLast(chartData);

    const valImport = (end.import || 0) - (start.import || 0);
    const valInternal = (end.internal || 0) - (start.internal || 0);
    const valExport = (end.export || 0) - (start.export || 0);
    const valInternalUsage = (valInternal / (valInternal + valExport)) * 100;
    const valExternal = (valImport || 0) - (valInternal || 0);
    const valSustainability = (valInternal / valImport) * 100;

    return {
        title,
        import: (valImport || 0).toFixed(2),
        internal: (valInternal || 0).toFixed(2),
        export: (valExport || 0).toFixed(2),
        external: (valExternal || 0).toFixed(2),
        internalUsage: (valInternalUsage || 0).toFixed(1),
        sustainability: (valSustainability || 0).toFixed(1),
    };
};
