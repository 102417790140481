import React from 'react';
import AppDesktop from './AppDesktop';
import AppMobile from './AppMobile';
import { isMobile } from './misc/util';
import { useToggleDebugMode } from './state/debugMode';

const App = () => {
    const mobile = isMobile();
    useToggleDebugMode();

    if (mobile) {
        return <AppMobile />;
    }

    return <AppDesktop />;
};

export default App;
