import React from 'react';
import * as R from 'ramda';
import { Doughnut } from 'react-chartjs-2';
import { useDoughnutData } from '../../hooks/useDoughnutData';
import Icon from '../../components/Icon';

export const MobileDoughnut = () => {
    const { amountNet, amountInternal } = useDoughnutData();
    const donutOptions = {
        aspectRatio: 1,
        offset: 1,
        cutout: '85%',
        radius: '100%',
        plugins: {
            legend: {
                display: false,
            },
        },
    };
    const donutData = {
        labels: ['Netzbezug', 'Eigenverbrauch'],
        datasets: [
            {
                label: 'Anteil Eigenverbrauch zu Netzbezug',
                data: [amountNet, amountInternal],
                backgroundColor: ['#047857', '#fbbf24'],
                hoverOffset: 4,
                borderRadius: 100,
            },
        ],
    };
    return (
        <div className="fade-in flex h-[calc(100%-7rem)] w-full flex-col justify-center gap-2 p-4">
            <div className="doughnut-container-mobile relative">
                <Doughnut data={donutData} options={donutOptions} />
                <div className="inner-text-mobile fade-in">
                    <p className="title-mobile">Netzbezug</p>
                    <p className="info-mobile">{R.replace('.', ',', amountNet.toFixed(2))} kWh</p>
                    <p className="title-critical-mobile">Eigenverbrauch</p>
                    <p className="info-critical-mobile">
                        {R.replace('.', ',', amountInternal.toFixed(2))} kWh
                    </p>
                </div>
                <Icon
                    name="sun"
                    className="scale-in-x-y absolute left-0 top-0 h-10 w-10 fill-amber-400"
                />
                <Icon
                    name="lightbulb"
                    className="scale-in-x-y absolute bottom-0 right-0 h-10 w-10 fill-emerald-700"
                />
            </div>
        </div>
    );
};
