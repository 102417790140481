import React from 'react';
import { SelectedTenantIdAtom } from '../state/states';
import { useGraph } from '../misc/got.config';
import { EDITOR, MAIN } from '../misc/consts';
import { useAtom } from '@gothub-team/got-atom';

export default function TenantRow({ parentId }) {
    const { useNode } = useGraph(MAIN, EDITOR);
    const tenant = useNode(parentId);
    const { nameT } = tenant || {};
    const isRowIsSelected = parentId === useAtom(SelectedTenantIdAtom) ? 'bg-yellow-200' : '';

    return (
        <div className="flex justify-center">
            <div
                className={`item-center text-md flex w-full cursor-pointer rounded-lg border p-2 text-neutral-400 transition duration-100  ${isRowIsSelected} ${
                    isRowIsSelected ? '' : 'hover:bg-yellow-100'
                }`}
                onClick={() => {
                    SelectedTenantIdAtom.set(parentId);
                }}
            >
                <div className="flex-1 text-slate-900">{nameT}</div>
            </div>
        </div>
    );
}
