import React from 'react';
import {
    SelectedTenantIdAtom,
    tenantPesonalInfoPopup,
    meterSelectionPopup,
    ZevArrayAtom,
    zevSelectionPopup,
} from '../state/states';
import Icon from '../components/Icon';
import { useAtom } from '@gothub-team/got-atom';

export default function NavMenuT() {
    const zevArray = useAtom(ZevArrayAtom);

    return (
        <div className="flex h-[90%] w-full flex-col items-center justify-between">
            <div className="flex flex-col gap-3">
                <MenuEntry
                    title="Persönliche Daten"
                    onClick={() => tenantPesonalInfoPopup.set(true)}
                />
                <MenuEntry
                    title="Zählerübersicht"
                    onClick={() => {
                        SelectedTenantIdAtom.set(undefined);
                        meterSelectionPopup.set(true);
                    }}
                />
                <MenuEntry
                    title="ZEV-Übersicht"
                    onClick={() => {
                        zevSelectionPopup.set(true);
                    }}
                    hide={!zevArray || zevArray.length <= 1}
                />
            </div>
            <div className="mb-3 flex w-full justify-center">
                <Icon name="logo" />
            </div>
        </div>
    );
}

const MenuEntry = ({ title, onClick }) => (
    <div
        className="w-full cursor-pointer rounded-md px-2 py-1 text-center text-slate-600 transition hover:bg-yellow-200 "
        onClick={onClick}
    >
        {title}
    </div>
);
