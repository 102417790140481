import React from 'react';
import { MobileModal } from '../../components/MobileModal';
import { landlordEditPopup } from '../../../state/states';
import { useAtom } from '@gothub-team/got-atom';
import { useEditLandlord } from '../../../hooks/useEditLandlord';
import { Input } from '../../../components/Input';

export const EditLandlordModalMobile = () => {
    const llEditPopup = useAtom(landlordEditPopup);

    const { landLord, update, onSave, onClose, loggedInLLID } = useEditLandlord();
    const {
        name,
        adress,
        tel,
        bank,
        iBAN,
        bIC,
        amtsgericht,
        geschäftsführer,
        taxNumber,
        pLZ,
        city,
    } = landLord || {};

    return (
        <MobileModal
            onClose={onClose}
            onSave={onSave}
            title="Persönliche Daten"
            isOpen={llEditPopup}
            scroll
        >
            {llEditPopup && (
                <>
                    <div className="flex w-full flex-col gap-5">
                        <Input
                            label={'Name'}
                            type={'text'}
                            value={name}
                            onChange={value => update({ id: loggedInLLID, name: value })}
                        />
                        <Input
                            label={'Adresse'}
                            type={'text'}
                            value={adress}
                            onChange={value => update({ id: loggedInLLID, adress: value })}
                        />
                        <Input
                            label={'PLZ'}
                            type={'text'}
                            value={pLZ}
                            onChange={value => update({ id: loggedInLLID, pLZ: value })}
                        />
                        <Input
                            label={'Stadt'}
                            type={'text'}
                            value={city}
                            onChange={value => update({ id: loggedInLLID, city: value })}
                        />
                        <Input
                            label={'Telefonnummer'}
                            type={'text'}
                            value={tel}
                            onChange={value => update({ id: loggedInLLID, tel: value })}
                        />
                        <Input
                            label={'Name der Bank'}
                            type={'text'}
                            value={bank}
                            onChange={value => update({ id: loggedInLLID, bank: value })}
                        />
                        <Input
                            label={'IBAN'}
                            type={'text'}
                            value={iBAN}
                            onChange={value => update({ id: loggedInLLID, iBAN: value })}
                        />
                        <Input
                            label={'BIC'}
                            type={'text'}
                            value={bIC}
                            onChange={value => update({ id: loggedInLLID, bIC: value })}
                        />
                        <Input
                            label={'Amtsgericht'}
                            type={'text'}
                            value={amtsgericht}
                            onChange={value => update({ id: loggedInLLID, amtsgericht: value })}
                        />
                        <Input
                            label={'Geschäftsführer'}
                            type={'text'}
                            value={geschäftsführer}
                            onChange={value => update({ id: loggedInLLID, geschäftsführer: value })}
                        />
                        <Input
                            label={'Steuernummer'}
                            type={'text'}
                            value={taxNumber}
                            onChange={value => update({ id: loggedInLLID, taxNumber: value })}
                        />
                        <div className="pb-16"></div>
                    </div>
                </>
            )}
        </MobileModal>
    );
};
