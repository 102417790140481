import { createGraph } from './got.config';
import { METER_DATA_EDITOR, METER_DATA_MAIN } from './herbenergy.api';

const downloadFromURL = (url, filename) => {
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};

const parse = value => {
    if (value === null || value === undefined) return '';
    return Number(value).toFixed(3);
};

export const exportCSV = (nodeId, name, interval) => {
    const { node } = createGraph(METER_DATA_MAIN, METER_DATA_EDITOR);
    const values = node(nodeId)?.values || [];

    let csv = `Timestamp,Import,Export,Intern\n`;

    for (let i = 0; i < values.length; i += interval) {
        const value = values[i];

        if (!value) continue;

        csv += `${value.timestamp || ''},${parse(value.import)},${parse(value.export)},${parse(
            value.internal,
        )}\n`;
    }

    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    downloadFromURL(url, `${name}.csv`);
};
