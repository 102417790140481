import React from 'react';
import * as R from 'ramda';
import { SelectedTenantIdAtom, deleteMenuAtom, selectedZEVID } from '../../state/states';
import { EDITOR, MAIN, METER_INVOICE_EDGE } from '../../misc/consts';
import { useGraph } from '../../misc/got.config';
import { useAtom } from '@gothub-team/got-atom';
import Icon from '../../components/Icon';

import { useSpring, animated } from 'react-spring';

import { Portal } from '@headlessui/react';
import { openInNewTab } from '../../misc/util';
import { ClickAnimator } from '../components/ClickAnimator';
import { Chaotic3dLogoSpinner } from '../components/Chaotic3dLogoSpinner';
import { Backdrop } from '../components/Backdrop';

const createInvoiceView = tenantID =>
    tenantID
        ? {
              [tenantID]: {
                  as: 'tenant',
                  include: {
                      node: true,
                  },
                  edges: {
                      [METER_INVOICE_EDGE]: {
                          as: 'invoices',
                          include: {
                              files: true,
                              edges: true,
                              metadata: true,
                              node: true,
                          },
                      },
                  },
              },
          }
        : {};

export const Invoices = ({ tenantView }) => {
    const sortInvoicesByDate = arr => {
        return arr.sort((a, b) =>
            a.node.invoice?.invoiceDate > b.node.invoice?.invoiceDate ? 1 : -1,
        );
    };

    const selectorInvoiceIDs = viewRes => {
        const invoicesObj = R.pathOr({}, ['tenant', 'invoices'], viewRes);
        const invoicesArray = R.values(invoicesObj);
        const sortedInvoicesArray = sortInvoicesByDate(invoicesArray);
        const invoiceIdsArray = sortedInvoicesArray.map(invoice => {
            return invoice.nodeId;
        });
        return invoiceIdsArray;
    };

    const selectedTenantId = useAtom(SelectedTenantIdAtom);
    const view = createInvoiceView(selectedTenantId);
    const { useView, pull } = useGraph(MAIN, EDITOR);
    const invoiceIds = useView(view, selectorInvoiceIDs);
    const [loading, setLoading] = React.useState(false);
    const asyncPull = async () => {
        setLoading(true);
        await pull(view);
        setLoading(false);
    };

    React.useEffect(() => {
        asyncPull();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTenantId]);

    if (loading) return <Chaotic3dLogoSpinner />;

    return <InvoicesList invoiceIds={invoiceIds} tenantView={tenantView} />;
};

export const InvoicesDisabled = () => (
    <div className="fade-in flex h-full w-4/5 flex-col items-center justify-center text-center">
        <Icon name="hint" className="mb-2 h-10 w-10 fill-yellow-400/60" />
        <div className="text-slate-600/40">
            Abrechnungen sind in ihrer Vesion der Software deaktiviert.
        </div>
    </div>
);

const InvoicesList = ({ invoiceIds, tenantView }) => {
    const tenantId = useAtom(SelectedTenantIdAtom);
    const zevId = useAtom(selectedZEVID);
    const isZEVSelected = tenantId === zevId;
    if (isZEVSelected) {
        return (
            <div className="fade-in flex h-full w-4/5 flex-col items-center justify-center text-center">
                <Icon name="hint" className="mb-2 h-10 w-10 fill-yellow-400/60" />
                <div className="text-slate-600/40">
                    ZEV ist ausgewählt. Wählen Sie einen Zähler, um die zugehörigen Rechnungen
                    anzuzeigen.
                </div>
            </div>
        );
    }
    if (R.length(invoiceIds) === 0) {
        return (
            <div className="fade-in flex h-full w-full flex-col items-center justify-center">
                <Icon name="hint" className="mb-2 h-10 w-10 fill-yellow-400/60" />
                <div className="text-slate-600/40">Keine Rechnungen vorhanden.</div>
            </div>
        );
    }
    return (
        <div className="fade-in flex w-full flex-wrap p-2">
            {invoiceIds.map(invoiceId => (
                <InvoiceElement key={invoiceId} invoiceId={invoiceId} tenantView={tenantView} />
            ))}
        </div>
    );
};

const InvoiceElement = ({ invoiceId, tenantView }) => {
    const { useNode, useFiles, remove, push } = useGraph(MAIN, EDITOR);
    const invoiceN = useNode(invoiceId);
    const { invoice, sums, paid } = invoiceN || {};
    const { gross } = sums || {};
    const { invoiceNumber, invoiceDate } = invoice || {};
    const url = useFiles(invoiceId, R.path(['pdf', 'url']));
    const [isOpen, setIsOpen] = React.useState(false);
    return (
        <div key={invoiceId} className="flex w-1/2 flex-col">
            <InvoiceTile
                url={url}
                invoiceNumber={invoiceNumber}
                invoiceDate={invoiceDate}
                gross={gross}
                paid={paid}
                setIsOpen={setIsOpen}
                tenantView={tenantView}
            />
            <DeleteInvoiceMenu
                setIsOpen={setIsOpen}
                isOpen={isOpen}
                invoiceNumber={invoiceNumber}
                invoiceN={invoiceN}
                remove={remove}
                push={push}
            />
            <Portal>
                <Backdrop onClick={() => setIsOpen(false)} toggle={isOpen} />
            </Portal>
        </div>
    );
};

const InvoiceTile = ({ url, invoiceNumber, invoiceDate, gross, paid, setIsOpen, tenantView }) => {
    return (
        <ClickAnimator>
            <div
                onClick={() => openInNewTab(url)}
                style={{ textDecoration: 'none' }}
                className="relative m-2 flex h-full w-[calc(100%-1rem)] flex-col gap-3 overflow-hidden rounded-xl bg-white p-2 px-3 shadow-[0_0.25rem_0.5rem_rgba(0,0,0,0.1)]"
            >
                <div className="font-bold text-emerald-700">Nr. {invoiceNumber}</div>
                <p className="text-black">{invoiceDate}</p>
                <p className="text-black">{gross} CHF</p>
                <div
                    className={`${
                        paid ? 'font-medium text-emerald-700' : 'text-neutral-400'
                    } flex items-center`}
                >
                    {paid && <Icon name="check-solid" className="mr-1 h-4 w-4 fill-emerald-700" />}
                    {paid ? 'Bezahlt' : 'Ausstehend'}
                </div>
            </div>
            {!tenantView && (
                <div
                    onClick={e => {
                        e.preventDefault();
                        setIsOpen(true);
                        deleteMenuAtom.set(true);
                    }}
                    className="absolute bottom-[1.125rem] right-[1.125rem] z-10 flex h-5 w-5 items-center justify-center fill-slate-500/70"
                >
                    <Icon name="trash-can" className="h-4 w-4" />
                </div>
            )}
        </ClickAnimator>
    );
};

const DeleteInvoiceMenu = ({ setIsOpen, isOpen, invoiceNumber, invoiceN, remove, push }) => {
    const menuSpring = useSpring({
        opacity: isOpen ? 1 : 0,
        transform: isOpen ? 'scale(1)' : 'scale(0.25)',
    });
    return (
        <Portal>
            <animated.div
                style={menuSpring}
                className="fixed left-4 top-[calc(50%-4.5rem)] z-20 flex h-36 w-[calc(100%-2rem)] flex-col items-center justify-center rounded-2xl bg-emerald-700 p-4 shadow-[0_0.5rem_1rem_rgba(0,0,0,0.25)] backdrop-blur-sm"
            >
                <div className="mb-8 text-xl text-white">
                    Rechnung <span className="font-semibold">Nr. {invoiceNumber}</span> wirklich
                    löschen?
                </div>
                <div className="flex w-full items-center justify-evenly">
                    <div
                        onClick={() => {
                            setIsOpen(false);
                            deleteMenuAtom.set(false);
                            remove(METER_INVOICE_EDGE)(SelectedTenantIdAtom.get())(invoiceN);
                            push();
                        }}
                        className="text-xl font-semibold text-white"
                    >
                        Löschen
                    </div>
                    <div
                        onClick={() => {
                            setIsOpen(false);
                            deleteMenuAtom.set(false);
                        }}
                        className="text-xl text-white"
                    >
                        Abbrechen
                    </div>
                </div>
            </animated.div>
        </Portal>
    );
};
