import * as R from 'ramda';
import Icon from '../../../components/Icon';
import { floor } from '../../../misc/util';
import { useState } from 'react';

const PropInputField = ({
    label = '',
    type = 'text',
    defaultVal = '',
    propName,
    obj,
    setObj,
    onBlur,
    onFocus,
    onChange,
    value,
}) => {
    const localValue = R.propOr(defaultVal, propName, obj);
    const [isFocused, setIsFocused] = useState(false);
    const handleOnBlur = e => {
        onBlur && onBlur(e);
        setIsFocused(false);
    };
    const handleOnFocus = e => {
        onFocus && onFocus(e);
        setIsFocused(true);
    };
    return (
        <div className="flex flex-row items-center gap-2">
            <div className="relative mb-8 inline-block w-full">
                <input
                    type={type}
                    className="box-border w-full border-none py-0 pl-[0.1rem] pr-3 text-base text-black outline-none transition-all duration-200 ease-out placeholder:transition-all placeholder:duration-200 placeholder:ease-out"
                    placeholder={label}
                    value={value || localValue}
                    onChange={
                        onChange
                            ? e => onChange(e)
                            : e => setObj(R.assoc(propName, e.target.value, obj))
                    }
                    onBlur={handleOnBlur}
                    onFocus={handleOnFocus}
                />
                <div
                    className={`absolute -bottom-1 left-0 h-[2px] w-full overflow-hidden rounded-[2px] bg-slate-600 after:absolute after:left-0 after:block after:h-full after:w-full after:bg-emerald-600 after:transition-all after:duration-200 after:ease-out after:content-[""]
                        ${isFocused ? 'after:scale-100' : 'after:scale-0'}
                    `}
                />
                {value && (
                    <div
                        className={`animate-fade-scale-in absolute -bottom-6 left-0 rounded py-[0.1rem] pl-1 pr-4 text-[0.6rem] font-medium text-white transition-all duration-200 ease-out
                            ${isFocused ? 'bg-emerald-600' : 'bg-slate-600'}
                            ${value || localValue ? 'scale-100' : 'scale-0'}
                        `}
                        style={{
                            clipPath:
                                'polygon(0% 0%, 100% 0, 100% 0%, calc(100% - 12px) 100%, 0% 100%)',
                            transformOrigin: 'left',
                        }}
                    >
                        {label}
                    </div>
                )}
            </div>
        </div>
    );
};

const ListInputFied = ({ label = '', propName, obj, setObj }) => {
    const values = R.propOr([], propName, obj);

    return (
        <div className="flex flex-col gap-2 pr-2">
            <div className="flex flex-row justify-between">
                <div className="">{label}</div>
                <button
                    className="flex h-6 w-6 cursor-pointer items-center justify-center border-none bg-transparent p-0"
                    type="button"
                    onClick={e => {
                        setObj(R.assoc(propName, [...values, { name: '', value: 0 }], obj));
                    }}
                >
                    <Icon className="h-6 w-6" name="plus" />
                </button>
            </div>
            {values.map((value, index) => (
                <div className="flex flex-row gap-4">
                    {/* <input
                        className="flex-[4] rounded-lg border-none px-1 py-2 shadow-[0_0_0_1px_#e5e7eb] outline-none focus:shadow-[0_0_0_2px_rgb(234,179,8)]"
                        placeholder={'Name'}
                        value={value.name}
                        onChange={e => {
                            const newValue = R.assoc('name', e.target.value, value);
                            const newValues = R.assoc(index, newValue, values);
                            setObj(R.assoc(propName, newValues, obj));
                        }}
                        type={'text'}
                    /> */}
                    <PropInputField
                        label={'Name'}
                        value={value.name}
                        onChange={e => {
                            const newValue = R.assoc('name', e.target.value, value);
                            const newValues = R.assoc(index, newValue, values);
                            setObj(R.assoc(propName, newValues, obj));
                        }}
                        type={'text'}
                    />
                    {/* <input
                        className="max-w-[6rem] flex-1 rounded-lg border-none px-1 py-2 text-right shadow-[0_0_0_1px_#e5e7eb] outline-none placeholder:text-right focus:shadow-[0_0_0_2px_rgb(234,179,8)]"
                        placeholder={'Wert'}
                        value={value.value}
                        onChange={e => {
                            const newValue = R.assoc('value', e.target.value, value);
                            const newValues = R.assoc(index, newValue, values);
                            setObj(R.assoc(propName, newValues, obj));
                        }}
                        type={'text'}
                    /> */}
                    <PropInputField
                        label={'Wert'}
                        value={value.value}
                        onChange={e => {
                            const newValue = R.assoc('value', e.target.value, value);
                            const newValues = R.assoc(index, newValue, values);
                            setObj(R.assoc(propName, newValues, obj));
                        }}
                        type={'text'}
                    />
                    <button
                        type="button"
                        className="flex h-10 w-6 cursor-pointer items-center justify-center border-none bg-transparent p-0"
                        onClick={() => {
                            const newValues = R.remove(index, 1, values);
                            setObj(R.assoc(propName, newValues, obj));
                        }}
                    >
                        <Icon className="h-6 w-6" name="trash-can" />
                    </button>
                </div>
            ))}
        </div>
    );
};

export const FieldsLeft = ({ tariffInfo, tariffRes, updateTariffInfo }) => (
    <>
        <h2 className="text-lg font-bold">Anlagendaten Allgemein</h2>
        <PropInputField
            label={'DC Anlagenleistung (kW)'}
            type={'number'}
            defaultVal={tariffInfo.installedPowerDc}
            propName={'installedPowerDc'}
            obj={tariffInfo}
            setObj={updateTariffInfo}
        />
        <PropInputField
            label={'AC Anlagenleistung (kW)'}
            type={'number'}
            defaultVal={tariffInfo.installedPowerAc}
            propName={'installedPowerAC'}
            obj={tariffInfo}
            setObj={updateTariffInfo}
        />
        <ListInputFied
            label={'Anlagekosten (CHF)'}
            propName={'fixedInvestment'}
            obj={tariffInfo}
            setObj={updateTariffInfo}
        ></ListInputFied>
        <ListInputFied
            label={'Subventionen (CHF)'}
            propName={'subsidy'}
            obj={tariffInfo}
            setObj={updateTariffInfo}
        ></ListInputFied>
        <PropInputField
            label={'Alter der Anlage'}
            type={'number'}
            defaultVal={tariffInfo.age}
            propName={'age'}
            obj={tariffInfo}
            setObj={updateTariffInfo}
        />

        <h2 className="text-lg font-bold">Verbrauch</h2>
        <PropInputField
            label={'Gesamtstromverbrauch (kWh/Jahr)'}
            type={'number'}
            defaultVal={tariffInfo.expectedTotalUsage}
            propName={'expectedTotalUsage'}
            obj={tariffInfo}
            setObj={updateTariffInfo}
        />
        <PropInputField
            label={'Zu erwartende Stromproduktion (kWh/Jahr)'}
            type={'number'}
            defaultVal={tariffInfo.expectedPower}
            propName={'expectedPower'}
            obj={tariffInfo}
            setObj={updateTariffInfo}
        />
        <PropInputField
            label={'Degradation (%/Jahr)'}
            type={'number'}
            defaultVal={tariffInfo.degradation}
            propName={'degradation'}
            obj={tariffInfo}
            setObj={updateTariffInfo}
        />
        <PropInputField
            label={'Eigenverbrauchsquote (%)'}
            type={'number'}
            defaultVal={tariffInfo.usageEfficiency}
            propName={'usageEfficiency'}
            obj={tariffInfo}
            setObj={updateTariffInfo}
        />
        <div className="flex flex-row justify-between">
            <div>Erwarteter Eigenverbrauch</div>
            <div className="text-right">{`${
                tariffRes?.expectedInteralUsage?.toFixed(2) || 'NaN'
            } kWh`}</div>
        </div>
        <div className="flex flex-row justify-between">
            <div>Netzeinspeisung</div>
            <div className="text-right">{`${
                tariffRes?.expectedSold?.toFixed(2) || 'NaN'
            } kWh`}</div>
        </div>
        <div className="flex flex-row justify-between">
            <div>Netzbezug</div>
            <div className="text-right">{`${tariffRes?.expectedBuy?.toFixed(2) || 'NaN'} kWh`}</div>
        </div>
        <h2 className="text-lg font-bold">Betriebskosten</h2>
        <ListInputFied
            label={'Betriebskosten (CHF)'}
            propName={'runningCosts'}
            obj={tariffInfo}
            setObj={updateTariffInfo}
        ></ListInputFied>
        <ListInputFied
            label={'Betriebskosten pro kWh (CHF/kWh)'}
            propName={'runningCostskWh'}
            obj={tariffInfo}
            setObj={updateTariffInfo}
        ></ListInputFied>
    </>
);

export const FieldsRight = ({ tariffInfo, tariffRes, updateTariffInfo }) => {
    const externalTariff = tariffRes?.externalTariff
        ? floor(2, tariffRes.externalTariff * 100).toFixed(2)
        : 'NaN';
    const internalTariffCosts = tariffRes?.internalTariffCosts
        ? floor(2, tariffRes.internalTariffCosts * 100).toFixed(2)
        : 'NaN';
    const internalTariff = tariffRes?.internalTariff
        ? floor(2, tariffRes.internalTariff * 100).toFixed(2)
        : 'NaN';

    return (
        <>
            <h2 className="text-lg font-bold">Zinsen</h2>
            <PropInputField
                label={'Armortisationszeitraum (Jahre)'}
                type={'number'}
                defaultVal={tariffInfo.maxInterestYears}
                propName={'maxInterestYears'}
                obj={tariffInfo}
                setObj={updateTariffInfo}
            />
            <PropInputField
                label={'Referenzzinssatz (%)'}
                type={'number'}
                defaultVal={tariffInfo.currentReferenceInterestRate}
                propName={'currentReferenceInterestRate'}
                obj={tariffInfo}
                setObj={updateTariffInfo}
            />
            <PropInputField
                label={'Risikozuschlag (%)'}
                type={'number'}
                defaultVal={tariffInfo.riskFreeInterestRate}
                propName={'riskFreeInterestRate'}
                obj={tariffInfo}
                setObj={updateTariffInfo}
            />
            <div className="flex flex-row justify-between">
                <div>Zinssatz</div>
                <div className="text-right">{`${(
                    (tariffRes?.actualInterestRate || 0) * 100
                ).toFixed(2)} %`}</div>
            </div>
            <div className="flex flex-row justify-between">
                <div>Kapitalkostenrendite</div>
                <div className="text-right">{`${(
                    (tariffRes?.maximumInterestRate || 0) * 100
                ).toFixed(2)} %`}</div>
            </div>
            <h2 className="text-lg font-bold">Strompreise</h2>
            <PropInputField
                label={'Grundpreis (CHF)'}
                type={'number'}
                defaultVal={tariffInfo.baseCost}
                propName={'baseCost'}
                obj={tariffInfo}
                setObj={updateTariffInfo}
            />
            <PropInputField
                label={'Energiepreis (CHF/kWh)'}
                type={'number'}
                defaultVal={tariffInfo.buyTariff}
                propName={'buyTariff'}
                obj={tariffInfo}
                setObj={updateTariffInfo}
            />
            <PropInputField
                label={'Arbeitspreis (CHF/kWh)'}
                type={'number'}
                defaultVal={tariffInfo.workingTariff}
                propName={'workingTariff'}
                obj={tariffInfo}
                setObj={updateTariffInfo}
            />
            <ListInputFied
                label={'zusätzliche kosten pro kWh (CHF/kWh)'}
                propName={'additionalCostsPerKWh'}
                obj={tariffInfo}
                setObj={updateTariffInfo}
            ></ListInputFied>
            <PropInputField
                label={'Einspeisevergütung (CHF/kWh)'}
                type={'number'}
                defaultVal={tariffInfo.sellTariff}
                propName={'sellTariff'}
                obj={tariffInfo}
                setObj={updateTariffInfo}
            />
            {/* <PropInputField
            label={'regionalSellTariff'}
            type={'number'}
            defaultVal={tariffInfo.regionalSellTariff}
            propName={'regionalSellTariff'}
            obj={tariffInfo}
            setObj={updateTariffInfo}
        /> */}

            <h2 className="text-lg font-bold">Tarife</h2>
            <div className="flex flex-row justify-between">
                <div>Standard Strompreis</div>
                <div className="text-right">{`${externalTariff} Rp/kWh`}</div>
            </div>
            <div className="flex flex-row justify-between">
                <div>Interne Stromgestehungskosten</div>
                <div className="text-right">{`${internalTariffCosts} Rp/kWh`}</div>
            </div>
            <div className="flex flex-row justify-between">
                <div>Interner Strompreis</div>
                <div className="text-right">{`${internalTariff} Rp/kWh`}</div>
            </div>
        </>
    );
};
