import React from 'react';
import {
    landlordEditPopup,
    tariffEditPopupAtom,
    SelectedTenantIdAtom,
    tenantEditPopup,
    tenantRightsPopup,
    selectedZEVID,
    zevSelectionPopup,
    ZevArrayAtom,
    screenNav,
} from '../state/states';
import { useAtom } from '@gothub-team/got-atom';
import Icon from '../components/Icon';
import { FEATURE_MODE, useFeatureMode } from '../hooks/useFeatureMode';
import classNames from 'classnames';

export default function NavMenu() {
    const selectedTenantId = useAtom(SelectedTenantIdAtom);
    const selectedZevId = useAtom(selectedZEVID);
    const zevArray = useAtom(ZevArrayAtom);

    const isPremium = useFeatureMode() === FEATURE_MODE.FULL;
    return (
        <div className="flex h-[90%] w-full flex-col items-center justify-between">
            <div className="flex flex-col gap-3">
                <MenuEntry
                    title="ZEV-Übersicht"
                    onClick={() => {
                        zevSelectionPopup.set(true);
                    }}
                    hide={!zevArray || zevArray.length <= 1}
                />
                <MenuEntry
                    title="Tarif bearbeiten"
                    onClick={() => tariffEditPopupAtom.set(true)}
                    hide={selectedTenantId !== selectedZevId}
                    disabled={!isPremium}
                />
                <MenuEntry
                    title="Persönliche Daten"
                    onClick={() => landlordEditPopup.set(true)}
                    hide={selectedTenantId !== selectedZevId}
                />
                <MenuEntry
                    title="Mieter bearbeiten"
                    onClick={() => tenantEditPopup.set(true)}
                    hide={!selectedTenantId || selectedTenantId === selectedZevId}
                />
                <MenuEntry
                    title="Rechte vergeben"
                    onClick={() => tenantRightsPopup.set(true)}
                    hide={!selectedTenantId || selectedTenantId === selectedZevId}
                />
                <MenuEntry title="Admin Ansicht" onClick={() => screenNav.set('admin')} hide />
            </div>
            <div className="mb-3 flex w-full justify-center">
                <Icon name="logo" />
            </div>
        </div>
    );
}

const MenuEntry = ({ title, onClick, hide, disabled }) => {
    if (hide) return null;
    return (
        <button
            className="flex w-full cursor-pointer flex-row flex-wrap items-center justify-center gap-2 rounded-md border-none bg-transparent py-1 text-center text-slate-600 transition hover:bg-yellow-200"
            onClick={onClick}
            disabled={disabled}
        >
            <div className={classNames('text-md whitespace-nowrap', disabled && 'opacity-50')}>
                {title}
            </div>
            {disabled && (
                <div className="rounded-full border border-solid border-red-700 bg-transparent px-1 py-[0.125rem] text-[0.6rem] text-red-700">
                    Premium
                </div>
            )}
        </button>
    );
};
