import { EDITOR, MAIN, ZEV_METER_EDGE } from '../misc/consts';
import * as R from 'ramda';
import { useAtom } from '@gothub-team/got-atom';
import { searchInputAtom, selectedFilter, selectedZEVID } from '../state/states';
import { useEffect, useState } from 'react';
import { useGraph } from '../misc/got.config';

const createLandLordView = landLordID =>
    landLordID
        ? {
              [landLordID]: {
                  as: 'landlord',
                  include: {
                      node: true,
                  },
                  edges: {
                      [ZEV_METER_EDGE]: {
                          as: 'meters',
                          include: {
                              edges: true,
                              metadata: true,
                              node: true,
                          },
                      },
                  },
              },
          }
        : {};

const zevMatchesSearch = (zev, searchArray) => {
    for (let index = 0; index < searchArray.length; index++) {
        const searchWord = searchArray[index].toLowerCase();
        if (
            zev?.name?.toLowerCase().includes(searchWord) ||
            zev?.adress?.toLowerCase().includes(searchWord) ||
            zev?.pLZ?.toLowerCase().includes(searchWord) ||
            zev?.city?.toLowerCase().includes(searchWord)
        ) {
            return true;
        }
    }

    return false;
};

const meterMatchesSearch = (meter, searchArray) => {
    for (let index = 0; index < searchArray.length; index++) {
        const searchWord = searchArray[index].toLowerCase();
        if (
            meter.nameT?.toLowerCase().includes(searchWord) ||
            meter.pLZT?.toLowerCase().includes(searchWord) ||
            meter.cityT?.toLowerCase().includes(searchWord) ||
            meter.telT?.toLowerCase().includes(searchWord) ||
            meter.adressT?.toLowerCase().includes(searchWord)
        ) {
            return true;
        }
    }

    return false;
};

export const useZev = () => {
    const loggedInLLID = useAtom(selectedZEVID);
    const { useNode } = useGraph(MAIN, EDITOR);
    const zevNode = useNode(loggedInLLID);
    const searchInput = useAtom(searchInputAtom);
    const searchArray = searchInput.split(' ');

    if (zevMatchesSearch(zevNode, searchArray)) {
        return zevNode;
    }

    return undefined;
};

export const useMeters = () => {
    const sortVar = useAtom(selectedFilter);
    const sortMeters = arr => {
        if (sortVar === 'A-Z sortieren') {
            return arr.sort((a, b) => (a.node.nameT > b.node.nameT ? 1 : -1));
        } else if (sortVar === 'Z-A sortieren') {
            return arr.sort((a, b) => (a.node.nameT < b.node.nameT ? 1 : -1));
        } else if (sortVar === 'Verbrauch aufsteigend') {
            return arr.sort((a, b) => (a.node.rateWT > b.node.rateWT ? 1 : -1));
        } else if (sortVar === 'Verbrauch absteigend') {
            return arr.sort((a, b) => (a.node.rateWT < b.node.rateWT ? 1 : -1));
        }
        return arr;
    };

    const searchInput = useAtom(searchInputAtom);
    const searchArray = searchInput.split(' ');

    const selectorTenantIDs = viewRes => {
        const metersObj = R.pathOr({}, ['landlord', 'meters'], viewRes);
        const metersArray = R.values(metersObj);
        const sortedMetersArray = sortMeters(metersArray);
        const filteredMetersArray = sortedMetersArray.filter(meterBag =>
            meterMatchesSearch(meterBag.node, searchArray),
        );
        const meterIDsArray = filteredMetersArray.map(meter => {
            return meter.nodeId;
        });
        return meterIDsArray;
    };

    const loggedInLLID = useAtom(selectedZEVID);
    const view = createLandLordView(loggedInLLID);

    const { pull, useView } = useGraph(MAIN, EDITOR);
    const tenantIDs = useView(view, selectorTenantIDs);
    const [loading, setLoading] = useState(false);
    const asyncPull = async () => {
        setLoading(true);
        await pull(view);
        setLoading(false);
    };

    useEffect(() => {
        asyncPull();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedInLLID]);

    return { tenantIDs, loading };
};
