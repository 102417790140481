import React from 'react';
import { SelectedTenantIdAtom, selectedZEVID, zevSelectionPopup } from '../../state/states';
import { useAtom } from '@gothub-team/got-atom';
import { SelectionModals } from '../components/SelectionModals';
import TenantSelectionMobile from '../sharedScreenComponents/TenantSelectionMobile';
import ZevSelectionMobile from '../Landlord/ZevSelectionMobile';

export const SelectModals = () => {
    const tenantId = useAtom(SelectedTenantIdAtom);
    const zevId = useAtom(selectedZEVID);

    const components = [
        {
            title: 'ZEV wählen',
            transformation: !zevId ? 'translateX(0vw)' : 'translateX(-100vw)',
            component: <ZevSelectionMobile />,
        },
        {
            title: 'Zähler wählen',
            transformation: zevId && !tenantId ? 'translateX(0vw)' : 'translateX(-100vw)',
            component: <TenantSelectionMobile tenant />,
            onBack: () => {
                SelectedTenantIdAtom.set(undefined);
                selectedZEVID.set(undefined);
                zevSelectionPopup.set(true);
            },
        },
    ];
    return <SelectionModals backdropToggle={tenantId} components={components} />;
};
