import { tenantTariffDetailsPopup } from '../../../state/states';
import { floor } from '../../../misc/util';

const Field = ({ label = '', unit = '', value }) => {
    return (
        <div className="">
            <div className="flex flex-row justify-between gap-4">
                <div className="whitespace-nowrap">{label}</div>
                <div className="whitespace-nowrap text-right">{`${value} ${unit}`}</div>
            </div>
        </div>
    );
};

export const TenantInfo = ({ tenant }) => {
    if (!tenant) return null;

    const { nameT, adressT, object, cityT, pLZT } = tenant;

    return (
        <div className="flex flex-col gap-2">
            <h2 className="text-lg font-bold">Anschrift</h2>
            <Field label={'Name'} value={nameT ?? '-'} />
            <Field label={'Adresse'} value={adressT ?? '-'} />
            <Field label={'Wohnungsbeschreibung'} value={object ?? '-'} />
            <Field label={'Stadt'} value={cityT ?? '-'} />
            <Field label={'PLZ'} value={pLZT ?? '-'} />
        </div>
    );
};

export const TenantContact = ({ tenant }) => {
    if (!tenant) return null;

    const { telT, faxT, mailT } = tenant;

    return (
        <div className="flex flex-col gap-2">
            <h2 className="text-lg font-bold">Kontaktdaten</h2>
            <Field label={'Telefonnummer'} value={telT ?? '-'} />
            <Field label={'Fax'} value={faxT ?? '-'} />
            <Field label={'Mail'} value={mailT ?? '-'} />
        </div>
    );
};

export const TariffData = ({ tariffRes }) => {
    if (!tariffRes) return null;

    const externalTariff = tariffRes?.externalTariff
        ? floor(2, tariffRes.externalTariff * 100).toFixed(2)
        : 'NaN';
    const internalTariffCosts = tariffRes?.internalTariffCosts
        ? floor(2, tariffRes.internalTariffCosts * 100).toFixed(2)
        : 'NaN';
    const internalTariff = tariffRes?.internalTariff
        ? floor(2, tariffRes.internalTariff * 100).toFixed(2)
        : 'NaN';

    return (
        <div className="flex flex-col gap-2">
            <h2 className="text-lg font-bold">Tarifdaten</h2>
            <Field
                label={'Netzeinspeisung'}
                value={tariffRes?.expectedSold?.toFixed(2) || 'NaN'}
                unit={'kWh'}
            />
            <Field
                label={'Netzbezug'}
                value={tariffRes?.expectedBuy?.toFixed(2) || 'NaN'}
                unit={'kWh'}
            />
            <Field label={'Standard Strompreis'} value={externalTariff} unit={'Rp/kWh'} />
            <Field
                label={'Eigenstromgestehungskosten'}
                value={internalTariffCosts}
                unit={'Rp/kWh'}
            />
            <Field label={'Eigenstrompreis'} value={internalTariff} unit={'Rp/kWh'} />
            <div
                className="font-semi mx-auto cursor-pointer px-4 py-2 text-center text-xl text-slate-600 hover:text-yellow-400 focus:outline-none"
                onClick={() => {
                    tenantTariffDetailsPopup.set(true);
                }}
            >
                Tarifdetails
            </div>
        </div>
    );
};
