import React from 'react';
import { landlordInvoicePopup } from '../../../state/states';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Modal } from '../../../components/Modal';
import { useCreateInvoice } from '../../../hooks/useCreateInvoice';
import { Position } from './shared';
import { Input } from '../../../components/Input';

export const CreateInvoiceModal = () => {
    const {
        onSave,
        handleStartDateChange,
        handleEndDateChange,
        selectedStartDate,
        selectedEndDate,
        invoiceDataLoading,
        externalPosition,
        internalPosition,
        isLoading,
        setInvoiceNumberIF,
        invoiceError,
        nameT,
        invoiceNumberIF,
    } = useCreateInvoice();
    return (
        <Modal onClose={() => landlordInvoicePopup.set(false)} title={'Neue Rechnung für ' + nameT}>
            <form className="relative w-fit" onSubmit={e => e.preventDefault()}>
                <div className="mb-5">
                    <Input
                        label={'Rechnungsnummer'}
                        type={'text'}
                        value={invoiceNumberIF}
                        onChange={value => setInvoiceNumberIF(value)}
                    />
                </div>
                <div className="my-4 flex w-full items-center justify-between gap-10">
                    <div className="flex flex-col">
                        <div className="mb-1 text-xs font-medium text-slate-600">
                            Startdatum wählen:
                        </div>
                        <DatePicker
                            selected={selectedStartDate}
                            onChange={handleStartDateChange}
                            dateFormat="dd.MM.yyyy"
                            maxDate={new Date(new Date().getTime() - 1000 * 60 * 60 * 24)}
                            isClearable
                            placeholderText="Datum"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            className="rounded-md outline-none focus:border-yellow-400"
                        />
                    </div>
                    <div className="flex flex-col">
                        <div className="mb-1 text-xs font-medium text-slate-600">
                            Enddatum wählen:
                        </div>

                        <DatePicker
                            selected={selectedEndDate}
                            onChange={handleEndDateChange}
                            dateFormat="dd.MM.yyyy"
                            maxDate={new Date(new Date().getTime() - 1000 * 60 * 60 * 24)}
                            isClearable
                            placeholderText="Datum"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            className="rounded-md outline-none focus:border-yellow-400"
                        />
                    </div>
                </div>
                {invoiceDataLoading === 'pending' && (
                    <div className="w-full text-center">Lade Daten...</div>
                )}
                {invoiceDataLoading === 'loaded' && (
                    <div>
                        <Position position={externalPosition}></Position>
                        <Position position={internalPosition}></Position>
                    </div>
                )}
                <div className="edit-tenant-form-buttons mt-8">
                    {invoiceError && (
                        <div className="rounded-md border border-solid border-red-600 px-4 py-2 text-red-600">
                            {invoiceError}
                        </div>
                    )}
                    {!invoiceError && (
                        <button
                            type="button"
                            className={`hover:bg-yellow-400' cursor-pointer rounded-md border-none bg-transparent px-4 py-2 text-xl text-black`}
                            onClick={onSave}
                        >
                            PDF erstellen
                        </button>
                    )}
                </div>
                {isLoading && (
                    <div className="absolute inset-0 z-10 flex items-center justify-center bg-white">
                        <div className="">Erstelle Rechnung...</div>
                    </div>
                )}
            </form>
        </Modal>
    );
};
