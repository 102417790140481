export const Position = ({ position }) => {
    const { name, amount, unitPrice, totalPrice } = position;

    return (
        <div className="flex w-full flex-row items-center justify-between gap-2 text-xs md:gap-4 md:text-base">
            <div className="flex-[4] whitespace-nowrap">{name}</div>
            <div className="flex-1 whitespace-nowrap">{amount}</div>
            <div className="flex-1 whitespace-nowrap">{unitPrice}</div>
            <div className="flex-1 whitespace-nowrap">{totalPrice}</div>
        </div>
    );
};

export const Values = ({ name, start, end }) => {
    return (
        <div className="flex w-full flex-row items-center justify-between gap-2 text-xs md:gap-4 md:text-base">
            <div className="flex-[4] whitespace-nowrap">{name}</div>
            <div className="flex-1 whitespace-nowrap">{start}</div>
            <div className="flex-1 whitespace-nowrap">{end}</div>
        </div>
    );
};
