import { SelectedTenantIdAtom, selectedZEVID } from '../state/states';
import { gotApi } from './got.config';
import { sha3_224 as sha } from 'js-sha3';

const KEY = 'user-settings-he';

const getState = () => {
    const str = localStorage.getItem(KEY);
    if (str) {
        return JSON.parse(str);
    }

    return {};
};

const setState = state => {
    localStorage.setItem(KEY, JSON.stringify(state));
};

export const setSettings = settings => {
    const email = gotApi.getCurrentUser()?.email;

    if (email) {
        const hash = sha(email);
        const state = getState();
        const userSettings = state[hash] || settings;
        state[hash] = { ...userSettings, ...settings };
        setState(state);
    }
};

export const getSettings = () => {
    const email = gotApi.getCurrentUser()?.email;

    if (email) {
        const hash = sha(email);
        const state = getState();
        return state[hash] || {};
    }

    return {};
};

selectedZEVID.subscribe({
    next: zevId => {
        setSettings({ zevId });
    },
});

SelectedTenantIdAtom.subscribe({
    next: tenantId => {
        setSettings({ tenantId });
    },
});
