import { atom } from '@gothub-team/got-atom';
import * as R from 'ramda';

export const loadAtom = () => {
    const baseAtom = atom({ loading: {}, loaded: {}, promises: {} });

    const load = id => {
        baseAtom.set(R.assocPath(['loading', id], true));
    };

    const loaded = id => {
        baseAtom.set(
            R.compose(R.assocPath(['loaded', id], true), R.assocPath(['loading', id], false)),
        );
    };

    const isLoaded = id => !id || baseAtom.get().loading[id] || baseAtom.get().loaded[id];

    const setPromise = (id, promise) => {
        baseAtom.set(R.assocPath(['promises', id], promise));
    };

    const getPromise = id => baseAtom.get().promises[id];

    const loadFn = async (id, asyncFn, forceReload = false) => {
        if (!forceReload && isLoaded(id)) {
            return getPromise(id);
        }

        load(id);
        const promise = asyncFn();
        setPromise(id, promise);
        const res = await promise;
        loaded(id);

        return res;
    };

    const clear = () => baseAtom.set({ loading: {}, loaded: {} });

    return {
        ...baseAtom,
        load,
        loaded,
        isLoaded,
        setPromise,
        getPromise,
        loadFn,
        clear,
    };
};
