import { useState } from 'react';
import { useAuthActions } from './useAuthActions';

export const taskNames = {
    login: 'Anmeldung',
    register: 'Registrierung',
    activate: 'Aktivierung',
    forgotPassword: 'Passwort zurücksetzen',
};

export const useAuthscreenActions = onLoggedIn => {
    const [task, setTask] = useState({
        name: 'login',
        email: '',
        password: '',
        passwordConfirm: '',
        code: '',
    });

    const authActions = useAuthActions({
        onUserNotVerified: () => setTask({ name: 'activate', email: task.email }),
        // onPasswordResetRequired: () => setTask({ name: 'forgotPassword', email: task.email }),
    });

    const navLogin = async () => {
        setTask({ name: 'login', email: task.email, password: task.password });
    };

    const navForgotPassword = async () => {
        if (await authActions.resetPassword(task.email)) {
            setTask({ name: 'forgotPassword', email: task.email });
        }
    };

    const navRegister = () => {
        setTask({
            name: 'register',
            email: task.email,
            password: task.password,
            passwordConfirm: '',
        });
    };

    const login = async () => {
        if (await authActions.login(task.email, task.password)) {
            onLoggedIn(true);
            return true;
        }
        return false;
    };

    const register = async () => {
        if (await authActions.register(task.email, task.password, task.passwordConfirm)) {
            setTask({ name: 'activate', email: task.email, password: task.password });
            return true;
        }

        return false;
    };

    const forgotPasswordVerify = async () => {
        if (
            await authActions.resetPasswordVerify(
                task.email,
                task.code,
                task.password,
                task.passwordConfirm,
            )
        ) {
            if (await authActions.login(task.email, task.password)) {
                onLoggedIn(true);
                return true;
            }
        }

        return false;
    };

    const activate = async () => {
        if (await authActions.registerVerify(task.email, task.code)) {
            if (!task.password) {
                setTask({ name: 'login', email: task.email });
                return true;
            }
            if (await authActions.login(task.email, task.password)) {
                onLoggedIn(true);
                return true;
            }
        }

        return false;
    };

    const resendActivationCode = async () => authActions.registerVerifyResend(task.email);

    return {
        task,
        setTask,
        navLogin,
        navForgotPassword,
        navRegister,
        login,
        register,
        forgotPasswordVerify,
        activate,
        resendActivationCode,
    };
};
