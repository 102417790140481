import { LoadState as ChartLoadState } from '../hooks/useChartData';
import { LoggedIn, SelectedDayAtom, SelectedMonthAtom, SelectedYearAtom } from '../state/states';
import { gotApi, gotStore } from './got.config';

const setLoggedOut = () => {
    LoggedIn.set('loggedOut');
    gotApi.logout();
};

const clearSelectionStates = () => {
    const currentYearInt = new Date().getFullYear();
    SelectedYearAtom.set(currentYearInt);
    SelectedMonthAtom.set('');
    SelectedDayAtom.set('');
};

const clearLoadStates = () => {
    ChartLoadState.clear();
};

export const logout = () => {
    setLoggedOut();
    gotStore.clearAll();
    clearSelectionStates();
    clearLoadStates();
};
