import React from 'react';
import { useAtom } from '@gothub-team/got-atom';
import { SelectedTenantIdAtom, selectedZEVID } from '../state/states';
import { useGraph } from '../misc/got.config';
import { EDITOR, MAIN } from '../misc/consts';
import * as R from 'ramda';

const createTenantView = tenantID =>
    tenantID
        ? {
              [tenantID]: {
                  include: {
                      node: true,
                  },
              },
          }
        : {};

const createLandLordView = landLordID =>
    landLordID
        ? {
              [landLordID]: {
                  include: {
                      node: true,
                  },
              },
          }
        : {};

const selectTariffRes = R.propOr({}, 'tariffRes');

export const useInfoTenant = () => {
    const selectedTenantId = useAtom(SelectedTenantIdAtom);
    const view = createTenantView(selectedTenantId);

    const { pull, useNode } = useGraph(MAIN, EDITOR);
    const tenant = useNode(selectedTenantId);
    const loggedInLLID = useAtom(selectedZEVID);
    const viewLL = createLandLordView(loggedInLLID);
    // const landLord = useNode(loggedInLLID);

    React.useEffect(() => {
        pull(view);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTenantId]);

    React.useEffect(() => {
        pull(viewLL);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const tariffRes = useNode(loggedInLLID, selectTariffRes);
    return { tenant, tariffRes };
};
