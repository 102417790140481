import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './openweather-restyle.css';
import App from './App';
import { Provider } from 'react-redux';
import { reduxStore } from './misc/redux';
import { ErrorBoundary } from 'react-error-boundary';
import { Error } from './Error';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ErrorBoundary FallbackComponent={Error}>
            <Provider store={reduxStore}>
                <App />
            </Provider>
        </ErrorBoundary>
    </React.StrictMode>,
);
