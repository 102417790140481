import React from 'react';
import * as RA from 'ramda-adjunct';
import { useSpring, animated } from 'react-spring';
import { MobileScreen } from './MobileScreen';
import { Backdrop } from './Backdrop';

export const SelectionModals = ({ components, backdropToggle }) => (
    <>
        {RA.mapIndexed((comp, index) => (
            <Modal
                key={index}
                title={comp.title}
                component={comp.component}
                transformation={comp.transformation}
                onBack={comp.onBack}
            />
        ))(components)}
        <Backdrop toggle={!backdropToggle} />
    </>
);

const Modal = ({ transformation, title, component, onBack }) => {
    const slideSpring = useSpring({ transform: transformation });
    return (
        <animated.div
            style={slideSpring}
            className="fixed left-[5%] top-[5%] z-30 h-[90%] w-[90%] overflow-hidden rounded-3xl bg-white shadow-[0_1rem_1rem_rgba(0,0,0,0.4)]"
        >
            <MobileScreen title={title} onBack={onBack}>
                {component}
            </MobileScreen>
        </animated.div>
    );
};
