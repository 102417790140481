import { LogoutButton } from '../components/Logout';
import { loadUser } from '../misc/loadUser';
import { isMobile } from '../misc/util';
import { SelectedTenantIdAtom, selectedZEVID } from '../state/states';

export const LandLordNoZev = () => (
    <div className="relative flex h-screen w-screen flex-col items-center justify-center gap-6 px-4">
        <h1>Es scheint als hättest du keine Berechtigungen auf ein ZEV</h1>
        <div>
            Setze dein ZEV mit dieser Emailadresse als Verwalter auf oder kontaktiere den support
            unter info@herbenergy.ch
        </div>
        <button
            className="font-semi mx-auto cursor-pointer border-none bg-transparent px-4 py-2 text-center text-xl text-slate-600 hover:text-yellow-400 focus:outline-none"
            onClick={() => {
                selectedZEVID.set(null);
                SelectedTenantIdAtom.set(null);
                loadUser({ mobile: isMobile() });
            }}
        >
            Zurück zur Auswahl
        </button>
        <div className="absolute right-4 top-4">
            <LogoutButton />
        </div>
    </div>
);
