import {
    screenNav,
    SelectedTenantIdAtom,
    selectedZEVID,
    MeterArrayAtom,
    ZevArrayAtom,
    zevSelectionPopup,
    meterSelectionPopup,
} from '../state/states';
import { createGraph, gotApi } from '../misc/got.config';
import { APPLICATION_ROOT_NODE, EDITOR, MAIN, ROOT_ZEV_EDGE, ZEV_METER_EDGE } from '../misc/consts';
import * as R from 'ramda';
import { getSettings } from '../misc/user-settings';

const zevsView = {
    [APPLICATION_ROOT_NODE]: {
        as: 'root',
        include: {
            node: true,
        },
        edges: {
            [ROOT_ZEV_EDGE]: {
                as: 'zevs',
                include: {
                    edges: true,
                    node: true,
                    rights: true,
                },
            },
        },
    },
};

const createTenantView = zevId =>
    zevId
        ? {
              [zevId]: {
                  as: 'zev',
                  include: {
                      node: true,
                  },
                  edges: {
                      [ZEV_METER_EDGE]: {
                          as: 'meters',
                          include: {
                              edges: true,
                              node: true,
                          },
                      },
                  },
              },
          }
        : {};

const selectZev = zevBags => {
    const userSettings = getSettings();
    const zevId = userSettings.zevId;

    return (
        zevBags.find(zev => zevId && zev.nodeId === zevId) ||
        (zevBags.length === 1 ? zevBags[0] : undefined)
    );
};

const selectTenantId = (meterBags, zevId) => {
    const userSettings = getSettings();
    const tenantId = userSettings.tenantId;

    if (zevId && (!tenantId || tenantId === zevId)) return zevId;

    const found = meterBags.find(meter => meter.nodeId === tenantId);
    if (found) {
        return tenantId;
    }
    return meterBags.length === 1 ? meterBags[0].nodeId : zevId;
};

export const loadUser = async ({ mobile }) => {
    const { pull, getView } = createGraph(MAIN, EDITOR);
    await pull(zevsView);

    const zevs = getView(zevsView);
    const zevArray = R.compose(
        R.sortBy(R.path(['node', 'name'])),
        R.values,
        R.pathOr({}, ['root', 'zevs']),
    )(zevs);
    ZevArrayAtom.set(zevArray);

    if (zevArray.length === 0) {
        screenNav.set('landlord-no-zev');
    } else {
        const zev = selectZev(zevArray);

        if (zev) {
            await loadZev(zev, mobile);
        } else {
            screenNav.set('landlord-main');
            !mobile && zevSelectionPopup.set(true);
        }
    }
};

export const loadZev = async (zevBag, mobile) => {
    const userEmail = gotApi.getCurrentUser()?.email;
    const admin = R.pathOr(false, ['rights', 'user', userEmail, 'admin'], zevBag);
    selectedZEVID.set(zevBag.nodeId);
    if (admin) {
        await loadMeterAsLandlord(zevBag.nodeId);
    } else {
        await loadMeterAsTenant({ zevId: zevBag.nodeId, mobile });
    }
};

const getTenArray = async zevId => {
    const { pull, getView } = createGraph(MAIN, EDITOR);
    const tenantView = createTenantView(zevId);

    await pull(tenantView);
    const tens = getView(tenantView);
    const tenArray = R.compose(
        R.sortBy(R.path(['node', 'nameT'])),
        R.values,
        R.pathOr({}, ['zev', 'meters']),
    )(tens);

    MeterArrayAtom.set(tenArray);

    return tenArray;
};

const loadMeterAsLandlord = async zevId => {
    const tenArray = await getTenArray(zevId);

    if (tenArray.length === 0) {
        SelectedTenantIdAtom.set(zevId);
    } else {
        const tenantId = selectTenantId(tenArray, zevId);
        if (tenantId) {
            SelectedTenantIdAtom.set(tenantId);
        } else {
            SelectedTenantIdAtom.set(zevId);
        }
    }

    screenNav.set('landlord-main');
};

export const loadMeterAsTenant = async ({ zevId, mobile }) => {
    const tenArray = await getTenArray(zevId);

    if (tenArray.length === 0) {
        screenNav.set('tenant-no-meter');
    } else {
        const tenantId = selectTenantId(tenArray);
        if (tenantId) {
            SelectedTenantIdAtom.set(tenantId);
            screenNav.set('tenant-main');
        } else {
            screenNav.set('tenant-main');
            !mobile && meterSelectionPopup.set(true);
        }
    }
};
