import { tariffCalcPopupAtom, tariffEditPopupAtom } from '../../../state/states';
import { Modal } from '../../../components/Modal';
import { useTariffEdit } from '../../../hooks/useTariffEdit';
import { RappenPropInputField } from './shared';

export const EditTariffModal = () => {
    const { onSave, tariff, updateTariff, tariffRes } = useTariffEdit();

    const externalPrice = tariff.external || 0;
    const externalSuggestion = tariffRes.externalTariff || 0;

    const internalPrice = tariff.internal || 0;
    const internalSuggestion = tariffRes.internalTariff || 0;

    const warning =
        (tariff.internal || 0) > internalSuggestion
            ? 'Der interne Strompreis ist höher als der empfohlene Preis, dies könnte gegen Schweizer Richtlinien verstoßen.'
            : '';

    return (
        <Modal
            onClose={() => tariffEditPopupAtom.set(false)}
            onSave={onSave}
            title="Tarif bearbeiten"
        >
            <div className="mx-auto flex flex-col justify-center">
                <div
                    className="font-semi mx-auto cursor-pointer px-4 py-2 text-center text-xl text-slate-600 hover:text-yellow-400 focus:outline-none"
                    onClick={() => {
                        tariffCalcPopupAtom.set(true);
                    }}
                >
                    Zum Tarifrechner
                </div>
                <RappenPropInputField
                    label={'Standard Strompreis Rp/kWh'}
                    type={'number'}
                    defaultVal={externalPrice}
                    suggestion={externalSuggestion}
                    propName={'external'}
                    obj={tariff}
                    setObj={updateTariff}
                />
                <RappenPropInputField
                    label={'Interner Strompreis Rp/kWh'}
                    type={'number'}
                    defaultVal={internalPrice}
                    suggestion={internalSuggestion}
                    propName={'internal'}
                    obj={tariff}
                    setObj={updateTariff}
                />
                <RappenPropInputField
                    label="Mehrwertsteuer %"
                    type={'number'}
                    defaultVal="0.077"
                    propName={'tax'}
                    obj={tariff}
                    setObj={updateTariff}
                />
                {warning && (
                    <div className="max-w-[450px] rounded-md border border-solid border-orange-500 px-4 py-2 text-orange-500">
                        {warning}
                    </div>
                )}
            </div>
        </Modal>
    );
};
