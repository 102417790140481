import React from 'react';
import { tenantRightsPopup } from '../../state/states';
import Icon from '../../components/Icon';
import { Modal } from '../../components/Modal';
import { useEditTenantRights } from '../../hooks/useEditTenantRights';
import { Input } from '../../components/Input';

export const TenantRightsModal = () => {
    const { eMail, setEMail, handleClick, handleRemove, rights, nameT } = useEditTenantRights();
    return (
        <Modal onClose={() => tenantRightsPopup.set(false)} title={nameT + ' – Rechte'}>
            <div className="mx-auto my-10 flex w-full items-center justify-evenly ">
                <Input
                    value={eMail}
                    onChange={value => setEMail(value)}
                    label={'E-Mail eingeben'}
                />

                <div
                    className="flex h-7 w-7 cursor-pointer items-center justify-center text-neutral-400 hover:scale-110"
                    onClick={handleClick}
                    title="Speichern"
                >
                    <Icon name="user-plus" className="fill-slate-600 hover:fill-yellow-400" />
                </div>
            </div>
            <div className="flex w-full flex-col flex-wrap gap-3">
                {rights.map((rightsEMail, index) => (
                    <div
                        className="flex items-center gap-2 rounded-xl px-2 py-1 text-slate-600"
                        key={index}
                    >
                        <div className="text-lg">{rightsEMail}</div>
                        <div
                            className="flex h-3 w-3 cursor-pointer items-center justify-center rounded-lg hover:scale-110"
                            onClick={() => handleRemove(rightsEMail)}
                            title="Löschen"
                        >
                            <Icon
                                name="trash-can"
                                className="fill-slate-600 hover:fill-yellow-400"
                            />
                        </div>
                    </div>
                ))}
            </div>
        </Modal>
    );
};
