import { useAtom } from '@gothub-team/got-atom';
import { SelectedTenantIdAtom, selectedZEVID } from '../state/states';
import { motion } from 'framer-motion';
import { Tile } from '../components/Tile';
import { useStatistics } from '../hooks/useStatistics';

const FadeInDiv = ({ delay, children }) => (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay }}>
        {children}
    </motion.div>
);

export const StatisticsTile = () => {
    const zevId = useAtom(selectedZEVID);
    const tenantId = useAtom(SelectedTenantIdAtom);
    const statistics = useStatistics();

    return (
        <Tile
            className="col-start-[13] col-end-[17] row-start-1 row-end-2 px-3"
            title={statistics.title}
            content={
                tenantId && (
                    <div className="fade-in flex h-full flex-col justify-around">
                        <FadeInDiv delay={0.15}>
                            <div className="mx-auto w-full">
                                <div className="flex justify-center text-center text-slate-700">
                                    Verbrauch Extern (Netzbezug)
                                </div>
                                <div className="flex justify-center text-xl text-neutral-400">
                                    {`${statistics.external} kWh`}
                                </div>
                            </div>
                        </FadeInDiv>
                        <FadeInDiv delay={0.25}>
                            <div className="flex justify-center text-center text-slate-700">
                                Solar Eigenverbrauch
                            </div>
                            <div className="flex justify-center text-xl text-neutral-400">
                                {`${statistics.internal} kWh`}
                            </div>
                        </FadeInDiv>
                        <FadeInDiv delay={0.35}>
                            <div className="flex justify-center text-center text-slate-700">
                                Gesamtverbrauch
                            </div>
                            <div className="flex justify-center text-xl text-neutral-400">
                                {`${statistics.import} kWh`}
                            </div>
                        </FadeInDiv>
                        <FadeInDiv delay={0.5}>
                            <div className="flex justify-center text-center text-slate-700">
                                Einspeisung
                            </div>
                            <div className="flex justify-center text-xl text-neutral-400">
                                {`${statistics.export} kWh`}
                            </div>
                        </FadeInDiv>
                        <FadeInDiv delay={0.65}>
                            <div className="flex justify-center text-center text-slate-700">
                                {tenantId === zevId ? 'Eigenverbrauchsquote' : 'Autarkie'}
                            </div>
                            <div className="flex justify-center text-xl text-neutral-400">
                                {tenantId === zevId
                                    ? `${statistics.internalUsage}%`
                                    : `${statistics.sustainability}%`}
                            </div>
                        </FadeInDiv>
                    </div>
                )
            }
        />
    );
};
